import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Title from '../title/Title';
import CompetencesItem from './CompetencesItem';
import CompetencesItem2 from "./CompetencesItem2";
import CompetencesItem3 from "./CompetencesItem3";
import './competences.scss'

function Competences() {
    return (
        <Row className="row-component-wrapper">
            <Col className="competences-wrap">
                <Row className="competences">
                    <Col className="title-responsive">
                        <Title t={'Мои компетенции'} />
                    </Col>
                    <Col className="competences__items-wrap">
                        <CompetencesItem />
                        <CompetencesItem2 />
                        <CompetencesItem3 />
                    </Col>
                </Row>
            </Col>
        </Row >
    );
}

export default Competences;