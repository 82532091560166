import React from 'react';
import {useStore} from "../../../../../../store";
import {Button} from "react-bootstrap";

function ExercisesAdminTextarea({lessonItem, questionItem, propertyItem}) {

    const {exercisesStore} = useStore()

    function setTextareaTitleHandler(lessonId, questionId, value, id) {
        exercisesStore.setTextareaTitle(lessonId, questionId, value, id)
    }

    function setTextareaPlaceholderHandler(lessonId, questionId, value, id) {
        exercisesStore.setTextareaPlaceholder(lessonId, questionId, value, id)
    }

    function deleteTextareaHandler(lessonId, questionId, textareaId) {
        exercisesStore.deleteTextarea(lessonId, questionId, textareaId)
    }

    return (
        <div>
            <input type="textarea"
                   placeholder="текст заголовка"
                   value={propertyItem.title}
                   onChange={(e) =>
                       setTextareaTitleHandler(
                           lessonItem.id,
                           questionItem.id,
                           e.target.value,
                           propertyItem.id)}
            />
            <input type="textarea"
                   placeholder="текст плейсхолдера"
                   value={propertyItem.placeholder}
                   onChange={(e) =>
                       setTextareaPlaceholderHandler(
                           lessonItem.id,
                           questionItem.id,
                           e.target.value,
                           propertyItem.id)}
            />
            <Button size={"sm"}
                    variant={"success"}
                    onClick={() =>
                        deleteTextareaHandler(
                            lessonItem.id,
                            questionItem.id,
                            propertyItem.id
                        )}>
                Удалить текстовое поле
            </Button>
        </div>
    );
}

export default ExercisesAdminTextarea;