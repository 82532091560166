import React from 'react';
import './btnWithArrow.scss'
import {ChevronRight} from 'react-bootstrap-icons';
import {useMediaQuery} from 'react-responsive';

function BtnWithArrow({t, a = 'true', w, c = 'white', bg = 'black', mt, h, shad = '2px 1px 3px black'}) {
    const max767 = useMediaQuery({
        query: '(max-width: 767px)'
    })

    let x = '2rem'
    let y = '0.7rem'

    if (max767) {
        x = '1.2rem'
        y = '0.6rem'
    }
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
        }}>
            <div style={{
                marginTop: mt,
                paddingLeft: x,
                paddingRight: x,
                paddingTop: y,
                paddingBottom: y,
                backgroundColor: bg,
                color: c,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: w,
                height: h,
                boxShadow: shad,
            }}>
                {t}
                {a ? <ChevronRight style={{
                    fontSize: '1rem',
                    marginLeft: '0.2rem',
                }}/> : null}
            </div>
        </div>
    );
}

export default BtnWithArrow;