import React, {useEffect} from 'react';
import {Accordion, Button} from "react-bootstrap";
import '../pt0.scss'
import '../pt1.scss'
import {observer} from "mobx-react";
import {FileArrowDown} from "react-bootstrap-icons";
import StatusBlock from "../StatusBlock";
import {useStore} from "../../../../store";
import ExerciseItemTable from "./ExerciseItemTable";
import './exerciseItem.scss';
import ExerciseItemCheckbox from "./ExerciseItemCheckbox";
import ExerciseItemTextarea from "./ExerciseItemTextarea";
import {Link} from "react-router-dom";
import ExerciseItemQuestionWithButtons from "./ExerciseItemQuestionWithButtons";
import ExerciseItemMediaFile from "./ExerciseItemMediaFile";

const ExerciseItem = observer(({exerciseItem}) => {

    const {userExercisesStore} = useStore()

    useEffect(() => {
        userExercisesStore.setLocalExercise(exerciseItem)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const exercise = userExercisesStore.exercise

    return (<>
        <Accordion.Header>
            <div dangerouslySetInnerHTML={{__html: `${exercise.title}`}}/>
        </Accordion.Header>
        <Accordion.Body>
            <div className="exercise-item">
                <div className="exercise-item__video-description-wrap">
                    <div className="exercise-item__iframe-wrap">
                        <iframe width="100%"
                                height="400px"
                                src="https://player.vimeo.com/video/376065897"
                                title="title"
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen=""/>
                    </div>
                    <div className="exercise-item__text-wrap">
                        {exercise.description &&
                            <div dangerouslySetInnerHTML={{__html: `${exercise.description}`}}/>}
                    </div>
                </div>
                <div className="pt1__middle exercise-item__lessons-wrap">
                    {exercise.lessons.map((lessonItem, index) => {
                        return <div key={index} className="exercise-new__lesson">

                            {lessonItem.description &&
                                <div dangerouslySetInnerHTML={{__html: `${lessonItem.description}`}}/>}

                            {lessonItem.questions?.map(questionItem => {
                                return <div key={questionItem.title}>
                                    {questionItem.title &&
                                        <div dangerouslySetInnerHTML={{__html: `${questionItem.title}`}}/>}

                                    {/*{questionItem.type !== 'table' &&*/}
                                    {/*    <div*/}
                                    {/*        dangerouslySetInnerHTML={{__html: `${questionItem.properties.table.title}`}}/>*/}
                                    {/*}*/}

                                    {questionItem.type === 'table' && questionItem.properties.table?.map((tableItem, index) => {
                                        return (<ExerciseItemTable key={index} tableItem={tableItem}
                                                                   lessonItem={lessonItem}
                                                                   questionItem={questionItem}
                                        />)
                                    })}

                                    {questionItem.type === 'checkbox' && questionItem.properties.checkboxes?.map((propertyItem, index) => {
                                        return (<ExerciseItemCheckbox key={index} lessonItem={lessonItem}
                                                                      questionItem={questionItem}
                                                                      propertyItem={propertyItem}
                                        />)
                                    })}

                                    {questionItem.type === 'textarea' && questionItem.properties.textarea?.map((propertyItem, index) => {
                                        return (<ExerciseItemTextarea key={index} lessonItem={lessonItem}
                                                                      questionItem={questionItem}
                                                                      propertyItem={propertyItem}
                                        />)
                                    })}

                                    {questionItem.type === 'mediaFile' && <ExerciseItemMediaFile
                                        questionItem={questionItem}
                                    />
                                    }

                                    {questionItem.type === 'questionWithButtons' && <ExerciseItemQuestionWithButtons
                                        lessonItem={lessonItem}
                                        questionItem={questionItem}
                                    />
                                    }
                                </div>
                            })}
                        </div>
                    })}
                </div>
            </div>

            {exercise.properties?.downloadBtn.toggle && <div className="pt1__middle">
                <div className="pt-status-wrap flex-column">
                    <div className="pt__download-btn-wrap">
                        <Link to={exercise.properties?.downloadBtn.link} target="_blank" download>
                            <Button className="pt__download-btn mt-3">
                                <FileArrowDown/>
                                <span>{exercise.properties?.downloadBtn.text}</span>
                            </Button>
                        </Link>
                    </div>
                    <StatusBlock/>
                </div>
            </div>}
            <pre>{JSON.stringify(exercise, null, 2)}</pre>
        </Accordion.Body>
    </>);
})

export default ExerciseItem;