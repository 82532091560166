import React from 'react';
import {Button, Modal} from "react-bootstrap";
import Draft from "../../../../../components/admin/draft/Draft";
import {observer} from "mobx-react";

const ModalWithDraftJs = observer(({handleClose, show, editorValue, setEditorValue, title, setText, lessonId, questionId }) => {

    function saveTextHandler() {
        handleClose()
        setText(editorValue, lessonId, questionId)
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Draft editorValue={editorValue} setEditorValue={setEditorValue}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                    <Button variant="primary" onClick={saveTextHandler}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
})

export default ModalWithDraftJs;
