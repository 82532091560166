import React from 'react';
import {Button} from "react-bootstrap";
import './statusBlock.scss';
import {
    CheckLg,
    Question,
    X
} from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import {saveAnswers} from "../schoolAdmin/schoolApi";
import {useStore} from "../../../store";

function StatusBlock({exercise}) {

    const {userExercisesStore} = useStore()

    function saveAnswersHandler() {
        userExercisesStore.setAnswers()
        saveAnswers(userExercisesStore.answersObj)
    }

    return (
        <div className="status-block-wrap">
            <div className="d-flex justify-content-center">
                <div className="pt-done-btn-wrap">
                    <b>
                        Нажмите кнопку “Готово”,если выполнили все упражнения из
                        домашнего задания
                    </b>
                    <div className="pt-status__check-button-wrap">
                        <Button className="pt-status__check-button mt-3" onClick={saveAnswersHandler}>
                            <CheckLg/> <span>ГОТОВО</span>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="pt-status-wrap">
                <div className="pt-status__left-block">
                    <h4>Статус&nbsp;задания</h4>
                    <div className="pt-status__not-fulfilled-btn-wrap">
                        <span className="pt-status__not-fulfilled-btn">
                            <span className="pt-status__icon"><X/></span>Не выполнено
                        </span>
                    </div>
                </div>
                <div className="pt-status__right-block">
                    <h4>Задать&nbsp;вопрос&nbsp;куратору</h4>
                    <div>
                        Перед тем, как задать свой вопрос, ознакомьтесь с разделом <Link to="#"> "Все вопросы и
                        ответы". </Link> Возможно, вы найдете ответ там.
                    </div>
                    <div className="pt-status__question-btn-wrap">
                        <Button className="pt-status__question-btn mt-3">
                            <Question/><span>ЗАДАТЬ ВОПРОС</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StatusBlock;