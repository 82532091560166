import React from 'react';
import { useMediaQuery } from 'react-responsive';

const DescriptionItem = ({ text }) => {
    const max767 = useMediaQuery({
        query: '(max-width: 767px)'
    })
    let w = '58px'

    if (max767) {
        w = '22px'
    }

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '.5rem',
        }}>
            <span style={{
                width: w,
                minWidth: w,
                height: 2,
                backgroundColor: 'black',
                display: 'inline-block',
                marginRight: 10,
            }} />
            <div style={{
                fontSize: '1rem',
                margin: 0,
            }}>
                {text}
            </div>
        </div >
    );
}

export default DescriptionItem;
