import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { getPaymentDataEpoint } from '../../api/generalApi';
import { useStore } from '../../store';
import { Link } from 'react-router-dom';
import EpointForm from './EpointForm';
import './paymentEpointButton.scss';

const PaymentEpointButton = observer(({ type, id, price, prolongation_price, webinar_state }) => {
  const { userStore } = useStore();
  const formPaymentEpoint = useRef(null);
  const [payData, setPayData] = useState({});

  const isAuthUser = userStore.isAuth;

  const buyReq = () => {
    const params = { type, id };
    getPaymentDataEpoint(params).then((res) => {
      if (res && !res.IsFree) {
        setPayData(res);
        formPaymentEpoint.current.submit();
      }
    });
  };

  const buyHandler = () => {
    if (isAuthUser) {
      buyReq();
    }
  };

  const renderButton = () => {
    if (isAuthUser) {

      if (webinar_state === 'STATE_CAN_PROLONG' && !!prolongation_price) {
        return (
          <button className="payment-epoint-button__buy-btn" onClick={() => buyHandler()}>
            <span>
              Продлить из других стран
              <span className="payment-epoint-button__buy-btn-asterisk">*</span> (
              {parseFloat(prolongation_price)} AZN)
            </span>
          </button>
        )
      }
      if (webinar_state === 'STATE_CAN_BUY') {
        return (
          <button className="payment-epoint-button__buy-btn" onClick={() => buyHandler()}>
            <span>
              Приобрести из других стран
              <span className="payment-epoint-button__buy-btn-asterisk">*</span> (
              {parseFloat(price)} AZN)
            </span>
          </button>
        )
      }
    } else {
      return (
        <Link
          className="payment-epoint-button__buy-btn"
          to={{ pathname: '/modal/auth', state: { modal: true } }}
        >
          <span>
            Приобрести из других стран
            <span className="payment-epoint-button__buy-btn-asterisk">*</span> (
            {parseFloat(price)} AZN)
          </span>
        </Link>
      )
    }
  }

  return (
    price !== undefined &&
    price > 0 && (
      <div className="payment-epoint-button-wrapper">
        <EpointForm refKey={formPaymentEpoint} payData={payData} />

        {renderButton()}
      </div>
    )
  );
});

export default PaymentEpointButton;
