import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import Title from '../title/Title';
import './homePageQa.scss'
import {AQ_ROUTE} from "../../utils/consts";
import LearnMoreLink from "../learnMoreLink/LearnMoreLink";
import {PlayCircle} from "react-bootstrap-icons";

function HomePageQa() {
    const [iframePreviewToggler, setIframePreviewToggler] = useState(false)

    return (
        <Row className="row-component-wrapper home-page-qa-wrap-outer">
            <Col className="home-page-qa-wrap-inner">
                <Row>
                    <Col className="title-responsive">
                        <Title t={'Вопрос-ответ'}/>
                    </Col>
                </Row>
                <Row className="home-page-qa">
                    <Col>
                        {iframePreviewToggler &&
                        <div className="home-page-qa__iframe-wrap">
                            <iframe className="home-page-qa__iframe"
                                    title="video"
                                    allowFullScreen="allowfullscreen" allow="autoplay; fullscreen"
                                    src={`https://www.youtube.com/embed/ZEjetLNvYOM?autoplay=1&amp;autohide=1&amp;fs=1&amp;rel=0&amp;hd=1&amp;wmode=transparent&amp;enablejsapi=1&amp;html5=1&amp;start=0`}
                                    scrolling="no">
                            </iframe>
                        </div>}
                        {!iframePreviewToggler &&
                        <div className="home-page-qa__img-wrap">
                            <img src={'https://img.youtube.com/vi/ZEjetLNvYOM/maxresdefault.jpg'} alt=''/>
                            <PlayCircle className="home-page-qa__img-wrap__circle"
                                        onClick={() => setIframePreviewToggler(!iframePreviewToggler)}/>
                        </div>}
                    </Col>
                </Row>
                <LearnMoreLink text={'Все ответы'} width={'11rem'} route={AQ_ROUTE}/>
            </Col>
        </Row>
    );
}

export default HomePageQa;