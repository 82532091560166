import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import Title from '../../components/title/Title';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import './aqPage.scss'
import {$host} from "../../utils/consts";
import AqItem from "../../components/aqItem/AqItem";

const AqPage = () => {

    const [answers, setAnswers] = useState([]);

    const getAnswersReq = () => {
        $host.get(`api/v1/user/answers`).then(res => {
            setAnswers(res.data)
        })
    }

    useEffect(() => {
        getAnswersReq()
    }, []);

    return (
        <Col className="col-page-wrapper aq-wrap">
            <Breadcrumbs active={'Ответы на вопросы'}/>
            <Title t={'Ответы на вопросы'}/>
            <Row className="aq__settings">
                <Col xs={12} md={8} className="aq__tags" style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    fontFamily: 'Lora',
                    fontSize: '1.1rem',
                }}>
                    <span>#Здоровье (18+)</span>
                    <span>#Дети (18+)</span>
                    <span>#Любовь (18+)</span>
                </Col>
            </Row>
            <Row>
                <Col className="aq__grid-wrap">
                    {answers.map(item => <AqItem
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        image={item.image}
                        url={item.url}
                        tags={item.tags}
                    />)}
                </Col>
            </Row>
        </Col>
    );
}

export default AqPage;
