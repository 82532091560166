import {makeObservable, observable} from "mobx"
import {getKitById, getKits, getKitsByMarkId, getWebinarById, getWebinarsPrivate} from "../api/private";

export default class Private {

    webinars = []
    webinar = {}
    kits = []
    kit = []
    kitByMarkId = []

    constructor() {
        makeObservable(this, {
            webinars: observable,
            webinar: observable,
            kits: observable,
            kit: observable,
            kitByMarkId: observable,
        });
    }

    setWebinars = async () => {
        this.webinars = await getWebinarsPrivate()
    }

    setWebinarById = async (id) => {
        this.webinar = await getWebinarById(id)
    }

    setKits = async () => {
        this.kits = await getKits()
    }

    setKitById = async (id) => {
        this.kit = await getKitById(id)
    }

    setKitsByMarkId = async (id) => {
        this.kitByMarkId = await getKitsByMarkId(id)
    }
}
