import React from "react";
import {Eye, EyeSlash} from "react-bootstrap-icons";
import './passInputWitheye.scss'

function PassInputWithEye({passVisible, setPassVisible}) {
    return (
        <span className="pass-input__with-eye__eye"
              onClick={() => setPassVisible(!passVisible)}>
            {passVisible ? <Eye/> : <EyeSlash/>}
        </span>
    );
}

export default PassInputWithEye;
