import {Formik} from 'formik';
import React, {useState} from 'react';
import * as yup from 'yup';
import {Col, Form,} from 'react-bootstrap';
import RegularBtn from '../../components/regularBtn/RegularBtn';
import {$host} from '../../utils/consts';
import './passwordRecoveryPage.scss'
import AlertModal from '../../components/alertModal/AlertModal';
import {useHistory} from 'react-router-dom';
import PassInputWithEye from "../../components/account/myData/PassInputWithEye";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

const schema = yup.object().shape({
    new_password: yup.string().trim().typeError('Должно быть строкой').required('Введите пароль'),
    new_retyped_password: yup.string().trim().oneOf([yup.ref('new_password')], 'Пароли не совпадают').typeError('Должно быть строкой').required('Введите пароль'),
});

const PasswordRecoveryPage = () => {
    let params = (new URL(document.location)).searchParams;
    let token = params.get('token');
    const history = useHistory();

    const [alertModalShow, setAlertModalShow] = useState(false)
    const [passVisible1, setPassVisible1] = useState(false);
    const [passVisible2, setPassVisible2] = useState(false);

    const passwordRecoveryHandler = (
        new_password,
        new_retyped_password,
        token
    ) => {
        $host.post(`api/v1/password-recover`, {
            new_password,
            new_retyped_password,
            token,
        }).then(() => {
            setAlertModalShow(true)
            setTimeout(() => {
                setAlertModalShow(false)
            }, 2500);
            setTimeout(() => {
                history.push("/");
            }, 2500);
        })
    }

    return (
        <>
            <Breadcrumbs />
            <Formik
                validationSchema={schema}
                initialValues={{
                    new_password: '',
                    new_retyped_password: '',
                }}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      dirty
                  }) => (
                    <Col>
                        <Form noValidate onSubmit={handleSubmit} className="pass-recovery-page-wrap">
                            <div as={Col} xs="12" md="6">
                                <Form.Group controlId="validationFormik01">
                                    <Form.Label>Новый пароль</Form.Label>
                                    <div className="pass-input__with-eye-wrap">
                                        <PassInputWithEye passVisible={passVisible1}
                                                          setPassVisible={setPassVisible1}/>
                                        <Form.Control rows={3}
                                                      type={passVisible1 ? "text" : "password"}
                                                      placeholder="password"
                                                      name="new_password"
                                                      value={values.new_password}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={!!errors.new_password}
                                        />
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="mb-3">
                                        {errors.new_password}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="validationFormik02">
                                    <Form.Label>Подтвердите новый пароль</Form.Label>
                                    <div className="pass-input__with-eye-wrap">
                                        <PassInputWithEye passVisible={passVisible1}
                                                          setPassVisible={setPassVisible2}/>
                                        <Form.Control rows={3}
                                                      type={passVisible2 ? "text" : "password"}
                                                      placeholder="password"
                                                      name="new_retyped_password"
                                                      value={values.new_retyped_password}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={!!errors.new_retyped_password}
                                        />
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="mb-3">
                                        {errors.new_retyped_password}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <RegularBtn onoff={!isValid || !dirty} t={'Изменить пароль'} w={'15rem'}
                                        onClick={() => passwordRecoveryHandler(
                                            values.new_password,
                                            values.new_retyped_password,
                                            token,
                                        )} style={{
                                margin: '0 auto',
                                marginTop: '1rem',
                            }}/>
                        </Form>
                    </Col>
                )}
            </Formik>
            <AlertModal text="Пароль успешно изменен!" show={alertModalShow}/>
        </>
    );
}

export default PasswordRecoveryPage;
