import React from 'react';
import {observer} from "mobx-react";
import {Form} from "react-bootstrap";
import {useStore} from "../../../../store";

const ExerciseItemCheckbox = observer(({lessonItem, questionItem, propertyItem}) => {

    const {userExercisesStore} = useStore()


    function setCheckboxActive(lessonId, questionId, propertyId) {
        userExercisesStore.setCheckboxActive(lessonId, questionId, propertyId)
    }

    return (
        <Form>
            <Form.Check
                type={'radio'}
                label={propertyItem.text}
                id={`id`}
                checked={propertyItem.active}
                onChange={() => setCheckboxActive(
                    lessonItem.id,
                    questionItem.id,
                    propertyItem.id
                )}
            />
        </Form>
    );
})

export default ExerciseItemCheckbox;