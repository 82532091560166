import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Title from '../title/Title';
import "./certificates.scss";
import CertificatesSlider from './certificatesSlider/CertificatesSlider';
// import {ArrowRightCircle} from "react-bootstrap-icons";
// import {NavLink} from "react-router-dom";
import LearnMoreLink from "../learnMoreLink/LearnMoreLink";
// import {REVIEWS_ROUTE} from "../../utils/consts";

function Certificates({mb = '3.375rem'}) {
    return (
        <Row className="certification-slider" style={{
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Col xs={{span: 12}} className="slider-wrap" style={{
                maxWidth: 1500,
            }}>
                <Row style={{flexDirection: 'column'}}>
                    <Col className="title-responsive">
                        <Title t={'Мои сертификаты'}/>
                    </Col>
                    <CertificatesSlider/>
                    <Col style={{marginBottom: mb, marginTop: '5.375rem'}}>
                        <LearnMoreLink text={'Все сертификаты'} width={'14.6rem'} route={'/about#cds'}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default Certificates;
