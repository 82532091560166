import {makeObservable, observable} from "mobx"
import {getCertificates} from "../api/public";
import {editCertificate, postCertificate} from "../api/admin";

export default class Certificates {
    certificates = []

    constructor() {
        makeObservable(this, {
            certificates: observable,
        });
    }

    setCertificates = async () => {
        this.certificates = await getCertificates();
    }

    addCertificate = async (params) => {
        const data = await postCertificate(params)
        this.certificates.push(data)
        return data
    }

    editCertificate = async (id, params) => {
        const data = await editCertificate(id, params)
        this.certificates = this.certificates.map(item => {
            if (item.id === data.id) {
                return data
            } else {
                return item
            }
        })
        return data
    }
}