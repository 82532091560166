import React from 'react';
import {Col, Row} from 'react-bootstrap';
import consultation from '../../images/consultation.jpg'
import Title from '../title/Title';
import './consultation.scss'
import {NavLink} from "react-router-dom";
import {CONSULTATION_ROUTE} from "../../utils/consts";

function Consultation() {

    return (
        <Row className="consultation" style={{
            flexDirection: 'column',
            backgroundColor: '#F2F2F2',
            alignItems: 'center',
            paddingBottom: '3.5rem',
        }}>
            <Col style={{
                maxWidth: 1500,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <Row>
                    <Col className="title-responsive">
                        <Title t={'Запись на консультацию'}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="background-wrapper" style={{
                        backgroundImage: `linear-gradient(to right, rgb(0 0 0), rgb(0 0 0) 26%, rgb(0 0 0 / 0%) 48%),
                    url(${consultation})`
                    }}>
                        <div>
                            <div>Срочная Skype-консультация</div>
                            <div>Только в свободные даты</div>
                        </div>
                        <NavLink to={CONSULTATION_ROUTE}>
                            <div style={{
                                backgroundColor: 'white',
                                color: 'black',
                                width: '9.3rem',
                                height: '2.3rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 600,
                                fontSize: 18,
                            }}>

                                Запись
                            </div>
                        </NavLink>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}


export default Consultation;