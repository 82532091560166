import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Title from '../../../components/title/Title';
import CertificatesSlider from '../../../components/certificates/certificatesSlider/CertificatesSlider';
import './competencePage.scss'
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import {InfoCircle} from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import {KITS_ROUTE, VIDEOCOURCES_ROUTE} from "../../../utils/consts";

const CompetencePage2 = () => {
    return (
        <Col className="col-page-wrapper">
            <Breadcrumbs to={'2'}/>
            <Title t={'Семейное консультирование.'}/>
            <Row>
                <Col>
                    <p className="mb-1">
                        Семейной терапии я обучалась лично у ведущего специалиста по семейному консультированию в РФ.
                        Будни семейного консультанта – это перечень стандартных задач, за решением которых к нему
                        обращаются:
                    </p>
                    <p className="mb-2 mt-1">
                        Как выйти замуж или жениться, чтобы потом не разводиться?
                        Как строить отношения с бывшим партнёром в случае развода?
                        Как интегрировать в одну семью детей от предыдущих браков обоих партнёров?
                        Что делать в случае измены?
                        Как избежать сексуальных расстройств и охлаждения в паре?
                        Я его люблю, а он меня – нет, что делать?
                    </p>
                    <p className="mb2">
                        Одним словом, как построить крепкий семейный союз и преодолеть кризисы мне хорошо известно. С
                        этими навыками мы не рождаемся, но им можно и нужно обучиться. Я обучаю своих клиентов искусству
                        построения отношений с 2006 года и уже сбилась со счета сколько рождённых в счастливых семьях
                        детей считаю косвенно своими.
                    </p>
                    <h4 className="mt-5 mb-3">
                        С 2008 года обучаю психологов навыкам семейного консультирования. Могу научить и вас:
                    </h4>
                    <div className="list-item">
                        <span className="list-item__stick"/>
                        <div className="list-item__text">
                            навыку правильно выбрать партнёра;
                        </div>
                    </div>
                    <div className="list-item">
                        <span className="list-item__stick"/>
                        <div className="list-item__text">
                            навыку построения здоровых отношений (психологических и сексуальных);
                        </div>
                    </div>
                    <div className="list-item">
                        <span className="list-item__stick"/>
                        <div className="list-item__text">
                            навыку преодоления кризиса.
                        </div>
                    </div>
                    <div className="d-flex mt-5 mb-5">
                        <div className="competence-page__note">
                            <InfoCircle/>
                        </div>
                        <div>
                            Рекомендую к просмотру видеокурсы из цикла «личные границы», «любовь», «секс». Поскольку
                            жизненные ситуации могут быть разными, в случае необходимости рекомендую материал
                            видеокурсов «Измена» и «Насилие в семье». Найти их можно в разделах
                            <Link
                                to={VIDEOCOURCES_ROUTE}> <u>«видеокурсы»</u>
                            </Link> и
                            <Link
                                to={KITS_ROUTE}> <u>«спецпредложения».</u>
                            </Link>

                        </div>
                    </div>
                    <div className="competence-page__slider-wrap">
                        <CertificatesSlider/>
                    </div>
                </Col>
            </Row>
        </Col>
    );
}

export default CompetencePage2;
