import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import './authModal.scss';
import {useHistory} from "react-router-dom";
import {classNames} from '../../utils/classNames';
import SignIn from './SignIn';
import SignUp from './SignUp';

const SignUpSignInModal = ({show}) => {
        const history = useHistory();
    const [activeForm, setActiveForm] = useState('SignIn');

    const modalClose = () => {
        history.goBack('/', {modal: false})
    }

    return (
        <Modal className="signin" show={show} onHide={() => modalClose()}>
            <Modal.Header closeButton className="auth-modal__header">
                <span
                    className={classNames({'selected-modal-header': activeForm === 'SignUp'})}
                    onClick={() => setActiveForm('SignUp')}>
                    Регистрация
                </span>
                <span
                    className={classNames({'selected-modal-header': activeForm === 'SignIn'})}
                    onClick={() => setActiveForm('SignIn')}>
                    Вход
                </span>
            </Modal.Header>
            <Modal.Body className="pb-4">
                {activeForm === 'SignUp' && (<SignUp onHide={() => modalClose()}/>)}
                {activeForm === 'SignIn' && (<SignIn onHide={() => modalClose()}/>)}
            </Modal.Body>
        </Modal>
    );
};

export default SignUpSignInModal;
