import req from "./req";

export const tagsRequest = async () => await req.get('api/v1/user/tags');
export const passRecovery = async (email) => await req.post('api/v1/generate-link', {email});
export const saveAnswers = async (data) => await req.post('api/v1/school/user/exercises/save-answers', data);

export const getPaymentData = async (params) => await req.get('api/v1/payment/robokassa/get-payment-data', {params});
export const getPaymentDataTinkoff = async (params) => await req.get('api/v1/payment/tinkoff/get-payment-data', {params});
export const getPaymentDataEpoint = async (params) => await req.get('api/v1/payment/epoint/get-payment-data', { params });

export const getUserByLoginReq = async (params) => await req.get(`api/v1/admin/users?page=1&login=${params}`);
export const getUserWebinars = async (params) => await req.get(`api/v1/admin/users/${params}/webinars`);

export const getStat = async (params) => await req.get(`api/v1/admin/payments`, {}, params); //not used
export const getStatAdditional = async (params) => await req.get(`api/v1/admin/payments/get`, {}, params); //not used
export const getQuestions = async () => await req.get(`api/v1/school/user/exercises`);

