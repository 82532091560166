import React, {Suspense, useEffect, useState} from 'react';
import {Accordion, Col} from "react-bootstrap";
import Title from "../../../components/title/Title";
import {Step, Stepper} from "react-form-stepper";
import './onlineSchool.scss';
import {NavLink} from "react-router-dom";
import {ACCOUNT_ROUTE, SCHOOL_ADMIN_ROUTE} from "../../../utils/consts";
import CustomSpinner from "../../../components/customSpinner/CustomSpinner";
import ExerciseItem from "./exerciseItem/ExerciseItem";
import {observer} from "mobx-react";
import {useStore} from "../../../store";
import {getExercises} from "../schoolAdmin/schoolApi";

const Pt0 = React.lazy(() => import('./Pt0'));
const Pt1 = React.lazy(() => import('./Pt1'));
const Pt2 = React.lazy(() => import('./Pt2'));
const Pt3 = React.lazy(() => import('./Pt3'));
const Pt4 = React.lazy(() => import('./Pt4'));
const Pt5 = React.lazy(() => import('./Pt5'));
const Pt6 = React.lazy(() => import('./Pt6'));
const Pt7 = React.lazy(() => import('./Pt7'));
const Pt8 = React.lazy(() => import('./Pt8'));
const Pt9 = React.lazy(() => import('./Pt9'));
const Pt10 = React.lazy(() => import('./Pt10'));
const Pt11 = React.lazy(() => import('./Pt11'));
const Pt12 = React.lazy(() => import('./Pt12'));

const OnlineSchool = observer(() => {
    const {userStore} = useStore()
    const [activeAccordionItem, setActiveAccordionItem] = useState(-1)
    const [exercises, setExercises] = useState([])

    const isAdmin = userStore.isAdmin

    useEffect(() => {
        getExercises().then(res => setExercises(res))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function clickHandler(numb) {
        setActiveAccordionItem(numb)
    }

    return (
        <Col className="col-page-wrapper online-school-outer-wrap">
            <div className="online-school__title-wrap">
                <Title t={'Онлайн школа'} d={'none'} mt={'2.5rem'}/>
                <div>
                    {isAdmin && <span className="online-school__btn">
                        <NavLink to={SCHOOL_ADMIN_ROUTE}>
                            Администрирование
                        </NavLink>
                    </span>}
                    <span className="online-school__btn">
                        <NavLink to={ACCOUNT_ROUTE}>
                            В личный кабинет
                        </NavLink>
                    </span>
                </div>
            </div>
            <div className="online-school__accordion-wrap">
                <div className="stepper">
                    <Stepper activeStep={activeAccordionItem}>
                        {exercises.map((_, index) => {
                            return <Step key={index}/>
                        })}
                    </Stepper>
                </div>
                <Accordion defaultActiveKey="0">
                    {exercises.map(item =>
                        <Accordion.Item key={item.id} eventKey={item.id}>
                            <Suspense fallback={<div>Загрузка...</div>}>
                                <ExerciseItem exerciseItem={item}/>
                            </Suspense>
                        </Accordion.Item>
                    )}
                    <hr/>
                    <hr/>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className="online-school-item-header">Инструкция</div>
                        </Accordion.Header>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <Pt0/>
                        </Suspense>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1" onClick={() => clickHandler(0)}>
                        <Accordion.Header> Путь к здоровью</Accordion.Header>
                        {activeAccordionItem === 0 && <Suspense fallback={<CustomSpinner/>}>
                            <Pt1/>
                        </Suspense>}
                    </Accordion.Item>

                    <Accordion.Item eventKey="2" onClick={() => clickHandler(1)}>
                        <Accordion.Header>Заболевания</Accordion.Header>
                        {activeAccordionItem === 1 && <Suspense fallback={<CustomSpinner/>}>
                            <Pt2/>
                        </Suspense>}
                    </Accordion.Item>

                    <Accordion.Item eventKey="3" onClick={() => clickHandler(2)}>
                        <Accordion.Header>Психобиологический резерв человека</Accordion.Header>
                        {activeAccordionItem === 2 && <Suspense fallback={<CustomSpinner/>}>
                            <Pt3/>
                        </Suspense>}
                    </Accordion.Item>

                    <Accordion.Item eventKey="4" onClick={() => clickHandler(3)}>
                        <Accordion.Header>Эмоциональная интеллигентность</Accordion.Header>
                        {activeAccordionItem === 3 && <Suspense fallback={<CustomSpinner/>}>
                            <Pt4/>
                        </Suspense>}
                    </Accordion.Item>

                    <Accordion.Item eventKey="5" onClick={() => clickHandler(4)}>
                        <Accordion.Header>Восстановление психического здоровья</Accordion.Header>
                        {activeAccordionItem === 4 && <Suspense fallback={<CustomSpinner/>}>
                            <Pt5/>
                        </Suspense>}
                    </Accordion.Item>

                    <Accordion.Item eventKey="6" onClick={() => clickHandler(5)}>
                        <Accordion.Header>Психогигиена. Здоровая психопрофилактика</Accordion.Header>
                        {activeAccordionItem === 5 && <Suspense fallback={<CustomSpinner/>}>
                            <Pt6/>
                        </Suspense>}
                    </Accordion.Item>

                    <Accordion.Item eventKey="7" onClick={() => clickHandler(6)}>
                        <Accordion.Header>Коррекция негативных установок (часть 1)</Accordion.Header>
                        {activeAccordionItem === 6 && <Suspense fallback={<CustomSpinner/>}>
                            <Pt7/>
                        </Suspense>}
                    </Accordion.Item>

                    <Accordion.Item eventKey="8" onClick={() => clickHandler(7)}>
                        <Accordion.Header>Коррекция негативных установок (часть 2)</Accordion.Header>
                        {activeAccordionItem === 7 && <Suspense fallback={<CustomSpinner/>}>
                            <Pt8/>
                        </Suspense>}
                    </Accordion.Item>

                    <Accordion.Item eventKey="9" onClick={() => clickHandler(8)}>
                        <Accordion.Header>Уникальная АВС-техника</Accordion.Header>
                        {activeAccordionItem === 8 && <Suspense fallback={<CustomSpinner/>}>
                            <Pt9/>
                        </Suspense>}
                    </Accordion.Item>

                    <Accordion.Item eventKey="10" onClick={() => clickHandler(9)}>
                        <Accordion.Header>Агрессия</Accordion.Header>
                        {activeAccordionItem === 9 && <Suspense fallback={<CustomSpinner/>}>
                            <Pt10/>
                        </Suspense>}
                    </Accordion.Item>

                    <Accordion.Item eventKey="11" onClick={() => clickHandler(10)}>
                        <Accordion.Header>Обида</Accordion.Header>
                        {activeAccordionItem === 10 && <Suspense fallback={<CustomSpinner/>}>
                            <Pt11/>
                        </Suspense>}
                    </Accordion.Item>

                    <Accordion.Item eventKey="12" onClick={() => clickHandler(11)}>
                        <Accordion.Header>Самооценка</Accordion.Header>
                        {activeAccordionItem === 11 && <Suspense fallback={<CustomSpinner/>}>
                            <Pt12/>
                        </Suspense>}
                    </Accordion.Item>
                </Accordion>
            </div>
        </Col>
    );
})

export default OnlineSchool;