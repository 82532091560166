import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Title from '../../components/title/Title';
import { kitType } from '../../utils/consts';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import KitWebinarItem from './kitWebinarItem/KitWebinarItem';
import RobokassaForm from '../../components/robokassaForm/RobokassaForm';
import { getPaymentData } from '../../api/generalApi';
import { observer } from 'mobx-react';
import { useStore } from '../../store';
import GiftButton from '../../components/giftButton/giftButton';
import './kitMoreDetails.scss';
import PaymentEpointButton from '../../components/paymentEpointButton/PaymentEpointButton';

const KitMoreDetails = observer(() => {
  const { privateStore, userStore } = useStore();

  const form = useRef(null);
  const type = kitType;
  let { id } = useParams();
  const kit = privateStore.kit;
  const isAuthUser = userStore.isAuth;

  const [payData, setPayData] = useState({});

  useEffect(() => {
    privateStore.setKitById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthUser]);

  const buyReq = () => {
    const params = { type, id };
    getPaymentData(params).then((res) => {
      setPayData(res);
      form.current.submit();
    });
  };

  const buyHandler = () => {
    if (isAuthUser) {
      buyReq();
    }
  };

  return (
    <Col className="col-page-wrapper kits-more-details__wrap">
      <Breadcrumbs to={2} />
      <div className="title-responsive">
        <Title t={kit.title} mt={'1.5rem'} mb={'1.5rem'} />
      </div>
      <Row>
        <Col className="pt-3">
          <Row>
            <Col xs={12} md={6} className="kits-more-details__inner-list-wrap">
              <div className="kits-more-details__list-wrap">
                <h4 className="kits-more-details__list-title">Видеокурсы в комплекте:</h4>
                {kit.webinars &&
                  kit.webinars.map((item) => (
                    <KitWebinarItem
                      key={item.id}
                      id={item.id}
                      title={item.title}
                      image={item.image}
                      length={item.length}
                      price={item.price}
                      tags={item.tags}
                    />
                  ))}
              </div>
            </Col>
            <Col xs={12} md={6} className="kits-more-details__description">
              <h4 className="kits-more-details__list-title">Описание комплекта:</h4>
              <div dangerouslySetInnerHTML={{ __html: `${kit.description}` }} />
              <div className="kit-more-details__info">
                <div>
                  В комплект входит {kit.webinars && kit.webinars.length}{' '}
                  {kit.webinars && kit.webinars.length < 5 ? 'видеокурса' : 'видеокурсов'}
                </div>
                <div>
                  Стоимость: <s>{kit.old_price === 0 ? '' : kit.old_price}</s>{' '}
                  <span className="kit-more-details__actual-price">{kit.price} ₽</span>
                </div>
              </div>
              <GiftButton id={id} type={type} />
              <div className="kit-more-details__btn-wrap">
                <RobokassaForm refKey={form} payData={payData} />
                {!isAuthUser && (
                  <Link
                    className="kit-more-details__buy-btn"
                    to={{ pathname: '/modal/auth', state: { modal: true } }}
                  >
                    Приобрести комплект из РФ
                  </Link>
                )}

                {isAuthUser && (
                  <button className="kit-more-details__buy-btn" onClick={() => buyHandler()}>
                    Приобрести комплект из РФ
                  </button>
                )}
                <PaymentEpointButton id={id} type={type} price={kit.price_azn} webinar_state='STATE_CAN_BUY' />
              </div>
              {kit.price_azn !== undefined && kit.price_azn > 0 && (
                <Col className="kit-more-details__after-btns">
                  <span className="kit-more-details__buy-btn-asterisk">*</span>В случае оплаты не из
                  РФ, сумма списывается в валюте вашей карты по курсу к манату (денежная единица
                  Азербайджана)
                </Col>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
});

export default KitMoreDetails;
