import React, { useEffect, useState } from 'react';
import 'draft-js/dist/Draft.css';
import './exerciesAdmin.scss'
import { observer } from "mobx-react";
import { useStore } from "../../../../../store";
import { Accordion, Button, Dropdown } from "react-bootstrap";
import ModalWithDraftJs from "./ModalWithDraftJs";
import {
  deleteExercise,
  getExerciseById,
  saveExercise,
  updateLesson,
  updateQuestion
} from "../../schoolApi";
import ExercisesAdminTable from "./exerciseAdminTable/ExercisesAdminTable";
import ExercisesAdminCheckbox from "./exerciseAdminCheckbox/ExercisesAdminCheckbox";
import ExercisesAdminTextarea from "./exerciseAdminTextarea/ExercisesAdminTextarea";
import { Gear } from "react-bootstrap-icons";
import ChoiceExerciseTypeModal from "./choiceExerciseType/ChoiceExerciseTypeModal";
import ExerciseAdminMediaFile from "./exerciseAdminMediaFile/ExerciseAdminMediaFile";
import ExerciseAdminQuestionWithButtons from "./exerciseAdminQuestionWithButtons/ExerciseAdminQuestionWithButtons";

const ExerciseAddUpdate = observer(({ currentExerciseId }) => {
    const { exercisesStore } = useStore()

    const exercise = exercisesStore.exercise

    useEffect(() => {
      if (currentExerciseId) {
        getExerciseById(currentExerciseId).then((res) => exercisesStore.setExercise(res))
      } else {
        exercisesStore.clearExercise()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentExerciseId]);

    const [show, setShow] = useState(false);
    const [editorValue, setEditorValue] = useState('editorValue')

    const [showChoiceExerciseType, setShowChoiceExerciseType] = useState(false);

    const handleCloseChoiceExerciseType = () => setShowChoiceExerciseType(false);
    const handleShowChoiceExerciseType = () => setShowChoiceExerciseType(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function setExerciseTitleHandler() {
      handleShow()
      setEditorValue(exercise.title)
      window.exerciseDraftJsProvider = exercisesStore.setExerciseTitle
    }

    function setExerciseDescriptionHandler() {
      handleShow()
      setEditorValue(exercise.description)
      window.exerciseDraftJsProvider = exercisesStore.setExerciseDescription
    }

    function addLesson() {
      exercisesStore.addLesson()
    }

    function deleteLessonHandler(lessonId) {
      exercisesStore.deleteLesson(lessonId)
    }

    function setLessonDescriptionHandler(lessonId) {
      handleShow()
      setEditorValue(exercise.lessons[lessonId]?.description)
      window.exerciseDraftJsProvider = exercisesStore.setLessonDescription
      window.lessonId = lessonId
    }

    function deleteLessonDescriptionHandler(lessonId) {
      exercisesStore.deleteLessonDescription(lessonId)
    }

    function addQuestionHandler() {
      setShowChoiceExerciseType(true)
    }


    function setQuestionTitleHandler(lessonId, questionId) {
      handleShow()
      setEditorValue(exercise.lessons[lessonId]?.questions[questionId].title)
      window.exerciseDraftJsProvider = exercisesStore.setQuestionTitle
      window.lessonId = lessonId
      window.questionId = questionId
    }

    function addCheckboxInputHandler(lessonId, questionId) {
      exercisesStore.createCheckbox(lessonId, questionId)
    }

    function addTextareaHandler(lessonId, questionId) {
      exercisesStore.createTextarea(lessonId, questionId)
    }

    function addTableHandler(lessonId, questionId) {
      exercisesStore.addTable(lessonId, questionId)
    }

    function addDownloadBtnHandler() {
      exercisesStore.setDownloadBtn()
    }

    function setDownloadBtnTextHandler(value) {
      exercisesStore.setDownloadBtnText(value)
    }

    function setDownloadBtnLinkHandler(value) {
      exercisesStore.setDownloadBtnLink(value)
    }

    function saveExerciseHandler() {
      saveExercise(exercise).then(() => alert('сохарнено'))
    }

    // function updateExerciseHandler(id) {
    //     const exercise = {
    //         title: exercise.title,
    //         description: exercise.description,
    //         url: exercise.url,
    //         properties: exercise.properties,
    //     }
    //     updateExercise(exercise, id).then(() => alert('обновлено'))
    // }

    function updateLessonHandler(item) {
      const lesson = {
        title: item.title,
        description: item.description,
        questions: item.url,
      }

      updateLesson(lesson, item.id).then(() => alert('обновлено'))
    }

    function updateQuestionHandler(item) {
      const question = {
        title: item.title,
        type: item.description,
        properties: item.properties,
      }

      updateQuestion(question, item.id).then(() => alert('Вопрос обновлен'))
    }

    function deleteQuestionHandler(lessonId, questionId) {
      exercisesStore.deleteQuestion(lessonId, questionId)
    }

    return (
      <Accordion.Body>
        <div className="exercise-new">
          <div className="pt-wrap">
            <div className="pt0__top">
              <div className="pt0__top__iframe-wrap">
                <iframe width="100%" height="400px" src="https://player.vimeo.com/video/376065897"
                        title="title"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""/>
              </div>
              <div className="pt0__text-wrap">
                <div className="pt0__text-edit-btn">
                  <Button onClick={() => setExerciseTitleHandler()}>
                    Заголовок
                  </Button>
                  <Button onClick={() => setExerciseDescriptionHandler()}>
                    Описание
                  </Button>
                </div>
                <div dangerouslySetInnerHTML={{ __html: `${exercise.description}` }}/>
                <Button variant={"primary"}
                        onClick={() => updateLessonHandler()}>
                  Обновить
                </Button>
              </div>
            </div>
            <div>
              <div>
                {exercise?.lessons?.map((lessonItem) => {
                  return <div key={lessonItem.id} className="exercise-new__lesson">
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary">
                          Настройки <Gear/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => setLessonDescriptionHandler(lessonItem.id)}>
                            {lessonItem.description === '<p></p>\n' ? 'Создать описание lesson' : 'Редактировать описание lesson'}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => deleteLessonDescriptionHandler(lessonItem.id)}>
                            Удалить описание lesson
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => addQuestionHandler(lessonItem.id)}>
                            Добавить вопрос question
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => deleteLessonHandler(lessonItem.id)}>
                            Удалить упражнение lesson
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <Button variant={"primary"}
                            onClick={() => updateLessonHandler(
                              lessonItem
                            )}>
                      Обновить задание
                    </Button>

                    <ChoiceExerciseTypeModal
                      showChoiceExerciseType={showChoiceExerciseType}
                      setShowChoiceExerciseType={setShowChoiceExerciseType}
                      handleCloseChoiceExerciseType={handleCloseChoiceExerciseType}
                      handleShowChoiceExerciseType={handleShowChoiceExerciseType}
                      lessonItem={lessonItem}
                    />

                    {lessonItem.description &&
                      <div dangerouslySetInnerHTML={{ __html: `${lessonItem.description}` }}/>}
                    {lessonItem.questions.map(questionItem => {
                      return <div key={questionItem.id} className="exercise-new__question">
                        <div>
                          <div dangerouslySetInnerHTML={{ __html: `${questionItem.title}` }}/>

                          <Button variant={"success"}
                                  onClick={() => setQuestionTitleHandler(lessonItem.id, questionItem.id)}>
                            Добавить текст перед question
                          </Button>
                          <div>
                            <div>

                              {questionItem.type === 'checkbox' &&
                                <Button size={"sm"}
                                        variant={"success"}
                                        onClick={() => addCheckboxInputHandler(lessonItem.id, questionItem.id)}>
                                  Создать новый чекбокс
                                </Button>}

                              {questionItem.type === 'textarea' &&
                                <Button size={"sm"}
                                        variant={"success"}
                                        onClick={() => addTextareaHandler(lessonItem.id, questionItem.id)}>
                                  Создать новое текстовое поле
                                </Button>}

                              {questionItem.type === 'checkbox' && questionItem.properties.checkboxes?.map((propertyItem, index) => {
                                return (<ExercisesAdminCheckbox key={index} lessonItem={lessonItem}
                                                                questionItem={questionItem}
                                                                propertyItem={propertyItem}
                                />)
                              })}

                              {questionItem.type === 'textarea' && questionItem.properties.textarea?.map((propertyItem, index) => {
                                return (<ExercisesAdminTextarea key={index} lessonItem={lessonItem}
                                                                questionItem={questionItem}
                                                                propertyItem={propertyItem}
                                />)
                              })}

                              {questionItem.type === 'table' && questionItem.properties.table?.map((tableItem, index) => {
                                  return (<ExercisesAdminTable key={index} lessonItem={lessonItem}
                                                               questionItem={questionItem}
                                                               tableItem={tableItem}
                                  />)
                                }
                              )}

                              {questionItem.type === 'table' &&
                                <Button variant={"success"}
                                        onClick={() => addTableHandler(lessonItem.id, questionItem.id)}>
                                  Добавить новую таблицу
                                </Button>}

                              {questionItem.type === 'mediaFile' && <ExerciseAdminMediaFile/>}

                              {questionItem.type === 'questionWithButtons' &&
                                <ExerciseAdminQuestionWithButtons/>}

                            </div>
                          </div>
                        </div>

                        <Button variant={"success"}
                                onClick={() => updateQuestionHandler(
                                  questionItem
                                )}>
                          Обновить вопрос
                        </Button>

                        <Button variant={"danger"}
                                onClick={() => deleteQuestionHandler(
                                  lessonItem.id,
                                  questionItem.id
                                )}>
                          Удалить вопрос
                        </Button>
                      </div>
                    })}

                  </div>
                })}
              </div>
            </div>

            <Button onClick={() => addLesson()}>
              Добавить задание
            </Button>

            <Button onClick={() => addDownloadBtnHandler()}>
              Добавить кнопку
            </Button>

            {exercise.properties?.downloadBtn?.toggle &&
              <>
                <input type="text"
                       value={exercise.properties.downloadBtn.text}
                       onChange={(e) =>
                         setDownloadBtnTextHandler(
                           e.target.value
                         )
                       }/>

                <input type="text"
                       value={exercise.properties.downloadBtn.link}
                       onChange={(e) =>
                         setDownloadBtnLinkHandler(
                           e.target.value
                         )
                       }/>
              </>
            }
            <Button variant={"danger"} onClick={() => saveExerciseHandler()}>
              Сохранить урок exercise
            </Button>
            <Button variant={"danger"} onClick={() => deleteExercise(currentExerciseId)}>
              Удалить урок exercise
            </Button>
            <hr/>
            <pre>{JSON.stringify(exercise, null, 2)}</pre>
          </div>
          <ModalWithDraftJs handleClose={handleClose}
                            show={show}
                            editorValue={editorValue}
                            setEditorValue={setEditorValue}
                            setText={window.exerciseDraftJsProvider}
                            lessonId={window.lessonId}
                            questionId={window.questionId}
          />
        </div>
      </Accordion.Body>
    );
  }
)

export default ExerciseAddUpdate;