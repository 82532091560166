import React from 'react';
import './exerciseItemTextarea.scss';
import {observer} from "mobx-react";
import {useStore} from "../../../../store";

const ExerciseItemTextarea = observer(({lessonItem, questionItem, propertyItem}) => {
    const {userExercisesStore} = useStore()
    function setTextareaTextHandler(lessonId, questionId, propertyId, value) {
        userExercisesStore.setTextareaText(lessonId, questionId, propertyId, value)
    }

    return (
        <div className="exercise-item-textarea">
            <div className="exercise-item-textarea__title">{propertyItem.title}</div>
            <div>
                <textarea placeholder="Заполните поле"
                          required="required"
                          value={propertyItem.text}
                          onChange={(e) => setTextareaTextHandler(
                              lessonItem.id,
                              questionItem.id,
                              propertyItem.id,
                              e.target.value
                          )}/>
            </div>
        </div>
    );
})

export default ExerciseItemTextarea;