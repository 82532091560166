import React from 'react'
import {Modal} from 'react-bootstrap';
import './AlertModal.scss'

function AlertModal({show, onHide, text, d = 'block', icon}) {

    return (
        <>
            <Modal className="data-succes-modal"
                   show={show}
                   onHide={onHide}
                   aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Body>
                    {text}
                    <span style={{display: d}}>
                        {icon}
                    </span>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AlertModal