import React from 'react';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import {
  authRoutes,
  adminRoutes,
  publicRoutes,
} from '../../routes';
import './breadcrumbs.scss';
import { HOME_ROUTE } from "../../utils/consts";

const Breadcrumbs = ({ from = 0, to }) => {
  const routes = [...authRoutes, ...adminRoutes, ...publicRoutes];
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <div className="breadcrumbs">
      <NavLink to={HOME_ROUTE}>
          Главная
      </NavLink>
        {breadcrumbs.slice(1, to).map(({ match, breadcrumb}) => (
      <span key={match.url} className="breadcrumbs-item">
          <NavLink to={match.url}>{breadcrumb}</NavLink>
      </span>
    ))}
    </div>
  );
};

export default Breadcrumbs;