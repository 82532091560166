import req from './req';

export const postArticle = async (params) => await req.post('api/v1/admin/articles', params, {
    headers: {'Content-Type': 'multipart/form-data'}
})

export const editArticle = async (id, params) => await req.post(`api/v1/admin/articles/${id}/update`, params, {
    headers: {'Content-Type': 'multipart/form-data'}
})

export const postCertificate = async (params) => await req.post('api/v1/admin/certificates', params, {
    headers: {'Content-Type': 'multipart/form-data'}
})

export const editCertificate = async (id, params) => await req.post(`api/v1/admin/certificates/${id}/update`, params, {
    headers: {'Content-Type': 'multipart/form-data'}
})

export const postFeedback = async (params) => await req.post('api/v1/admin/feedbacks', params, {
    headers: {'Content-Type': 'multipart/form-data'}
})

export const editFeedback = async (id, params) => await req.post(`api/v1/admin/feedbacks/${id}/update`, params, {
    headers: {'Content-Type': 'multipart/form-data'}
})

export const postKit = async (params) => await req.post('api/v1/admin/kits', params, {
    headers: {'Content-Type': 'multipart/form-data'}
})

export const editKit = async (id, params) => await req.post(`api/v1/admin/kits/${id}/update`, params, {
    headers: {'Content-Type': 'multipart/form-data'}
})

export const postWebinar = async (params) => await req.post('api/v1/admin/webinars', params, {
    headers: {'Content-Type': 'multipart/form-data'}
})

export const editWebinar = async (id, params) => await req.post(`api/v1/admin/webinars/${id}/update`, params, {
    headers: {'Content-Type': 'multipart/form-data'}
})

export const editTariff = async (id, params) => await req.post(`api/v1/school/admin/tariffs/${id}/update`, params);

export const postWebinarCustomPrice = async (id, params) => await req.post(`api/v1/admin/webinars/${id}/add-custom-price`, params);

export const getAdminWebinars = async () => await req.get('api/v1/admin/webinars');
export const getAdminExercises = async () => await req.get('api/v1/school/admin/exercises');
export const getAdminAnswersUsers = async (id) => await req.get(`api/v1/school/admin/answers/users?exercise_id=${id}`);
export const getAdminAnswersUsersAttempts = async (exercise_id, user_id) => await req.get(`api/v1/school/admin/users/attempts?exercise_id=${exercise_id}&user_id=${user_id}&with_answers=true`);
export const getAdminTariffs = async () => await req.get('api/v1/school/admin/tariffs');

export const AttemptsChange = async (id, data) => await req.post(`api/v1/school/admin/users/attempts/${id}/change`, data);

export const getPsychoPreregistration = async (page) => await req.get(`api/v1/admin/psycho/pre-registrations?page=${page}`);
