import React from 'react';
import './schoolAdmin.scss';
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import {
    SCHOOL_ADMIN_ROUTE,
    SCHOOL_ANSWERS_ROUTE,
    SCHOOL_EXERCIES_ROUTE,
    SCHOOL_TARIFFS_ROUTE
} from "../../../utils/consts";
import Exercises from "./Exercises/Exercises";
import Tariffs from "./Tariffs/Traiffs";
import Answers from "./Answers/Answers";
import ErrorFallback from "../../../components/errorsCatchers/appErrorsCatcher/ErrorFallback";
import {ErrorBoundary} from "react-error-boundary";
import {Nav} from "react-bootstrap";

function SchoolAdmin() {
    return (
        <>
            <Nav variant="tabs" defaultActiveKey="/home">
                <Nav.Item>
                    <Nav.Link as={NavLink} to={SCHOOL_ADMIN_ROUTE + SCHOOL_EXERCIES_ROUTE}>
                        Упражнения
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to={SCHOOL_ADMIN_ROUTE + SCHOOL_TARIFFS_ROUTE}>
                        Тарифы
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to={SCHOOL_ADMIN_ROUTE + SCHOOL_ANSWERS_ROUTE}>
                        Вопросы
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <Switch>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Route path={SCHOOL_ADMIN_ROUTE + SCHOOL_EXERCIES_ROUTE}>
                        <Exercises/>
                    </Route>
                    <Route path={SCHOOL_ADMIN_ROUTE + SCHOOL_TARIFFS_ROUTE}>
                        <Tariffs/>
                    </Route>
                    <Route path={SCHOOL_ADMIN_ROUTE + SCHOOL_ANSWERS_ROUTE}>
                        <Answers/>
                    </Route>
                    <Redirect to={SCHOOL_ADMIN_ROUTE + SCHOOL_EXERCIES_ROUTE}/>
                </ErrorBoundary>
            </Switch>
        </>
    );
}

export default SchoolAdmin;
