import React from 'react';
import {Dropdown, Table} from "react-bootstrap";
import {useStore} from "../../../../../../store";
import {Gear} from "react-bootstrap-icons";
import './exercisesAdminTable.scss';

function ExercisesAdminTable({lessonItem, questionItem, tableItem}) {

    const {exercisesStore} = useStore()

    function setColTextHandler(lessonId, questionId, value, tableId, rowId, colId) {
        exercisesStore.setColText(lessonId, questionId, value, tableId, rowId, colId)
    }

    function createColWithTextHandler(lessonId, questionId, tableId, rowId) {
        exercisesStore.addColWithText(lessonId, questionId, tableId, rowId)
    }

    function createColWithTextareaHandler(lessonId, questionId, tableId, rowId) {
        exercisesStore.addColWithTextarea(lessonId, questionId, tableId, rowId)
    }

    function setThColHandler(lessonId, questionId, tableId, rowId) {
        exercisesStore.setThCol(lessonId, questionId, tableId, rowId)
    }

    function deleteRowHandler(lessonId, questionId, tableId, rowId) {
        exercisesStore.deleteRow(lessonId, questionId, tableId, rowId)
    }

    function createRowHandler(lessonId, questionId, tableId) {
        exercisesStore.addRow(lessonId, questionId, tableId)
    }

    function abilityToAddFieldHandler(lessonId, questionId, rowId) {
        exercisesStore.setAbilityToAddField(lessonId, questionId, rowId)
    }

    function deleteTableHandler(lessonId, questionId, tableId) {
        exercisesStore.deleteTable(lessonId, questionId, tableId)
    }

    return (
        <Table bordered className="exercises-admin-table">
            <tbody>
            {tableItem.rows?.map((rowItem, index) => {
                return (
                    <tr key={index}>
                        {rowItem.cols.map((colItem, index) => {
                            return (
                                <td key={index}>
                                    <input type="text"
                                           value={colItem.text}
                                           onChange={(e) =>
                                               setColTextHandler(
                                                   lessonItem.id,
                                                   questionItem.id,
                                                   e.target.value,
                                                   tableItem.id,
                                                   rowItem.id,
                                                   colItem.id
                                               )}
                                    />
                                </td>
                            )
                        })}

                        <Dropdown className="exercises-admin-table__row-edit-dropdown">
                            <Dropdown.Toggle variant={"success"}>
                                <Gear/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() =>
                                    createColWithTextHandler(
                                        lessonItem.id,
                                        questionItem.id,
                                        tableItem.id,
                                        rowItem.id,
                                    )}>
                                    Добавить колонку с текстом
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() =>
                                    createColWithTextareaHandler(
                                        lessonItem.id,
                                        questionItem.id,
                                        tableItem.id,
                                        rowItem.id,
                                    )}>
                                    Добавить колонку - текстовое поле
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() =>
                                    setThColHandler(
                                        lessonItem.id,
                                        questionItem.id,
                                        tableItem.id,
                                        rowItem.id,
                                    )}>
                                    Сделать заглавной строкой
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() =>
                                    deleteRowHandler(
                                        lessonItem.id,
                                        questionItem.id,
                                        tableItem.id,
                                        rowItem.id,
                                    )}>
                                    Удалить строку
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </tr>
                )
            })}
            </tbody>
            <Dropdown>
                <Dropdown.Toggle variant={"success"}>
                   Управление таблицей
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => createRowHandler(
                        lessonItem.id,
                        questionItem.id,
                        tableItem.id
                    )}>
                        Добавить строку в таблицу
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() =>
                        abilityToAddFieldHandler(
                            lessonItem.id,
                            questionItem.id
                        )}>
                        {questionItem.properties.table[0].abilityToAddField ?
                            'Отключить возможность добавления пользовательского поля' :
                            'Включить возможность добавления пользовательского поля'}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() =>
                        deleteTableHandler(
                            lessonItem.id,
                            questionItem.id,
                            tableItem.id
                        )}>
                        Удалиь таблицу
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Table>
    );
}

export default ExercisesAdminTable;