import { makeAutoObservable, runInAction } from 'mobx';
import { getPsychoPreregistration } from '../api/admin';

export default class PsychoPreregistration {
    psychoPreregistrations = [];
    paginationData = {
        currentPage: 1,
        from: 1,
        to: 1,
        perPage: 1,
        totalPages: 1,
    };

    constructor() {
        makeAutoObservable(this);
    }

    setPsychoPreregistration = async (page = 1) => {
        const response = await getPsychoPreregistration(page);
        const responseData = await response.data;
        try {
            runInAction(() => {
                this.psychoPreregistrations = responseData;
                this.paginationData = {
                    currentPage: response.current_page,
                    lastPage: response.last_page,
                    from: response.from,
                    to: response.to,
                    perPage: response.per_page,
                    total: response.total,
                };
            });
        } catch (e) {
            console.error(
                'Ошибка при выполнении запроса получения данных о предварительной записи',
            );
        }
    };
}
