import React from 'react';
import {
    ABOUT_ROUTE,
    ACCOUNT_ROUTE,
    ADMIN_ROUTE,
    AQ_ROUTE,
    COMPETENCE_ROUTE,
    COMPETENCES_ROUTE,
    CONSULTATION_ROUTE,
    CONTACTS_ROUTE,
    HOME_ROUTE,
    KITS_ROUTE,
    MY_KITS_ROUTE,
    MY_VIDEOS_ROUTE,
    PASS_RECOVERY_ROUTE,
    TARIFFS,
    REVIEWS_ROUTE,
    SCHOOL_ADMIN_ROUTE,
    SCHOOL_ROUTE,
    VIDEOCOURCES_ROUTE,
} from "./utils/consts";

import HomePage from "./pages/home/HomePage";
import AboutPage from "./pages/about/AboutPage";
import VideocoursesPage from "./pages/videocources/VideoCoursesPage";
import AqPage from "./pages/aq/AqPage";
import CompetencesPage from "./pages/competences/CompetencesPage";
import CompetencePage from "./pages/competences/competence/CompetencePage";
import VideoCource from "./pages/videocources/videoCourse/VideoCource";
import ContactsPage from "./pages/contacts/ContactsPage";
import Kits from "./pages/kits/Kits";
import KitMoreDetails from "./pages/kits/KitMoreDetails";
import PasswordRecoveryPage from "./pages/passwordRecovery/PasswordRecoveryPage";
import CompetencePage2 from "./pages/competences/competence/CompetencePage2";
import CompetencePage3 from "./pages/competences/competence/CompetencePage3";
import Feedbacks from "./pages/feedback/Feedbacks";
import AqPageItemWatch from "./pages/aq/aqPageItemWatch/AqPageItemWatch";
import OnlineSchool from "./pages/school/onlineSchool/OnlineSchool";
import SchoolAdmin from "./pages/school/schoolAdmin/SchoolAdmin";
import ConsultationPage from "./pages/consultation/ConsultationPage";
import { TariffsPage } from './pages/rates/Tariffs.component';

const MyKitsWatch = React.lazy(() => import('./components/account/myKits/MyKitsWatch'));
const MyVideosWatch = React.lazy(() => import('./components/account/myVideos/MyVideosWatch'));
const Account = React.lazy(() => import('./pages/account/Account'));

const AdminPage = React.lazy(() => import('./pages/admin/AdminPage'));

export const adminRoutes = [
    {
        path: SCHOOL_ADMIN_ROUTE,
        Component: SchoolAdmin,
        breadcrumb: 'Управление школой'
    },
    {
        path: ADMIN_ROUTE,
        Component: AdminPage,
        breadcrumb: 'Страница администратора'
    },
]

export const authRoutes = [
    {
        path: ACCOUNT_ROUTE + MY_KITS_ROUTE + '/:id',
        Component: MyKitsWatch,
        breadcrumb: 'Просмотр комплекта'
    },
    {
        path: ACCOUNT_ROUTE + MY_VIDEOS_ROUTE + '/:id',
        Component: MyVideosWatch,
        breadcrumb: 'Просмотр видео'
    },
    {
        path: SCHOOL_ROUTE,
        Component: OnlineSchool,
        breadcrumb: 'Онлайн школа'
    },
    {
        path: ACCOUNT_ROUTE,
        Component: Account,
        breadcrumb: 'Аккаунт'
    },
]

export const publicRoutes = [
    {
        path: AQ_ROUTE + '/:id',
        Component: AqPageItemWatch,
        breadcrumb: 'Видеокурс'
    },
    {
        path: VIDEOCOURCES_ROUTE + '/:id',
        Component: VideoCource,
        breadcrumb: 'Видеокурс'
    },
    {
        path: KITS_ROUTE + '/:id',
        Component: KitMoreDetails,
        breadcrumb: 'Набор'
    },
    {
        path: COMPETENCES_ROUTE + COMPETENCE_ROUTE,
        Component: CompetencePage,
        breadcrumb: 'Лечение депрессий и панических атак'
    },
    {
        path: COMPETENCES_ROUTE + COMPETENCE_ROUTE + 2,
        Component: CompetencePage2,
        breadcrumb: 'Семейное консультирование'
    },
    {
        path: COMPETENCES_ROUTE + COMPETENCE_ROUTE + 3,
        Component: CompetencePage3,
        breadcrumb: 'Стрессоустойчивость руководителей'
    },
    {
        path: PASS_RECOVERY_ROUTE,
        Component: PasswordRecoveryPage,
        breadcrumb: 'Восстановление пароля'
    },
    {
        path: COMPETENCES_ROUTE,
        Component: CompetencesPage,
        breadcrumb: 'Мои компетенции'
    },
    {
        path: ABOUT_ROUTE,
        Component: AboutPage,
        breadcrumb: 'Обо мне'
    },
    {
        path: VIDEOCOURCES_ROUTE,
        Component: VideocoursesPage,
        breadcrumb: 'Видеокурсы'
    },
    {
        path: AQ_ROUTE,
        Component: AqPage,
        breadcrumb: 'Вопросы и ответы'
    },
    {
        path: CONSULTATION_ROUTE,
        Component: ConsultationPage,
        breadcrumb: 'Консультация'
    },
    {
        path: CONTACTS_ROUTE,
        Component: ContactsPage,
        breadcrumb: 'Контакты'
    },
    {
        path: KITS_ROUTE,
        Component: Kits,
        breadcrumb: 'Комплекты'
    },
    {
        path: REVIEWS_ROUTE,
        Component: Feedbacks,
        breadcrumb: 'Отзывы'
    },
    {
        path: HOME_ROUTE,
        Component: HomePage,
        breadcrumb: 'Главная'
    },
    {
        path: TARIFFS,
        Component: TariffsPage,
        breadcrumb: 'Психотипы'
    },
]