import React from 'react';
import {Clock} from 'react-bootstrap-icons';
import {NavLink} from 'react-router-dom';
import BtnWithArrow from '../btnWithArrow/BtnWithArrow';
import GiftButton from "../giftButton/giftButton";
import { webinarType } from "../../utils/consts";
import './videoCoursesItem.scss';

const VideoCoursesItem = ({title, length, id, image, is_owner, tags, price, custom_price}) => {
    const accountPage = window.location.href.includes(`/myvideos`, 0) || window.location.href.includes(`/mykits`, 0)

    return (
      <div className="my-videocources-item">
        <img src={image} alt="" />
        <div className="videocources-item__wrap">
          <span className="videocources-item__tags">
            {tags && tags.map((item) => <span key={item.id}>#{item.title} </span>)}
          </span>
          <span className="videocources__title">{title}</span>
          <div className="videocources-item__info-row_account-page">
            <span className="videocources-item__length-wrap">
              <span className="videocources-item__clock-ico_account-page">
                <Clock />
              </span>
              <span>{length}</span>
            </span>
            {price === 0 ? (
              <span className="videocources-item__price-free">Бесплатный видеокурс</span>
            ) : (
              <span className="videocources-item__price">
                Стоимость:{' '}
                {custom_price ? (
                  <>
                    <span style={{ color: 'gray' }}>
                      <s>{price}</s>
                    </span>
                    <span> {custom_price.price}</span>
                  </>
                ) : (
                  <span>{price}</span>
                )}
              </span>
            )}
          </div>
        </div>
        {is_owner && <div className="videocources__is-owner-indicator">Вебинар приобретен!</div>}
        {is_owner ? (
          <div>
            <NavLink to={'/account/myvideos/' + id}>
              <BtnWithArrow
                t={'К просмотру'}
                w={'100%'}
                bg={'#82ac0b'}
                shad={'#82ac0b 1px 1px 3px'}
              />
            </NavLink>
            {price > 0 && <GiftButton id={id} type={webinarType} variant="simple" />}
          </div>
        ) : (
          <div>
            {accountPage ? (
              <NavLink to={'/account/myvideos/' + id}>
                <BtnWithArrow t={'Смотреть'} w={'100%'} />
              </NavLink>
            ) : (
              <NavLink to={`/videocources/` + id}>
                <BtnWithArrow t={'Подробнее'} w={'100%'} />
              </NavLink>
            )}
          </div>
        )}
        {custom_price && <span className="videocources-item__discount-block">Скидка</span>}
      </div>
    );
}

export default VideoCoursesItem;
