import {Button, Modal} from "react-bootstrap";
import {useState} from "react";

//App errors catching

function ErrorFallback({error}) {

    const [onHide, setOnHide] = useState(false);

    return (
        <Modal show={!onHide} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Возникла ошибка</Modal.Title>
            </Modal.Header>
            <Modal.Body>{error.message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOnHide(true)}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
        // <button onClick={resetErrorBoundary}>Try again</button>
    )
}

export default ErrorFallback