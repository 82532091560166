import {Button, Modal} from "react-bootstrap";
import React from "react";
import {useStore} from "../../../../../../store";
import {observer} from "mobx-react";

const ChoiceExerciseTypeModal = observer(({
                                              showChoiceExerciseType,
                                              handleCloseChoiceExerciseType,
                                              lessonItem,
                                          }) => {
    const {exercisesStore} = useStore()
    function addQuestionHandler(lessonId, type) {
        exercisesStore.setQuestionType(lessonId, type)
        handleCloseChoiceExerciseType()
    }

    return (
        <Modal size={"lg"} show={showChoiceExerciseType} onHide={handleCloseChoiceExerciseType}>
            <Modal.Header closeButton>
                <Modal.Title>Выберите тип вопроса question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button size={"sm"}
                        onClick={() => addQuestionHandler(lessonItem.id, 'checkbox')}>
                    Чекбокс
                </Button>

                <Button size={"sm"}
                        onClick={() => addQuestionHandler(lessonItem.id, 'textarea')}>
                    Текстовое поле
                </Button>

                <Button size={"sm"}
                        onClick={() => addQuestionHandler(lessonItem.id, 'table')}>
                    Таблица
                </Button>

                <Button size={"sm"}
                        onClick={() => addQuestionHandler(lessonItem.id, 'mediaFile')}>
                    Прикрепить медиафайл
                </Button>

                <Button size={"sm"}
                        onClick={() => addQuestionHandler(lessonItem.id, 'questionWithButtons')}>
                    Кнопки (да, нет)
                </Button>
            </Modal.Body>
        </Modal>
    );
})

export default ChoiceExerciseTypeModal