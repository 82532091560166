import React from 'react';
import {Col, Form} from "react-bootstrap";
import './signIn.scss';
import RegularBtn from '../regularBtn/RegularBtn';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Link} from "react-router-dom";
import {useStore} from "../../store";

const schema = yup.object().shape({
    login: yup.string().trim().email('Введите корректный email').required('Введите email'),
    password: yup.string().trim().typeError('Должно быть строкой').required('Введите пароль'),
});

const SignIn = ({onHide}) => {
    const {userStore} = useStore();

    const signInHandler = (values) => {
        userStore.userSignIn(values);
        onHide()
    };


    return (
        <Formik
            validationSchema={schema}
            onSubmit={signInHandler}
            initialValues={{
                login: '',
                password: '',
            }}>
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  isValid,
                  errors,
                  dirty
              }) => (
                <Form noValidate onSubmit={handleSubmit} className="sign-in">
                    <div>
                        <Form.Group as={Col} md="12" controlId="validationFormik01">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Email"
                                name="login"
                                value={values.login}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={!!errors.login}
                            />
                            <Form.Control.Feedback type="invalid" className="mb-3">
                                {errors.login}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationFormik02">
                            <Form.Label className="signin-password-form-label">
                                Пароль
                                <Link
                                    className="signin-password-recovery"
                                    to={{pathname: '/modal/reset', state: {modal: true}}}
                                >
                                    Забыли пароль?
                                </Link>
                            </Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Пароль"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={!!errors.password}
                            />
                            <Form.Control.Feedback type="invalid" className="mb-3">
                                {errors.password}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <RegularBtn onoff={!isValid || !dirty} type={"submit"} t={'Войти'} mt={'0'}/>
                </Form>
            )}
        </Formik>
    );
};

export default SignIn;