import React from 'react';
import { Col, Row } from 'react-bootstrap';
import img from '../../../images/about-me.jpg'
import Title from '../../title/Title';
import './aboutMe.scss';
import { NavLink } from "react-router-dom";
import { ArrowRightCircle } from "react-bootstrap-icons";

function AboutMe() {
    return (
        <Row className="about-me">
            <Col className="about-me-inner-wrap">
                <Title t={'Обо мне'} />
                <Row>
                    <Col md={{ span: 4 }}>
                        <img src={img} alt="" style={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%'
                        }} />
                    </Col>
                    <Col md={{ span: 8 }} className="about-me__text-wrap">
                        <p>
                            Меня хорошо знают в профессиональном сообществе как человека, который очень
                            много работает и колоссальные деньги вкладывает в свое развитие. На сегодняшний
                            день не знаю специалиста с таким же высоким европейским и российским образовательным
                            стандартом. Считаю, что в современном мире ценятся только уникальные навыки.
                            Уникальные навыки, как крупицы золота, систематически, как привередливый
                            золотоискатель, ищу и нахожу по всему миру.
                        </p>
                        <p>
                            Если лечить-то так, чтобы результат был ярким и психологическая боль уходила
                            навсегда. Помощь должна быть такой, чтобы пациент вспоминал тебя добрым словом
                            и доверял лечение своих детей.
                        </p>
                        <p>Этому меня научили родители. Я - потомственный врач, уже в третьем
                            поколении.
                            Мой дед, которого ребенком мать отдала в детский дом, (в нищие годы не могла прокормить
                            4-х детей), прошел путь до профессора-хирурга, стал одним из основоположников
                            сердечно-сосудистой хирургии в СССР. Моя бабушка была акушером-гинекологом,
                            кандидатом наук. Мои родители - заслуженные врачи РФ. Мои брат и сестра - врачи.
                            С детства я много времени проводила в больнице, а дома слушала медицинский консилиум.
                            Лечить - для меня также естественно, как дышать.
                        </p>
                        <p>И о самом главном. Я замужем. Мы с супругом воспитываем двух дочерей – Александру и Агнию. Мужа и дочерей
                            считаю главными достижениями в жизни. Свою семью я построила на основании тех знаний о семье,
                            которым обучаю других. И считаю косвенно своими не один десяток детей, родившихся в тех
                            семьях, которые с моей помощью не развелись, а укрепили отношения или построили семью.
                            Правда, не знаю почему, с моей легкой руки рождаются (со значительным перевесом) только
                            девочки.
                        </p>
                        <div className="about-me__link">
                            <NavLink to="/about">
                                Подробнее
                                <ArrowRightCircle />
                            </NavLink>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default AboutMe;