import React from 'react';

function RobokassaForm({refKey, payData}) {
    return (
        <form action='https://auth.robokassa.ru/Merchant/Index.aspx' method="POST" ref={refKey}>
            <input type="hidden" name="MerchantLogin" value={payData.MerchantLogin}/>
            <input type="hidden" name="OutSum" value={payData.OutSum}/>
            <input type="hidden" name="InvId" value={payData.InvId}/>
            <input type="hidden" name="Description" value={payData.Description}/>
            <input type="hidden" name="SignatureValue" value={payData.SignatureValue}/>
            <input type="hidden" name="Culture" value={payData.Culture}/>
            <input type="hidden" name="Receipt" value={payData.Receipt}/>
        </form>
    );
}

export default RobokassaForm;
