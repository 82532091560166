import React from 'react';
import { Col } from 'react-bootstrap';
import './contsctsPage.scss';
import Contacts from '../../components/contacts/Contacts';
import Contact from '../../components/contact/Сontact';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';

const ContactsPage = () => {
    return (
        <Col className="col-page-wrapper">
            <Breadcrumbs />
            <Contacts />
            <Contact bg={'white'} />
        </Col>
    );
}

export default ContactsPage;
