import { Button } from "react-bootstrap";
import './regularBtn.scss'

const RegularBtn = ({ t, bc = 'black', c = 'white', w = '100%', onoff = false, onClick, type, mt = '1rem' }) => {
    return (
        <Button className="regular-btn" disabled={onoff} onClick={onClick} type={type} style={{
            backgroundColor: bc,
            color: c,
            width: w,
            marginTop: mt,
        }}>
            {t}
        </Button >
    );
}

export default RegularBtn;
