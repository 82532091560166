import {makeAutoObservable, toJS} from "mobx"

const _ = require('lodash');

export default class Exercises {

    exercise = {
        description: '',
        url: '',
        title: '',
        properties: {
            doneBtn: true,
            downloadBtn: {
                toggle: false,
                text: '',
                link: ''
            }
        },
        lessons: []
    }

    answersObj = {
        answers: []
    }

constructor()
{
    makeAutoObservable(this);
}

setLocalExercise = (data) => {
    this.exercise = data
}

substitution = (lessonId, questionId) => {
    return this.exercise.lessons.find(i => i.id === lessonId).questions.find(i => i.id === questionId)
}

addAdditionalField = (lessonId, questionId) => {
    const cloneTarget = toJS(this.exercise.lessons.find(i => i.id === lessonId).questions.find(i => i.id === questionId).properties[0])

    cloneTarget.rows.forEach(row => {
        row.cols.forEach(col => {
            if (col.textarea) {
                col.text = ''
            }
        })
    })

    this.exercise.lessons.find(i => i.id === lessonId).questions.find(i => i.id === questionId).properties.push(_.cloneDeep(cloneTarget))

    this.substitution(lessonId, questionId).properties.map((item, index) => {
        item.id = index
        return item
    })
}

setColTextareaText = (lessonId, questionId, tableItemId, rowItemId, colItemId, value) => {
    this.substitution(lessonId, questionId).properties.table[tableItemId].rows[rowItemId].cols[colItemId].text = value
}

setCheckboxActive = (lessonId, questionId, propertyId) => {
    this.substitution(lessonId, questionId).properties.checkboxes.map(item => {
        if (item.id === propertyId) {
            item.active = true
        } else {
            item.active = false
        }
        return null
    })
}

setTextareaText = (lessonId, questionId, propertyId, value) => {
    this.substitution(lessonId, questionId).properties.textarea[propertyId].text = value
    this.setAnswers()
}

setQuestionWithButtonsAnswer = (lessonId, questionId, value) => {
    this.substitution(lessonId, questionId).properties.answer = value
}

setAnswers = () => {
    this.exercise.lessons.forEach(lessonItem => {
        lessonItem.questions.forEach(questionItem => {
            const answersItem = {
                question_id: questionItem.id,
                answer: questionItem
            }
            this.answersObj.answers.push(answersItem)
        })
    })
}
}