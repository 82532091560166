import React from 'react';
import './feedbackVideoItem.scss'

function FeedbacksVideoItem({id, name, description, url}) {
    return (
        <div className="feedback-video-item">
            <iframe style={{height: '100%', width: '100%'}}
                    src={url}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
            </iframe>
            <div className="feedback-video-item__name">
                {name}
            </div>
        </div>
    );
}

export default FeedbacksVideoItem;