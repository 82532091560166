import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { useMediaQuery } from 'react-responsive';
import { useStore } from "../../../store";
import { observer } from "mobx-react";
import Lightbox from 'react-image-lightbox';
import './certificatesSlider.scss';

const CertificatesSlider = observer(() => {
    useEffect(() => {
        publicStore.setCertificates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { publicStore } = useStore()
    const max767 = useMediaQuery({
        query: '(max-width: 767px)'
    })

    const [lightBoxVisible, setLightBoxVisible] = useState(false);
    const [largeImage, setLargeImage] = useState('');

    const certificates = publicStore.certificates

    function lightBoxOpenHandler(img) {
        setLargeImage(img);
        setLightBoxVisible(true);
        console.log('largeImage=', largeImage);
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: !max767,
        draggable: max767,
    };

    return (
        <>
            <Slider {...settings} className="certificates-slider" id="111">
                {certificates && certificates.map(item => {
                    return (
                        <div key={item.id} className="certificates-slider__item">
                            <img src={item.image}
                                alt=""
                                style={{ width: '100%', }}
                                id={`${item.id + '-certificates-slider'}`}
                                onClick={() => lightBoxOpenHandler(item.image)} />
                        </div>
                    )
                }
                )}
            </Slider>
            {lightBoxVisible && (
                <Lightbox
                    mainSrc={largeImage}
                    onCloseRequest={() => setLightBoxVisible(false)}
                    enableZoom={false}
                />
            )}
        </>
    );
})

export default CertificatesSlider;



