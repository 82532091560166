import {makeObservable, observable} from "mobx"
import {getWebinars} from "../api/public";
import {editWebinar, getAdminWebinars, postWebinar, postWebinarCustomPrice} from "../api/admin";

export default class Webinars {
    webinars = []
    adminWebinars = []

    constructor() {
        makeObservable(this, {
            webinars: observable,
            adminWebinars: observable,
        });
    }

    setWebinars = async () => {
        this.webinars = await getWebinars();
    }

    setAdminWebinars = async () => {
        this.adminWebinars = await getAdminWebinars();
    }

    setSorted = (items) => {
        this.adminWebinars = items;
    }

    addWebinar = async (params) => {
        const data = await postWebinar(params)
        this.webinars.push(data)
        return data
    }

    editWebinar = async (id, params) => {
        const data = await editWebinar(id, params)
        this.webinars = this.webinars.map(item => {
            if (item.id === data.id) {
                return data
            } else {
                return item
            }
        })
        return data
    }

    addWebinarCustomPrice = async (id, params) => {
        await postWebinarCustomPrice(id, params)
    }
}