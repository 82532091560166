import React from 'react';
import { Col, Dropdown, DropdownButton, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import { ChevronDown, Person } from 'react-bootstrap-icons';
import { Link, NavLink, useHistory } from 'react-router-dom';
import MobileMenu from '../header/MenuMobile';
import LogoForLightTheme from '../../images/aina-logo-black.png';
import LogoForDarkTheme from '../../images/aina-logo-white.png';

import {
  ACCOUNT_ROUTE,
  CONTACTS_ROUTE,
  HOME_ROUTE,
  MY_COUPONS_ROUTE,
  MY_DATA_ROUTE,
  MY_KITS_21ST_CENTURY_ROUTE,
  MY_KITS_ROUTE,
  MY_PAYMENTS_ROUTE,
  MY_VIDEOS_ROUTE,
  TARIFFS,
  REVIEWS_ROUTE,
  MYHERO,
} from '../../utils/consts';

import './header.scss';
import { useMediaQuery } from 'react-responsive';
import styled, { ThemeProvider } from "styled-components";
import { useStore } from "../../store";

const HeaderStyle = styled.div`
      border-bottom: ${props => props.theme.borderBottom};
      background-color: ${props => props.theme.backgroundColor};
      box-shadow: 0 1px 25px #0000000f;

      a {
        color: ${props => props.theme.color};
      }

      svg {
        color: ${props => props.theme.color};
      }

      button {
        color: ${props => props.theme.color};
      }

      .dropdown-menu {
        background-color: ${props => props.theme.dropdownBgColor};

        a {
          color: ${props => props.theme.innerAcolor}
        }
      }

      @media ${props => props.theme.media.phablet} {
        border-bottom: none !important;
        box-shadow: none !important;
      }
    `;

const Header = () => {
  const history = useHistory();
  const { userStore } = useStore();
  const { privateStore } = useStore();

  const kitWithMark = privateStore.kitByMarkId;

  const pathname = history.location.pathname;

  const exitHandler = () => {
    history.push(HOME_ROUTE);
    userStore.userSignOut()
  };

  const max767 = useMediaQuery({
    query: '(max-width: 767px)'
  });

  const isAuthUser = userStore.isAuth
  const isAdmin = userStore.isAdmin
  const user = userStore.user

  const headerThemeDark = {
    color: "white !important",
    innerAcolor: "black !important",
    backgroundColor: "black !important",
    borderBottom: "1px solid rgb(62 62 62) !important",
    dropdownBgColor: "white !important",
    media: {
      phablet: "(max-width: 768px)",
    }
  };

  const headerThemeLight = {
    color: "black !important",
    innerAcolor: "black !important",
    backgroundColor: "white !important",
    borderBottom: "1px solid #F0F0F0 !important",
    dropdownBgColor: "#f7f7f7 !important",
    media: {
      phablet: "(max-width: 768px)",
    }
  };

  function themeChanger() {
    if (pathname === HOME_ROUTE) {
      return headerThemeDark
    } else {
      return headerThemeLight
    }
  }

  function logoChanger() {
    if (pathname === HOME_ROUTE) {
      return LogoForDarkTheme
    } else {
      return LogoForLightTheme
    }
  }

  return (
    <ThemeProvider theme={themeChanger()}>
      <HeaderStyle
        as={Row}
        className="header"
        style={{
          height: '6.2rem',
          fontFamily: 'Lora, serif',
          justifyContent: 'center',
        }}
      >
        <Col className="col-page-wrapper header-wrapper">
          <Col xs={2} className="header__logo">
            <NavLink to="/">
              <img src={logoChanger()} alt="Logo" className="header__logo" />
            </NavLink>
          </Col>
          <Col xs={2} md={8} className="header__middle">
            {max767 ? (
              <MobileMenu
                customBurgerIcon={false}
                isAuthUser={isAuthUser}
                isAdmin={isAdmin}
                exitHandler={exitHandler}
                kits={kitWithMark}
                userName={user.login}
              />
            ) : (
              <Navbar bg="alert" expand="md">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto" style={{ display: 'flex', alignItems: 'center' }}>
                    <Nav.Link as={Link} to={TARIFFS}>
                      Психотипы
                    </Nav.Link>
                    <Nav.Link href={MYHERO} target="_blank">
                      Мой герой
                    </Nav.Link>
                    <NavDropdown title="Обо мне" id="basic-nav-dropdown" href="/about">
                      <NavDropdown.Item style={{ color: 'red' }} as={Link} to="/about">
                        Обо мне
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={Link} to="/aq">
                        Вопрос-ответ
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={Link} to="/competences">
                        Мои компетенции
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={Link} to="/consultation">
                        Запись на консультацию
                      </NavDropdown.Item>
                    </NavDropdown>{' '}
                    <ChevronDown
                      style={{
                        fontSize: '0.7rem',
                        position: 'relative',
                        left: '-3px',
                      }}
                    />
                    {/*<Nav.Link href="#link">СМИ</Nav.Link>*/}
                    <NavDropdown title="Видеокурсы" id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/videocources">
                        Видеокурсы
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={Link} to="/kits">
                        Комплекты
                      </NavDropdown.Item>
                    </NavDropdown>{' '}
                    <ChevronDown
                      style={{
                        fontSize: '0.7rem',
                        position: 'relative',
                        left: '-3px',
                      }}
                    />
                    <Nav.Link as={Link} to={REVIEWS_ROUTE}>
                      Отзывы
                    </Nav.Link>
                    <Nav.Link as={Link} to={CONTACTS_ROUTE}>
                      Контакты
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            )}
          </Col>
          {pathname === '/' && (
            <NavLink to="/" className="header__logo-mobile">
              <img src={logoChanger()} alt="Logo" />
            </NavLink>
          )}
          <Col
            xs={4}
            md={1}
            style={{
              alignSelf: 'center',
            }}
          >
            {!max767 && (
              <>
                {isAuthUser ? (
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span
                      className="header__account__icon"
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <Person
                        style={{
                          fontSize: '1.2rem',
                        }}
                      />
                      <DropdownButton variant="header" title={user ? user.login : 'dropdowntitle'}>
                        <div className="dropdown-menu-wrapper">
                          <Dropdown.Item as={Link} to={ACCOUNT_ROUTE}>
                            Личный кабинет
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to={ACCOUNT_ROUTE + MY_DATA_ROUTE}>
                            Мои данные
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to={ACCOUNT_ROUTE + MY_VIDEOS_ROUTE}>
                            Мои видеокурсы
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to={ACCOUNT_ROUTE + MY_KITS_ROUTE}>
                            Мои комплекты
                          </Dropdown.Item>
                          {!!kitWithMark?.length && (
                            <Dropdown.Item
                              as={Link}
                              to={ACCOUNT_ROUTE + MY_KITS_21ST_CENTURY_ROUTE}
                            >
                              Психотерапевт 21 века
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item as={Link} to={ACCOUNT_ROUTE + MY_PAYMENTS_ROUTE}>
                            Мои покупки
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to={ACCOUNT_ROUTE + MY_COUPONS_ROUTE}>
                            Активировать купон
                          </Dropdown.Item>
                          {isAdmin && (
                            <Dropdown.Item as={Link} to="/admin/webinars" style={{ color: 'red' }}>
                              Админ панель
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item onClick={() => exitHandler()}>Выход </Dropdown.Item>
                        </div>
                      </DropdownButton>
                    </span>
                  </Col>
                ) : (
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Link
                      className="header__signin"
                      to={{ pathname: '/modal/auth', state: { modal: true } }}
                    >
                      Авторизация
                    </Link>
                  </Col>
                )}
              </>
            )}
          </Col>
        </Col>
      </HeaderStyle>
    </ThemeProvider>
  );
};

export default Header;