import {makeObservable, observable} from "mobx"
import {getArticles, getCertificates, getCompetences, getFeedbacks, getWebinars} from "../api/public";

export default class Public {

    webinars = []
    articles = []
    certificates = []
    competences = []
    feedbacks = []

    constructor() {
        makeObservable(this, {
            webinars: observable,
            articles: observable,
            certificates: observable,
            competences: observable,
            feedbacks: observable,
        });
    }

    setWebinars = async () => {
        this.webinars = await getWebinars();
    }

    setArticles = async () => {
        this.articles = await getArticles();
    }

    setCertificates = async () => {
        this.certificates = await getCertificates();
    }

    setCompetences = async () => {
        this.competences = await getCompetences();
    }

    setFeedbacks = async () => {
        this.feedbacks = await getFeedbacks();
    }
}