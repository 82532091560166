import React from 'react';
import './exerciseItemQuestionWithButtons.scss';
import {Check, X} from "react-bootstrap-icons";
import {observer} from "mobx-react";
import {useStore} from "../../../../store";

const ExerciseItemQuestionWithButtons = observer(({lessonItem, questionItem}) => {

    const {userExercisesStore} = useStore()

    function setQuestionWithButtonsAnswerHandler(lessonId, questionId, value) {
        userExercisesStore.setQuestionWithButtonsAnswer(lessonId, questionId, value)
    }

    return (
        <div className="exercise-item-question-with-buttons__wrap">
                <h6>{questionItem.properties.text}</h6>
                <div className="exercise-item-question-with-buttons">
                    <button className="exercise-item-question-with-buttons__yes" onClick={() => setQuestionWithButtonsAnswerHandler(
                        lessonItem.id,
                        questionItem.id,
                        true
                    )}>
                        <Check/><span>да</span>
                    </button>
                    <button className="exercise-item-question-with-buttons__no" onClick={() => setQuestionWithButtonsAnswerHandler(
                        lessonItem.id,
                        questionItem.id,
                        false
                    )}>
                        <X/><span>нет</span>
                    </button>
                </div>
        </div>
    );
})

export default ExerciseItemQuestionWithButtons;