import {createContext, useContext} from "react";
import User from "./user";
import Public from "./public";
import Private from "./private";
import ErrorsStoreInstance from "./errors";
// import {enableLogging} from 'mobx-logger';
import Articles from "./articles";
import Certificates from "./certificates";
import Feedbacks from "./feedbacks";
import Kits from "./kits";
import Webinars from "./webinars";
import Exercises from "./exercises";
import UserExercise from "./userExercise";
import PsychoPreregistration from './psychoPreregistration';
import Tariffs from "./tariffs";

export class RootStore {
    userStore = new User();
    publicStore = new Public();
    articlesStore = new Articles();
    certificatesStore = new Certificates();
    feedbacksStore = new Feedbacks();
    kitsStore = new Kits();
    webinarsStore = new Webinars();
    privateStore = new Private();
    userExercisesStore = new UserExercise();
    exercisesStore = new Exercises();
    tariffsStore = new Tariffs();
    psychoPreregistrationStore = new PsychoPreregistration();
    errorsStore = ErrorsStoreInstance;
}

// enableLogging({predicate: () => true, action: true, reaction: false, transaction: true, compute: true});

export const store = new RootStore();

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}