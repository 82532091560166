import req from "./req";

export const getWebinars = async () => await req.get('api/v1/user/webinars');
export const getArticles = async () => await req.get('api/v1/user/articles');
export const getCertificates = async () => await req.get('api/v1/user/certificates');
export const getCompetences = async () => await req.get('api/v1/user/competences');
export const getFeedbacks = async () => await req.get('api/v1/user/feedbacks');
export const getSchoolFeedbacks = async () => await req.get('api/v1/school/user/feedbacks');
export const getKits = async () => await req.get('/api/v1/user/kits');
export const getKitsById = async (id) => await req.get(`/api/v1/user/kits/${id}`);
export const getAllTariffs = async (id) => await req.get('/api/v1/school/user/tariffs');