import React, {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import Slider from "react-slick";
import Title from '../title/Title';
import VideocourseSliderItem from './VideocourseSliderItem.jsx'
import './videoСourcesSlider.scss'
import {useMediaQuery} from 'react-responsive';
import {VIDEOCOURCES_ROUTE} from "../../utils/consts";
import LearnMoreLink from "../learnMoreLink/LearnMoreLink";
import {useStore} from "../../store";
import {observer} from "mobx-react";

const VideoCoursesSlider = observer(() => {
    const {publicStore} = useStore()

    const max767 = useMediaQuery({
        query: '(max-width: 767px)'
    })

    const settings = {
        dots: !max767,
        infinite: true,
        speed: 500,
        slidesToShow: max767 ? 2 : 4,
        slidesToScroll: max767 ? 2 : 4,
        adaptiveHeight: true,
        arrows: !max767,
    };

    useEffect(() => {
        publicStore.setWebinars()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userWebinars = publicStore.webinars

    function compare(a, b) {
        if (a.custom_price) {
            return -1;
        }
        if (!b.custom_price) {
            return 1;
        }
        return 0;
    }

    return (
        <Row className="cources-slider" style={{
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Col className="slider-wrap" style={{
                maxWidth: 1500,
                paddingBottom: '3.275rem',
            }}>
                <Row>
                    <Col className="title-responsive">
                        <Title t={'Видеокурсы'}/>
                    </Col>
                </Row>
                <Slider {...settings}>
                    {userWebinars?.slice().sort(compare).map(item => <VideocourseSliderItem
                        key={item.id}
                        title={item.title}
                        id={item.id}
                        image={item.image}
                        length={item.length}
                        age={item.age}
                        price={item.price}
                        created_at={item.created_at}
                        tags={item.tags}
                        custom_price={item.custom_price}
                    />)}
                </Slider>
                <div className="videocources-slider-btn">
                    <LearnMoreLink text={'Все видеокурсы'} width={'14rem'} route={VIDEOCOURCES_ROUTE}/>
                </div>
            </Col>
        </Row>
    );
})

export default VideoCoursesSlider;