import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ArrowRightShort } from 'react-bootstrap-icons';
import Title from '../title/Title';
import {NavLink} from "react-router-dom";

function CompetencesMobile() {
    return (
        <Row style={{
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <Col style={{
                maxWidth: 1500,
                marginBottom: '3rem',
            }}>
                <Row style={{
                    flexDirection: 'column',
                    textAlign: 'center',
                }}>
                    <Col>
                        <Col className="title-responsive">
                            <Title t={'Мои компетенции'} />
                        </Col>
                    </Col>
                    <Col style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Row style={{
                            borderBottom: '1px solid #e2e2e2',
                            marginBottom: '1rem',
                            paddingBottom: '.5rem',
                        }}>
                            <Col>
                                <span style={{
                                    fontWeight: 600,
                                    fontSize: '1.2rem',
                                }}>
                                    Лечение депрессий и панических атак
                                </span>
                                <div>
                                    <NavLink to="/competences/competence">
                                        Узнать больше
                                    </NavLink>
                                    <span style={{
                                        fontSize: 23
                                    }}>
                                        <ArrowRightShort />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{
                            borderBottom: '1px solid #e2e2e2',
                            marginBottom: '1rem',
                            paddingBottom: '.5rem',
                        }}>
                            <Col>
                                <span style={{
                                    fontWeight: 600,
                                    fontSize: '1.2rem',
                                }}>
                                    Семейное консультирование
                                </span>
                                <div>
                                    <NavLink to="/competences/competence2">
                                        Узнать больше
                                    </NavLink>
                                    <span style={{
                                        fontSize: 23
                                    }}>
                                        <ArrowRightShort />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span style={{
                                    fontWeight: 600,
                                    fontSize: '1.2rem',
                                }}>
                                    Стрессоустойчивость руководителей
                                </span>
                                <div>
                                    <NavLink to="/competences/competence3">
                                        Узнать больше
                                    </NavLink>
                                    <span style={{
                                        fontSize: 23
                                    }}>
                                        <ArrowRightShort />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row >
    );
}

export default CompetencesMobile;