import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import Header from '../components/header/Header';
import ErrorModal from '../components/errorsCatchers/networkErrorsCatcher/ErrorModal';
import Footer from '../components/footer/Footer';
import { useStore } from '../store';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

const AppLayout = observer((props) => {
    const { children } = props;
    const { errorsStore } = useStore();
    const { privateStore } = useStore();
    const { userStore } = useStore();
    const pathname = useHistory().location.pathname;

    const isAuth = userStore.isAuth;

    function errorCloseHandler() {
        errorsStore.setIsError(false);
        errorsStore.setErrorMessage('');
    }

    useEffect(() => {
        if (isAuth) {
            privateStore.setKitsByMarkId(1).then();
        }
    }, [privateStore, isAuth]);

    function footerDisable() {
        return pathname.includes('admin');
    }

    return (
        <Container fluid>
            <div className="app-layout-wrap">
                <Header />

                <ErrorModal
                    message={errorsStore.errorMessage}
                    show={errorsStore.isError}
                    errorCloseHandler={errorCloseHandler}
                />

                <Row className="app-wrapper justify-content-center">{children}</Row>

                {!footerDisable() && <Footer />}
            </div>
        </Container>
    );
});

export default AppLayout;
