import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import './answers.scss';

function Answers() {

    const [email, setEmail] = useState('');
    const [tariff, setTariff] = useState('');
    const [status, setStatus] = useState('');

    return (
        <div className="col-page-wrapper answers-wrap">
            <Form className="answers-wrap__form">
                <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email"
                                  placeholder="name@example.com"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}/>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Тариф</Form.Label>
                    <Form.Control as="select"
                                  aria-label="tariff"
                                  value={tariff}
                                  onChange={(e) => setTariff(e.target.value)}>
                        <option>0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Статус задания</Form.Label>
                    <Form.Control as="select"
                                  aria-label="tariff"
                                  value={status}
                                  onChange={(e) => setStatus(e.target.value)}>
                        <option>Не проверено</option>
                        <option value="1">Проверено</option>
                    </Form.Control>
                </Form.Group>

                {/*<Button onClick={f}>*/}
                {/*    Применить*/}
                {/*</Button>*/}
            </Form>
        </div>
    );
}

export default Answers;