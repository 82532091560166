import axios from 'axios'

export const HOME_ROUTE = '/'
export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const ACCOUNT_ROUTE = '/account'
export const ABOUT_ROUTE = '/about'
export const VIDEOCOURCES_ROUTE = '/videocources'
export const AQ_ROUTE = '/aq'
export const TARIFFS = '/tariffs'
export const MYHERO = '/game/myhero'
export const COMPETENCES_ROUTE = '/competences'
export const COMPETENCE_ROUTE = '/competence'
export const MY_VIDEOS_ROUTE = '/myvideos'
export const MY_KITS_ROUTE = '/mykits'
export const MY_KITS_21ST_CENTURY_ROUTE = '/21st-century-psychotherapist'
export const MY_DATA_ROUTE = '/mydata'
export const MY_PAYMENTS_ROUTE = '/mypayments'
export const MY_COUPONS_ROUTE = '/mycoupons'
export const MY_QUESTIONS_ROUTE = '/questions'
export const VIDEOS_ITEM_ROUTE = '/videositem'
export const CONSULTATION_ROUTE = '/consultation'
export const CONTACTS_ROUTE = '/contacts'
export const ADMIN_WEBINARS_ROUTE = '/webinars'
export const KITS_ROUTE = '/kits'
export const REVIEWS_ROUTE = '/reviews'
export const PASS_RECOVERY_ROUTE = '/password-recovery'
export const SCHOOL_ROUTE = '/online-school'
export const SCHOOL_ADMIN_ROUTE = '/school-admin'
export const SCHOOL_EXERCIES_ROUTE = '/exercises'
export const SCHOOL_TARIFFS_ROUTE = '/tarrifs'
export const SCHOOL_EXERCIES_NEW_ROUTE = '/new'
export const SCHOOL_TARIFFS_NEW_ROUTE = '/new'
export const SCHOOL_ANSWERS_ROUTE = '/answers'
export const SCHOOL_EXERCIES_UPDATE_ROUTE = '/update'

export const webinarType = 1
export const kitType = 2
export const consultationType = 3

export const $host = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

export const phoneRegExp = /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/

export const ID = function () {
    return '_' + Math.random().toString(36).substr(2, 9);
};

export const allCountriesPhoneRegExp = /^(\+?[0-9]{1,2})?[\s-]?\(?[0-9]{3}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;
