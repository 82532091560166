import React from 'react';
import { Col } from 'react-bootstrap';
import Competences from '../../components/competences/Competences';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';

const CompetencesPage = () => {
    return (
        <Col style={{ maxWidth: 1500 }} className="pb-5">
            <Breadcrumbs />
            <Competences />
        </Col>
    );
}

export default CompetencesPage;
