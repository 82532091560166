import React, {useEffect, useState} from 'react';
import {Col, Dropdown, DropdownButton, Row} from 'react-bootstrap';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumbs';
import MyVideocourcesItem from '../../components/videoCoursesItem/VideoCoursesItem';
import './videoCoursesPage.scss'
import Title from '../../components/title/Title';
import {useStore} from "../../store";
import {observer} from "mobx-react";
import {toJS} from "mobx";
import {tagsRequest} from "../../api/generalApi";

const VideoCoursesPage = observer(() => {
    const {publicStore} = useStore()

    const [sortingTag, setSortingTag] = useState(0);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        tagsRequest().then((res) => setTags(res))
        publicStore.setWebinars()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const webinars = toJS(publicStore.webinars)

    const sortItems = (a, b) => {
        if (a.sort > b.sort) {
            return 1
        } else {
            return -1
        }
    }

    return (
        <Col className="col-page-wrapper videocources">
            <Breadcrumb active={'Видеокурсы'}/>
            <Row>
                <Col>
                    <Title t={'Все видеокурсы'}/>
                </Col>
            </Row>
            <Row className="videocources__settings">
                <Col className="videocources__tags">
                    <span onClick={() => setSortingTag(0)}>Все вебинары</span>
                    {tags?.map(item => <span key={item.id} onClick={() => setSortingTag(item.id)}>
                            #{item.title}
                        </span>
                    )}
                </Col>
                <DropdownButton variant="black" className="videocources__dropdown" title="Сортировка по тегам">
                    <Dropdown.Item onClick={() => setSortingTag(0)}>Все вебинары</Dropdown.Item>
                    {tags?.map(item => <Dropdown.Item key={item.id} onClick={() => setSortingTag(item.id)}>
                            #{item.title}
                        </Dropdown.Item>
                    )}
                </DropdownButton>
            </Row>
            <Row>
                <Col className="videocources__items-wrap">
                    {sortingTag === 0 && webinars?.sort(sortItems).map(item => {
                        if (item.is_active) {
                            return <MyVideocourcesItem
                                key={item.id}
                                title={item.title}
                                id={item.id}
                                image={item.image}
                                length={item.length}
                                age={item.age}
                                price={item.price}
                                created_at={item.created_at}
                                tags={item.tags}
                                is_owner={item.is_owner}
                                custom_price={item.custom_price}
                            />
                        }
                        return null
                    })}
                    {sortingTag !== 0 && webinars?.map(item => {
                            if (item.tags[0] && item.tags[0].id === sortingTag && item.is_active) {
                                return <MyVideocourcesItem
                                    key={item.id}
                                    title={item.title}
                                    id={item.id}
                                    image={item.image}
                                    length={item.length}
                                    age={item.age}
                                    price={item.price}
                                    created_at={item.created_at}
                                    tags={item.tags}
                                    is_owner={item.is_owner}
                                    custom_price={item.custom_price}
                                />
                            }
                            return null
                        }
                    )}
                </Col>
            </Row>
        </Col>
    );
})

export default VideoCoursesPage;
