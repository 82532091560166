import React from 'react';
import './customSpinner.scss'
import {Spinner} from "react-bootstrap";

function CustomSpinner() {
    return (
        <div className="custom-spinner">
            <Spinner animation="border"/>
        </div>
    );
}

export default CustomSpinner;