import {autorun, computed, makeObservable, observable, toJS} from "mobx"
import {getCurrentUser, getToken, register, userUpdate} from "../api/user";
import ErrorsStoreInstance from "./errors";

export default class User {
    isAuth = false
    isAdmin = false
    user = {}

    constructor() {
        makeObservable(this, {
            isAuth: observable,
            isAdmin: observable,
            user: observable,
            userGetter: computed,
        });
        autorun(() => this.userGetter);
    }

    get userGetter() {
        if (this.user.api_token) {
            this.isAuth = true
        }

        if (toJS(this.user.roles)?.includes('ADMIN')) {
            this.isAdmin = true
        }
        return null
    }

    setUser = async () => {
        if (localStorage.getItem('token') !== null) {
            this.user = await getCurrentUser()
        }
    }

    setToken(token) {
        localStorage.setItem('token', token);
    }

    userSignUp = async (params) => {
        const data = await register(params)
        const token = data?.api_token

        if (token) {
            this.setToken(token)
            this.setUser()
        }
    }

    userSignIn = async (params) => {
        const data = await getToken(params)
        const token = data?.user?.api_token

        if (token) {
            this.setToken(token)
            this.setUser()
        }
    }

    userSignOut = () => {
        this.isAuth = false
        this.isAdmin = false
        localStorage.removeItem('token')
    }

    userEditSelfData = async (params) => {
        const data = await userUpdate(params)
        if (!ErrorsStoreInstance.isError) {
            this.user = data
        }
        return data
    }
}