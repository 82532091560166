import React, {Component} from "react";
import {Editor} from "react-draft-wysiwyg";
import {convertToRaw, EditorState} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import {stateFromHTML} from 'draft-js-import-html';

export default class Draft extends Component {
    contentState = stateFromHTML(this.props.editorValue);
    state = {
        editorState: EditorState.createWithContent(this.contentState),
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    // const [editorState, setEditorState] = useState(EditorState.createWithContent(stateFromHTML()));
    //EditorState - это объект состояния верхнего уровня для редактора.
    //createWithContent Возвращает новый EditorState объект на основе ContentState предоставленного декоратором
    //stateFromHTML Это модуль для DraftJS , который преобразует HTML в содержимое редактора.
    //onEditorStateChange : функция вызывается каждый раз при изменении состояния редактора, переданный аргумент функции является объектом типа  EditorState .

    render() {
        const {editorState} = this.state;
        this.props.setEditorValue(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        return (
            <div>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                />
            </div>
        );
    }
}
