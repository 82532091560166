import { makeAutoObservable, runInAction } from 'mobx';
import { editTariff, getAdminTariffs } from '../api/admin';

export default class Tariffs {
  tariffs = [];

  constructor() {
    makeAutoObservable(this);
  }

  setTariffs = async () => {
    this.tariffs = [];

    try {
      const data = await getAdminTariffs();

      runInAction(() => {
        this.tariffs = data;
      });
    } catch (e) {
      console.error('Ошибка при выполнении запроса получения данных о тарифах');
    }
  };

  editTariff = async (id, params) => {
    try {
      await editTariff(id, params);

      runInAction(() => {
        this.setTariffs();
      });
    } catch (error) {
      console.error('Ошибка при выполнении запроса сохранения данных о тарифе');
    }
  };
}
