import React from "react";
import './countdownRender.scss'

const Completionist = () => <span>Акция завершена</span>;

const CountdownRenderer = ({days, hours, minutes, seconds, completed}) => {
    if (completed) {
        return <Completionist/>;
    } else {
        return (
            <>
                <div className="countdown__items">
                <span className="countdown__item">
                    <div>{days}</div>
                    <span>дней</span>
                </span>
                    <span className="countdown__item">
                    <div>{hours}</div>
                    <span>часов</span>
                </span>
                    <span className="countdown__item">
                    <div>{minutes}</div>
                    <span>минут</span>
                </span>
                    <span className="countdown__item">
                    <div>{seconds}</div>
                    <span>секунд</span>
                </span>
                </div>
            </>
        )
    }
};

export default CountdownRenderer