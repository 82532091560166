import {
    Button,
    Modal
} from "react-bootstrap";

//Network errors catching

const ErrorModal = ({message, show, onHide, errorCloseHandler}) => {
    const onCloseHandler = () => {
        errorCloseHandler()
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Возникла ошибка</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onCloseHandler()}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ErrorModal;