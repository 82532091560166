import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';
import background from '../../images/home-background.jpg';
import './homeBackground.scss';
import { NavLink } from 'react-router-dom';
import { VIDEOCOURCES_ROUTE } from '../../utils/consts';

function HomeBackgroundWrapper() {
    return (
        <Row
            className="home-bg-wrapper "
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), 80%, rgb(0, 0, 0)), url(${background})`,
                backgroundColor: 'black',
                paddingTop: '30vh',
                height: '100vh',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '30% 30%',
                justifyContent: 'center',
                backgroundSize: '106rem',
            }}
        >
            <Col
                style={{
                    color: 'white',
                    maxWidth: 1500,
                }}
            >
                <Row>
                    <Col md={{ span: 6, offset: 6 }}>
                        <Row
                            style={{
                                flexDirection: 'column',
                            }}
                        >
                            <Col className="home-bg-wrapper__name">
                                <h1>Айна Громова</h1>
                            </Col>
                            <Col xs={{ span: 12 }} className="home-bg-wrapper__text-wrapper">
                                <Row
                                    style={{
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Col className="home-bg-wrapper__text">
                                        <span>
                                            <span
                                                style={{
                                                    position: 'relative',
                                                    top: '-.3rem',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: 170,
                                                        height: 2,
                                                        backgroundColor: 'white',
                                                        display: 'inline-block',
                                                        marginRight: 10,
                                                    }}
                                                />
                                            </span>
                                            Врач-психиатр, психотерапевт, магистр психологии.
                                            Сертифицированный специалист Центра когнитивной терапии
                                            г. Оксфорд (Великобритания).
                                            <span className="home-bg-wrapper__text-mobile-cut">
                                                Консультант паралимпийской сборной РФ Эксперт на
                                                федеральном телевидении и в СМИ (1 канал, Россия24,
                                                НТВ, РенТВ, Пятница)
                                            </span>
                                        </span>
                                    </Col>
                                    <Col
                                        style={{
                                            fontWeight: 600,
                                            marginTop: '1.3rem',
                                            marginBottom: '1.3rem',
                                        }}
                                    >
                                        <NavLink to={VIDEOCOURCES_ROUTE}>
                                            Видеокурсы <ArrowRight style={{ fontSize: '1.3rem' }} />
                                        </NavLink>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default HomeBackgroundWrapper;
