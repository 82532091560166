import {makeObservable, observable} from "mobx"
import {getArticles} from "../api/public";
import {editArticle, postArticle} from "../api/admin";

export default class Articles {
    articles = []

    constructor() {
        makeObservable(this, {
            articles: observable,
        });
    }

    setArticles = async () => {
        this.articles = await getArticles();
    }

    addArticle = async (params) => {
        const data = await postArticle(params)
        this.articles.push(data)
        return data
    }

    editArticle = async (id, params) => {
        const data = await editArticle(id, params)
        this.articles = this.articles.map(item => {
            if (item.id === data.id) {
                return data
            } else {
                return item
            }
        })
        return data
    }
}