import React from 'react';
import {slide as Menu} from 'react-burger-menu'
import {List} from 'react-bootstrap-icons';
import './menuMobile.scss'
import {Link, NavLink} from 'react-router-dom';
import SignUpSignInModal from '../signupSignin/SignUpSignInModal';
import {
    ABOUT_ROUTE,
    ACCOUNT_ROUTE,
    AQ_ROUTE,
    COMPETENCES_ROUTE,
    CONSULTATION_ROUTE,
    CONTACTS_ROUTE,
    HOME_ROUTE,
    KITS_ROUTE,
    MY_COUPONS_ROUTE,
    MY_DATA_ROUTE, MY_KITS_21ST_CENTURY_ROUTE,
    MY_KITS_ROUTE,
    MY_PAYMENTS_ROUTE, MY_QUESTIONS_ROUTE,
    MY_VIDEOS_ROUTE, TARIFFS,
    VIDEOCOURCES_ROUTE
} from '../../utils/consts';

export default class MobileMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuOpen: false,
            signInVisible: false,
            signUpVisible: false,
            signUpSignInModalVisible: false
        }
    }

    handleStateChange(state) {
        this.setState({menuOpen: state.isOpen})
    }

    closeMenu() {
        this.setState({menuOpen: false})
    }

    toggleMenu() {
        this.setState(state => ({menuOpen: !state.menuOpen}))
    }

    setSignInVisible() {
        this.closeMenu()
        this.setState(state => ({
            signInVisible: !state.signInVisible
        }));
    }

    setSignUpVisible() {
        this.closeMenu()
        this.setState(state => ({
            signUpVisible: !state.signUpVisible
        }));
    }

    setSignUpSignInModalVisible() {
        this.closeMenu()
    }

    render() {
        return (
            <div className="menu-mobile-wrap">
                <Menu
                    id="menu-mobile"
                    customBurgerIcon={<List/>}
                    isOpen={this.state.menuOpen}
                    onStateChange={(state) => this.handleStateChange(state)}
                >
                    <NavLink to={HOME_ROUTE} activeClassName="selected" onClick={() => this.closeMenu()}>Главная</NavLink>
                    <NavLink to={TARIFFS} activeClassName="selected" onClick={() => this.closeMenu()}>Психотипы</NavLink>
                    {/*<NavLink to="/" activeClassName="selected" onClick={() => this.closeMenu()}>Онлайн школа</NavLink>*/}
                    <NavLink to={ABOUT_ROUTE} activeClassName="selected" onClick={() => this.closeMenu()}>Обо
                        мне</NavLink>
                    <NavLink to={AQ_ROUTE} activeClassName="selected"
                             onClick={() => this.closeMenu()}>Вопрос-ответ</NavLink>
                    <NavLink to={COMPETENCES_ROUTE} activeClassName="selected" onClick={() => this.closeMenu()}>Мои
                        компетенции</NavLink>

                    <NavLink to={CONSULTATION_ROUTE} activeClassName="selected" onClick={() => this.closeMenu()}>Запись
                        на консультацию</NavLink>
                    {/*<NavLink to="/" activeClassName="selected" onClick={() => this.closeMenu()}>СМИ</NavLink>*/}

                    <NavLink to={VIDEOCOURCES_ROUTE} activeClassName="selected"
                             onClick={() => this.closeMenu()}>Видеокурсы</NavLink>
                    <NavLink to={KITS_ROUTE} activeClassName="selected"
                             onClick={() => this.closeMenu()}>Комплекты</NavLink>
                    <NavLink to="/" activeClassName="selected" onClick={() => this.closeMenu()}>Отзывы</NavLink>
                    <NavLink to={CONTACTS_ROUTE} activeClassName="selected" style={{
                        paddingBottom: '2rem',
                    }} onClick={() => this.closeMenu()}>Контакты</NavLink>
                    {this.props.isAuthUser
                        ?
                        <>  <span>{this.props.userName}</span>
                            <NavLink to={ACCOUNT_ROUTE} style={{
                                paddingTop: '.5rem',
                                borderTop: '1px solid #757575',
                            }} activeClassName="selected" onClick={() => this.closeMenu()}>Личный кабинет</NavLink>
                            <NavLink
                              to={ACCOUNT_ROUTE + MY_QUESTIONS_ROUTE}
                              activeClassName="selected"
                              onClick={() => this.closeMenu()}
                            >
                                Курс психотипы
                            </NavLink>
                            <NavLink to={ACCOUNT_ROUTE + MY_VIDEOS_ROUTE} activeClassName="selected"
                                     onClick={() => this.closeMenu()}>Доступные видеокурсы</NavLink>
                            <NavLink to={ACCOUNT_ROUTE + MY_KITS_ROUTE} activeClassName="selected"
                                     onClick={() => this.closeMenu()}>Доступные комплекты</NavLink>

                            {!!this.props.kits?.length && <NavLink to={ACCOUNT_ROUTE + MY_KITS_21ST_CENTURY_ROUTE} activeClassName="selected"
                                     onClick={() => this.closeMenu()}>Психотерапевт 21 века</NavLink>}

                            <NavLink to={ACCOUNT_ROUTE + MY_DATA_ROUTE} activeClassName="selected"
                                     onClick={() => this.closeMenu()}>Мои данные</NavLink>
                            <NavLink to={ACCOUNT_ROUTE + MY_PAYMENTS_ROUTE} activeClassName="selected"
                                     onClick={() => this.closeMenu()}>Мои покупки</NavLink>
                            <NavLink to={ACCOUNT_ROUTE + MY_COUPONS_ROUTE} activeClassName="selected"
                                     onClick={() => this.closeMenu()}>Активировать купон</NavLink>
                            {this.props.isAdmin &&
                            <NavLink to="/admin/webinars" style={{color: 'red'}} onClick={() => this.closeMenu()}>Админ
                                панель</NavLink>}
                            <span onClick={() => this.props.exitHandler(false)}
                                  className="menu-mobile__logout">Выход</span>
                        </>
                        :
                        <div style={{
                            paddingTop: '.5rem',
                            borderTop: '1px solid #757575',
                        }}>
                            <Link
                                className="header__signin"
                                to={{pathname: '/modal/auth', state: {modal: true}}}
                                onClick={() => this.setSignUpSignInModalVisible()}
                            >
                                Авторизация
                            </Link>
                        </div>
                    }
                </Menu>
                <SignUpSignInModal show={this.state.signUpSignInModalVisible}/>
            </div>
        )
    }
}
