import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import App from './App.jsx';
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import AppSchoolLanding from "./AppSchoolLanding";
import AppCoursesLanding from "./AppCoursesLanding";

const getApp = subdomainApplications([
    {
        subdomains: [''],
        application: function () {
            return App();
        },
        main: true
    },
    {
        subdomains: ['promo'],
        application: function () {
            return AppCoursesLanding();
        }
    },
    {
        subdomains: ['school'],
        application: function () {
            return AppSchoolLanding();
        }
    }
]);

function subdomainApplications(map) {
    let main = map.find((item) => item.main);
    if (!main) {
        throw new Error();
    }

    return function getComponent() {
        const parts = window.location.hostname.split('.');

        let last_index = -2;
        const last = parts[parts.length - 1];
        const is_localhost = last === 'localhost';
        if (is_localhost) {
            last_index = -1;
        }

        const subdomain = parts.slice(0, last_index).join('.');

        if (!subdomain) {
            return main.application;
        }

        const app = map.find(({subdomains}) => subdomains.includes(subdomain));
        if (app) {
            return app.application;
        } else {
            return main.application;
        }
    }
}

const AppRender = getApp();

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop/>
        <AppRender/>
    </BrowserRouter>,
    document.getElementById('root')
);