import React from 'react';
import './exerciseItemMediaFile.scss';
import {Button} from "react-bootstrap";
import {Soundwave} from "react-bootstrap-icons";
import ReactAudioPlayer from "react-audio-player";

function ExerciseItemMediaFile({questionItem}) {
    return (
        <div className="exercise-item-media-file">
            <Button className="exercise-item-media-file__download-btn">
                <Soundwave/>
                <span>
                    {questionItem.properties.text}
                </span>
            </Button>
            <ReactAudioPlayer
                src={questionItem.properties.link}
                controls
            />
        </div>
    );
}

export default ExerciseItemMediaFile;