import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import Title from '../title/Title';
import './contacts.scss'
import image from './../../images/map-preview.png'

function Contacts() {
    const [iframePreviewToggler, setIframePreviewToggler] = useState(false)

    return (
        <Row className="row-component-wrapper">
            <Col className="contacts-wrap-inner">
                <div className="contacts__text-wrap">
                    <div>
                        <Title t={'Контакты'}/>
                    </div>
                    <div className="contacts__text">
                        <div className="contacts__subtitle" style={{
                            fontFamily: 'Lora',
                            fontSize: '1.375rem'
                        }}>
                            Психотерапевтический центр Айны Громовой
                        </div>
                        <div className="contacts__middle-text" style={{
                            fontWeight: 500,
                            marginTop: '1.625rem',
                            lineHeight: '2.2rem',
                        }}>
                            <div>
                                г. Ставрополь, ул. Добролюбова, 26
                            </div>
                            <div>
                                Пн-Пт: с 09:00 до 15:00
                            </div>
                            <div>
                                Выходные – суббота, воскресенье
                            </div>
                        </div>
                        <div style={{
                            fontWeight: 500,
                            marginTop: '2rem',
                        }}>
                            Помощник
                        </div>
                        <div className="contacts__support" style={{
                            fontFamily: 'Lora',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '1.375rem',
                        }}>
                                    <span>
                                        Евгения Владимировна
                                    </span>
                            <span style={{
                                marginLeft: '2rem',
                                fontFamily: 'Lora',
                                fontSize: '1.0625rem',
                                textDecoration: 'underline',
                            }}>
                                        info@doctor-ayna.ru
                                    </span>
                        </div>
                    </div>
                </div>
                <div className="contacts__map-wrap">
                    {iframePreviewToggler &&
                    <iframe title="title"
                            src="https://yandex.ru/map-widget/v1/?um=constructor%3A20be3617a1c4775b0cf60aa2699286a7d630654d406198f3f993a88ee4868b9b&amp;source=constructor"
                            width="100%" height="100%" frameBorder="0"/>
                    }
                    {!iframePreviewToggler &&
                    <div className="contacts__img-wrap" onClick={() => setIframePreviewToggler(!iframePreviewToggler)}>
                        <img src={image} alt=''/>
                        <div className="contacts__img-wrap__btn"
                             onClick={() => setIframePreviewToggler(!iframePreviewToggler)}>
                            <span>
                                Показать карту
                            </span>
                        </div>
                    </div>}
                </div>
            </Col>
        </Row>
    );
}

export default Contacts;