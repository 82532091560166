import axios from "axios";
import axiosRetry from "axios-retry";
import ErrorsStoreInstance from "../store/errors";

const req = () => {
    const defaultOptions = {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
        },
    };

    const instance = axios.create(defaultOptions);

    instance.interceptors.request.use(function (config) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        return config;
    });

    const retryDelay = (retryNumber = 0) => {
        const seconds = Math.pow(2, retryNumber) * 1000;
        const randomMs = 1000 * Math.random();
        return seconds + randomMs;
    };

    axiosRetry(axios, {
        retries: 2,
        retryDelay,
        retryCondition: axiosRetry.isRetryableError,
    });

    instance.interceptors.response.use(
        (response) => Promise.resolve(response.data),
        (error) => Promise.reject(error).catch(function (error) {
            if (error.response) {
                console.log(error.response.data.message);
                console.log(error.response.status);
                console.log(error.response.headers);
                ErrorsStoreInstance.setErrorMessage(error.response.data.message)
                ErrorsStoreInstance.setIsError(true)
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        })
    );

    return instance;
};

export default req()