import React from 'react';
import {Button} from "react-bootstrap";
import {Plus} from "react-bootstrap-icons";
import './addFieldBtn.scss'

function AddFieldBtn() {
    return (
        <span className="pt-add-field-btn-wrap">
            <Button className="pt-add-field-btn">
                <Plus/><span>Добавить поле</span>
            </Button>
        </span>
    );
}

export default AddFieldBtn;