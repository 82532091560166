import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { getPaymentData } from '../../api/generalApi';
import RegularBtn from '../regularBtn/RegularBtn';
import * as yup from 'yup';
import { useMediaQuery } from 'react-responsive';
import { useStore } from '../../store';
import { Link } from 'react-router-dom';
import RobokassaForm from '../robokassaForm/RobokassaForm';
import './giftButton.scss';

const GiftSchema = yup.object().shape({
  gift_email: yup.string().required('Обязательное поле').email('Введите корректный email'),
});

const GiftButton = observer(({ type, id, variant = 'full' }) => {
  const max767 = useMediaQuery({ query: '(max-width: 420px)' });

  let btnWidth = '18rem';

  if (max767) {
    btnWidth = '100%';
  }

  const { userStore } = useStore();
  const formGift = useRef(null);
  const [modalGiftForm, setModalGiftForm] = useState(false);
  const [payData, setPayData] = useState({});

  const isAuthUser = userStore.isAuth;

  return (
    <div className={'gift-button-wrapper' + (variant === 'simple' ? ' simple' : '')}>
      <Modal show={modalGiftForm} onHide={() => setModalGiftForm(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Подарить другу</Modal.Title>
        </Modal.Header>

        <Modal.Body className="show-grid">
          <Container>
            <Formik
              validationSchema={GiftSchema}
              onSubmit={async (values) => {
                const params = {
                  type,
                  id,
                  gift_email: values.gift_email,
                };
                getPaymentData(params)
                  .then((res) => {
                    if (!res.IsFree) {
                      setPayData(res);
                      formGift.current.submit();
                    }
                  })
                  .catch(() => {
                    console.log('catch');
                  });
              }}
              initialValues={{
                gift_email: '',
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                dirty,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="4">
                        <Form.Label>Электронная почта друга</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="email"
                            placeholder="Введите email друга"
                            aria-describedby="inputGroupPrepend"
                            name="gift_email"
                            value={values.gift_email}
                            onChange={handleChange}
                            isInvalid={!!errors.gift_email}
                          />
                          <Form.Control.Feedback tooltip type="invalid">
                            {errors.gift_email}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="float-end">
                        <RegularBtn
                          type={'submit'}
                          t={'Оплатить'}
                          onoff={!isValid || !dirty}
                          mt={'0'}
                          w={btnWidth}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Container>
        </Modal.Body>
      </Modal>

      <RobokassaForm refKey={formGift} payData={payData} />
      <Row>
        <Col>
          {variant === 'full' && (
            <div className="gift-button__container">
              <div className="gift-button__text-wrapper">
                <div className="gift-button__text">Подарить</div>
                <div className="gift-button__text">другу</div>
              </div>
              {isAuthUser ? (
                <button className="gift-button__buy-btn" onClick={() => setModalGiftForm(true)}>
                  Подарить
                </button>
              ) : (
                <Link
                  className="gift-button__buy-btn"
                  to={{ pathname: '/modal/auth', state: { modal: true } }}
                >
                  Подарить другу
                </Link>
              )}
            </div>
          )}

          {variant === 'simple' && (
            <div className="gift-button__container-simple">
              {isAuthUser ? (
                <button className="gift-button__buy-btn" onClick={() => setModalGiftForm(true)}>
                  Подарить другу
                </button>
              ) : (
                <Link
                  className="gift-button__buy-btn"
                  to={{ pathname: '/modal/auth', state: { modal: true } }}
                >
                  Подарить другу
                </Link>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
});

export default GiftButton;
