import React from 'react';
import {useStore} from "../../../../../../store";
import {Button} from "react-bootstrap";
import './exercisesAdminCheckbox.scss';

function ExercisesAdminCheckbox({lessonItem, questionItem, propertyItem}) {

    const {exercisesStore} = useStore()

    function setCheckboxTextHandler(lessonId, questionId, value, id) {
        exercisesStore.setCheckboxText(lessonId, questionId, value, id)
    }

    function deleteCheckboxHandler(lessonId, questionId, checkboxId) {
        exercisesStore.deleteCheckbox(lessonId, questionId, checkboxId)
    }

    return (
            <div>
                <input type="text"
                       value={propertyItem.text}
                       onChange={(e) =>
                           setCheckboxTextHandler(
                               lessonItem.id,
                               questionItem.id,
                               e.target.value,
                               propertyItem.id)}
                />
                <Button size={"sm"}
                        variant={"success"}
                        onClick={() =>
                            deleteCheckboxHandler(
                                lessonItem.id,
                                questionItem.id,
                                propertyItem.id
                            )}>
                    Удалить чекбокс
                </Button>
            </div>
    );
}

export default ExercisesAdminCheckbox;