import React, {useState} from 'react';
import './videoReviewsSliderItem.scss'
import {PlayCircle} from "react-bootstrap-icons";

function VideoReviewsSliderItem({id, name, description, url, previewImageLink}) {

    const [iframePreviewToggler, setIframePreviewToggler] = useState(false)

    return (
        <div className="videoreviev-slideritem">
            {iframePreviewToggler &&
            <iframe className="videoreviev-slideritem__iframe"
                    title="video"
                    allowFullScreen="allowfullscreen" allow="autoplay; fullscreen"
                    src={`${url}?autoplay=1&amp;autohide=1&amp;fs=1&amp;rel=0&amp;hd=1&amp;wmode=transparent&amp;enablejsapi=1&amp;html5=1&amp;start=0`}
                    scrolling="no">
            </iframe>}
            {!iframePreviewToggler && <div className="videoreviev-slideritem__img-wrap">
                <img src={previewImageLink} alt=''/>
                <PlayCircle className="videoreviev-slideritem__img-wrap__circle"
                            onClick={() => setIframePreviewToggler(!iframePreviewToggler)}/>
            </div>}
            <div className="videoreviev-slideritem__name">
                {name}
            </div>
        </div>
    );
}

export default VideoReviewsSliderItem;