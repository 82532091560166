import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import {createTariff} from "../schoolApi";

function TariffsNew() {

    const [tariff, setTariff] = useState(
        {
            title: "",
            properties: {
                key: "value"
            }
        }
    );

    function addTariff() {
        createTariff(tariff)
    }

    return (
        <div>
            <input type="text" value={tariff.title} onChange={(e) => setTariff(
                {...tariff, title: e.target.value})
            }/>
            <Button onClick={() => addTariff()}>
                Добавить тариф
            </Button>
        </div>
    );
}

export default TariffsNew;