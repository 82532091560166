import React, {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import Title from '../title/Title';
import KitsItem from '../kitsItem/KitsItem';
import LearnMoreLink from "../learnMoreLink/LearnMoreLink";
import {KITS_ROUTE} from "../../utils/consts";
import './specialOffers.scss'
import {useStore} from "../../store";
import {observer} from "mobx-react";

const SpecialOffers = observer(() => {
    const {kitsStore} = useStore()

    useEffect(() => {
        kitsStore.setKits()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const kits = kitsStore.kits

    return (
        <Row className="special-offers-wrap">
            <Col style={{
                maxWidth: 1500,
                padding: 0,
            }}>
                <Row>
                    <Col className="title-responsive">
                        <Title t={'Специальные предложения'}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="kits-wrap-inner">
                        {kits?.slice(0, 3).map(item => <KitsItem
                            key={item.id}
                            id={item.id}
                            title={item.title}
                            price={item.price}
                            old_price={item.old_price}
                            description={item.description}
                            image={item.image}
                        />)}
                    </Col>
                </Row>
                <div>
                    <LearnMoreLink text={'Все комплекты'} width={'13.6rem'} route={KITS_ROUTE}/>
                </div>
            </Col>
        </Row>
    );
})

export default SpecialOffers;