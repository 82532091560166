import React from 'react';
import './kitsItem.scss';
import {Col, Row} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import BtnWithArrow from '../btnWithArrow/BtnWithArrow';

const KitsItem = ({id, title, price, old_price, image}) => {

    return (
        <>
            <Row className="kits-item">
                <Col className="kits-item__wrap-inner">
                    <div className="kits-item__wrap-content">
                        <img src={image} alt="offers"/>
                        {old_price > 0 &&
                        <span className="kits-item__discount-block">
                                Скидка
                            </span>}
                        <div className="kits-item__text-wrap">
                            <div>Комплект видеокурсов</div>
                            <div className="kit-title kit-title-cutter">{title}</div>
                            <div className="kit-price-wrap">
                                Стоимость: <span className="kit-price">
                                {
                                    old_price > 0 && <s> {old_price} ₽ </s>
                                }
                            </span>
                                <span> {price} ₽</span>
                            </div>
                        </div>
                        <NavLink to={`/kits/` + id}><BtnWithArrow t={'Подробнее'} w={'100%'}/></NavLink>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default KitsItem;

