import React, {Suspense, useEffect, useState} from 'react';
import './App.scss';
import {Container, Row} from "react-bootstrap";
import {Route, useLocation} from "react-router-dom";
import SignUpSignInModal from "./components/signupSignin/SignUpSignInModal";
import PasswordRecoveryModal from "./components/signupSignin/PasswordRecoveryModal";
import AlertModal from "./components/alertModal/AlertModal";
import CustomSpinner from "./components/customSpinner/CustomSpinner";
import {observer} from "mobx-react";
import {useStore} from "./store";
import ErrorModal from "./components/errorsCatchers/networkErrorsCatcher/ErrorModal";

const CoursesLandingPage = React.lazy(() => import('./pagesCoursesLanding/CoursesLandingPage'));

const ObservableAppCoursesLanding = observer(() => {
    const {userStore, errorsStore} = useStore()

    const isError = errorsStore.isError
    const errorMessage = errorsStore.errorMessage

    const location = useLocation();

    useEffect(() => {
        userStore.setUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [previousLocation, setPreviousLocation] = useState(location);

    useEffect(() => {
        if (!(location.state && location.state.modal)) {
            setPreviousLocation(location);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    function errorCloseHandler() {
        errorsStore.setIsError(false)
        errorsStore.setErrorMessage('')
    }

    const isModal = (location.state && location.state.modal && previousLocation !== location);

    return (
        <>
            <Container fluid>
                <Row className="cources-landing-container-wrap">
                    <ErrorModal message={errorMessage} show={isError} errorCloseHandler={errorCloseHandler}/>
                    <Suspense fallback={<CustomSpinner/>}>
                        <CoursesLandingPage/>
                    </Suspense>
                </Row>
            </Container>
            {isModal ? (
                <>
                    <Route exact path="/modal/auth">
                        <SignUpSignInModal show={isModal} location={location}/>
                    </Route>
                    <Route exact path="/modal/reset">
                        <PasswordRecoveryModal show={isModal}/>
                    </Route>
                    <Route exact path="/modal/success">
                        <AlertModal show={isModal}/>
                    </Route>
                </>
            ) : null}
        </>
    );
});

export default ObservableAppCoursesLanding;
