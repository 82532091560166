import React, {useEffect} from 'react';
import './kits.scss';
import KitsItem from '../../components/kitsItem/KitsItem';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import Title from '../../components/title/Title';
import {Col, Row} from 'react-bootstrap';
import {observer} from "mobx-react";
import {useStore} from "../../store";

const Kits = observer(() => {
    const {kitsStore} = useStore()

    useEffect(() => {
        kitsStore.setKits()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const kits = kitsStore.kits

    return (
        <Col className="col-page-wrapper">
            <Row className="kits-wrap">
                <Breadcrumbs/>
                <Title t={'Все комплекты'} mt={'1rem'}/>
                <Col className="kits-wrap-inner">
                    {kits?.map(item => <KitsItem
                            key={item.id}
                            id={item.id}
                            title={item.title}
                            price={item.price}
                            old_price={item.old_price}
                            description={item.description}
                            image={item.image}
                        />
                    )}
                </Col>
            </Row>
        </Col>
    );
})

export default Kits;
