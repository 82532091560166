import {makeObservable, observable} from "mobx"

export class Errors {

    isError = false
    errorMessage = ''

    constructor() {
        makeObservable(this, {
            isError: observable,
            errorMessage: observable,
        });
    }

    setIsError(bool) {
        this.isError = bool;
    }

    setErrorMessage(message) {
        this.errorMessage = message;
    }
}

export default new Errors()
