import React, {Suspense, useEffect, useState} from 'react';
import {
    SCHOOL_ADMIN_ROUTE,
    SCHOOL_EXERCIES_NEW_ROUTE,
    SCHOOL_EXERCIES_ROUTE,
} from "../../../../utils/consts";
import {Route, Switch, useHistory} from "react-router-dom";
import {getExercises} from "../schoolApi";
import {Accordion} from "react-bootstrap";
import './exercises.scss';
import ExerciseAddUpdate from "./exerciseManagement/ExerciseAddUpdate";
import {observer} from "mobx-react";
import {useStore} from "../../../../store";

const Exercises = observer(() => {
    const {exercisesStore} = useStore()
    const history = useHistory()

    const [exercises, setExercises] = useState([]);
    const [currentExerciseId, setCurrentExerciseId] = useState([]);

    useEffect(() => {
        getExercises().then((res) => setExercises(res))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function addNewExerciseHandler() {
        setCurrentExerciseId(null)
        history.push(SCHOOL_ADMIN_ROUTE + SCHOOL_EXERCIES_ROUTE + SCHOOL_EXERCIES_NEW_ROUTE)
    }

    function setCurrentExerciseIdHandler(id) {
        setCurrentExerciseId(id)
    }

    // function ContextAwareToggle({children, eventKey, callback}) {
    //     const {activeEventKey} = useContext(AccordionContext);
    //
    //     const decoratedOnClick = useAccordionButton(
    //         eventKey,
    //         () => callback && callback(eventKey),
    //     );
    //
    //     const isCurrentEventKey = activeEventKey === eventKey;
    //
    //     return (
    //         <button
    //             type="button"
    //             style={{backgroundColor: isCurrentEventKey ? 'pink' : 'lavender'}}
    //             onClick={decoratedOnClick}
    //         >
    //             {children}
    //         </button>
    //     );
    // }

    return (
        <div className="mt-4">
            <Accordion defaultActiveKey="0">
                {exercises.map((item) => {
                        return (
                            <Accordion.Item key={item.id} eventKey={item.id}>
                                <Suspense fallback={<div>Загрузка...</div>}>
                                    <Accordion.Header onClick={() => setCurrentExerciseIdHandler(item.id)}>
                                        <div className="exercise-accordion__title"
                                             dangerouslySetInnerHTML={{__html: `${item.title}`}}/>
                                    </Accordion.Header>
                                    <ExerciseAddUpdate currentExerciseId={currentExerciseId}/>
                                </Suspense>
                            </Accordion.Item>
                        )
                    }
                )}
                {/*<span dangerouslySetInnerHTML={{__html: `${item.title}`}}/>*/}
                {/*<XCircle color="black" onClick={() => deleteExerciseHandler(item.id, number)}/>*/}
                {/*<Pencil color="black" onClick={() => editExerciseHandler(item.id)}/>*/}
            </Accordion>
            <Switch>
                <Route path={SCHOOL_ADMIN_ROUTE + SCHOOL_EXERCIES_ROUTE + SCHOOL_EXERCIES_NEW_ROUTE}>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Suspense fallback={<div>Загрузка...</div>}>
                                <Accordion.Header onClick={() => setCurrentExerciseIdHandler()}>
                                    <div className="exercise-accordion__title"
                                         dangerouslySetInnerHTML={{__html: `${exercisesStore.exercise.title}`}}/>
                                </Accordion.Header>
                                <ExerciseAddUpdate currentExerciseId={currentExerciseId}/>
                            </Suspense>
                        </Accordion.Item>
                    </Accordion>
                </Route>
            </Switch>
            <button onClick={() => addNewExerciseHandler()}>
                Добавить упражнение
            </button>
        </div>
    );
})

export default Exercises;