import {makeAutoObservable} from "mobx"
import {getKits} from "../api/public";
import {editKit, postKit} from "../api/admin";
import {getKitsByMarkId} from "../api/private";

export default class Kits {
    kits = []
    kitsByMarkId = []

    constructor() {
        makeAutoObservable(this)
    }

    setKits = async () => {
        this.kits = await getKits();
    }

    setKitsByMarkId = async (id) => {
        this.kitsByMarkId = await getKitsByMarkId(id);
    }

    addKit = async (params) => {
        const data = await postKit(params)
        this.kits.push(data)
        return data
    }

    editKit = async (id, params) => {
        const data = await editKit(id, params)

        this.kits = this.kits.map(item => {
            if (item.id === data.id) {
                return data
            } else {
                return item
            }
        })

        this.kitsByMarkId = this.kitsByMarkId.map(item => {
            if (item.id === data.id) {
                return data
            } else {
                return item
            }
        })

        return data
    }
}