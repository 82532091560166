import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {$host} from "../../../utils/consts";
import Title from "../../../components/title/Title";
import './aqPageItemWatch.scss'
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";

function AqPageItemWatch() {

    const {id} = useParams()

    const [answer, setAnswer] = useState([]);
    const getAnswersReq = () => {
        $host.get(`api/v1/user/answers/${id}`).then(res => {
            setAnswer(res.data)
        })
    }

    useEffect(() => {
        getAnswersReq()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Col className="col-page-wrapper">
            <Breadcrumbs to={'2'} />
            <Title t={answer.title}/>
            <Row className="aq-watch-wrap">
                <Col>
                    <div className="aq-watch__frame">
                        <iframe style={{height: '100%', width: '100%'}}
                                src="https://www.youtube.com/embed/sbyOu4hnZ2Y"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                        </iframe>
                    </div>
                </Col>
                <Col>
                    <div className="aq-watch__tags">
                        {answer.tags && answer.tags.map(item => <span key={item.id}> #{item.title}&nbsp;</span>)}
                    </div>
                </Col>
                {/*<Col className="aq-watch__text">*/}
                {/*    <div>*/}
                {/*        Вопрос: мучаюсь долгое время с выбором деятельности, восхищаюсь людьми, которые сами что-то*/}
                {/*        производят и*/}
                {/*        зарабатывает на этом. Но я начинаю в себе сомневаться, полезна ли я буду, а не будет ли это*/}
                {/*        глупо, я*/}
                {/*        как*/}
                {/*        будто не слышу себя, делаю как-то работу, но четкого плана и ориентира нет, нахожусь в*/}
                {/*        постоянном*/}
                {/*        напряжении из-за этого, не получаю радости от какой-либо деятельности, чувствую себя никчёмным,*/}
                {/*        не*/}
                {/*        интересным человеком.*/}
                {/*        <br/>*/}
                {/*        <br/>*/}
                {/*        Ответ: Всем людям, которые хотят себя в этой жизни реализовать, надо убрать внутренние барьеры в*/}
                {/*        голове.*/}
                {/*        все это негативные установки. Подробно о них в видеокурсах о психологическом здоровье, вторая и*/}
                {/*        третья*/}
                {/*        часть “коррекция негативных установок”. Там все разобрано, все страхи, как взять ответственность*/}
                {/*        на*/}
                {/*        себя*/}
                {/*        и познакомиться с собой по-настоящему, понять, чего вы реально хотите. Берите материал и*/}
                {/*        работайте.*/}
                {/*    </div>*/}
                {/*</Col>*/}
            </Row>
        </Col>
    );
}

export default AqPageItemWatch;