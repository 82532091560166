import React from 'react';
import {Clock} from 'react-bootstrap-icons';
import './videocourseSliderItem.scss'
import {NavLink} from "react-router-dom";

function VideocourseSliderItem({title, price, tags, length, image, custom_price, id}) {

    return (
        <div className="videocources-slider-item-wrap">
            <div className="videocources-slider-item__img-wrap">
                <img src={image} alt=""/>
                <div className="videocources-slider-item__button-wrap">
                    <NavLink to={`/videocources/` + id} className="videocources-slider-item__button">
                        Перейти к курсу
                    </NavLink>
                </div>
            </div>
            <div className="videocources-slider-item">
                <span className="videocources-slider-item__tags">
                    {tags && tags.map(item => <span key={item.id}>
                        #{item.title} </span>)}
                </span>
                <span className="videocources-slider-item__title">
                    {title}
                </span>
                <span className="videocources-slider-item__info">
                    <Clock/>
                    <span>
                        {length}
                    </span>
                </span>
                <span>
                    {price === 0 ?
                        <span className="videocources-item__price-free">
                            Бесплатный видеокурс
                        </span>
                        :
                        <span className="videocources-item__price">
                        Стоимость: {custom_price ? <>
                                <span style={{color: 'gray'}}><s>{price}</s></span>
                                <span> {custom_price.price}</span>
                            </>
                            :
                            <span>
                                {price}
                            </span>}
                    </span>}
                </span>
            </div>
            {custom_price && <span className="videocources-item__discount-block">
                Скидка
            </span>}
        </div>
    );
}

export default VideocourseSliderItem;