import req from "../../../api/req";
// import {$host} from "../../../utils/consts";

export const saveExercise = async (params) => await req.post('api/v1/school/exercises', params)
export const deleteExercise = async (id) => await req.post(`api/v1/school/exercises/${id}/delete`)
export const updateExercise = async (params, id) => await req.post(`api/v1/school/exercises/${id}/update`, params)
export const updateLesson = async (params, id) => await req.post(`api/v1/school/lessons/${id}/update`, params)
export const updateQuestion = async (params, id) => await req.post(`api/v1/school/questions/${id}/update`, params)
export const createExercise = async () => await req.post('api/v1/school/exercises')
export const createTariff = async () => await req.post('api/v1/school/tariffs')
export const updateTariff = async (id) => await req.post(`api/v1/school/tariffs/${id}/update`)
export const saveAnswers = async (params) => await req.post('api/v1/school/exercise/save-answers', params)

export const getExerciseById = async (id) => await req.get(`api/v1/school/exercises/${id}`)
export const getRespondedUsers = async () => await req.get(`api/v1/school/answers/users`)
export const getExercises = async () => await req.get('api/v1/school/exercises', {
    params: {
        with_lessons: true,
        with_questions: true
    }
})
export const getTarrifs = async () => await req.get('api/v1/school/tariffs')

