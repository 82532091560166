import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import SimpleImage from '../../../../images/simple.jpg';
import './Questions.style.scss';

export const TariffsQuestions = () => {
  const [lightBoxVisible, setLightBoxVisible] = useState(false);
  const [largeImage, setLargeImage] = useState('');

  const showImage = (event) => {
    event.preventDefault();
    setLargeImage(SimpleImage);
    setLightBoxVisible(true);
  }

  return (
    <Row className="row-component-wrapper questions-sl-wrap-outer">
      <Col className="questions-sl-wrap-inner">
        <div className="sl-title">Вас ждет:</div>
        <div className="questions-sl-blocks-wrap">
          <div className="questions-sl-first-block">
            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>1</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                  <span>17 видеоуроков с клиническими примерами;</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>2</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                  <span>5 прямых эфиров с разбором ваших кейсов, ответами на вопросы;</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>3</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                  <span>
                    презентация, в которой разобраны основные положения ведущих теорий личности;
                  </span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>4</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                  <span>2 тестирования;</span>
                </span>
              </div>
            </div>
          </div>

          <div className="questions-sl-second-block">
            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>5</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                  <span>
                    видеоролики-разборы предлагаемых кейсов с обратной связью куратора и{' '}
                    <b>Айны Громовой</b>;
                  </span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>6</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                  <span>дополнительные полезные материалы;</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>7</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                  <span>
                    сертификат о прохождении обучающей программы для тарифов «Продвинутый» «Ученики
                    Айны Громовой»;
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Col>

      {lightBoxVisible && (
        <Lightbox
          mainSrc={largeImage}
          onCloseRequest={() => setLightBoxVisible(false)}
          enableZoom={false}
        />
      )}
    </Row>
  );
}

