import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './expretise.scss'
import expertise1 from '../../images/expertise-1.jpg'
import expertise2 from '../../images/expertise-2.jpg'
import expertise3 from '../../images/expertise-3.jpg'
import Title from '../title/Title';

function Expertise() {
    return (
        <Row style={{
            flexDirection: 'column',
            backgroundColor: '#F2F2F2',
            alignItems: 'center',
        }}>
            <Col style={{
                maxWidth: 1500,
                marginBottom: '3.75rem',
            }}>
                <Row style={{ flexDirection: 'column' }}>
                    <Col>
                        <Row>
                            <Col className="title-responsive">
                                <Title t={'Я являюсь экспертом федеральных СМИ'} />
                            </Col>
                        </Row>
                        <span style={{
                            fontFamily: 'Montserrat',
                            fontSize: '1rem',
                        }}>
                            <p>Даю профессиональные комментарии для программ «Человек и закон», «Прямой эфир с Андреем Малаховым», «Пусть говорят», «Мужское и женское», «ДНК
                                на НТВ», «Новости» на России-1, РЕН ТВ, телеканал «Звезда» и т.д.</p>
                        </span>
                    </Col>
                    <Col style={{
                        display: 'flex',
                        paddingTop: 30,
                    }}>
                        <Row style={{
                            width: '100%',
                            background: `url(${expertise1})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            alignItems: 'flex-end',
                            height: 473,
                        }} />
                        <Row style={{
                            width: '100%',
                            background: `url(${expertise2})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            alignItems: 'flex-end',
                            marginLeft: 20,
                            marginRight: 20,
                            height: 473,
                        }} />
                        <Row style={{
                            width: '100%',
                            background: `url(${expertise3})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            alignItems: 'flex-end',
                            height: 473,
                        }} />
                    </Col>
                </Row>
            </Col>
        </Row >
    );
}

export default Expertise;