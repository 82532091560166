import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import Title from '../../components/title/Title';
import img1 from '../../images/about-me.jpg';
import img2 from '../../images/about-me-2.jpg';
import img3 from '../../images/about-me-3.jpg';
import './aboutPage.scss';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import CertificatesGallery from "../../components/certificatesGallery/CertificatesGallery";
import { useHistory } from "react-router-dom";

const About = () => {
    const history = useHistory()
    const myRef = useRef(null)

    useEffect(() => {
        const executeScroll = () => myRef.current.scrollIntoView()
        if (history.location.hash === '#cds') {
            executeScroll()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Col style={{ maxWidth: 1500 }} className="about">
            <Breadcrumbs to={2} />
            <Row>
                <Col className="title-responsive">
                    <Title t={'Обо мне'} mt={'1.4em'} />
                </Col>
            </Row>
            <Row>
                <Col xs={4} className="about__img">
                    <img src={img1} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </Col>
                <Col xs={12} lg={8} className="about__text-wrap-right">
                    <span style={{
                        lineHeight: '2.03rem',
                    }}>
                        <img src={img2} alt="" className="about__img-phablet" style={{
                            width: '40%',
                            paddingRight: '1rem',
                            paddingBottom: '.5rem',
                            float: 'left'
                        }} />
                        Меня хорошо знают в профессиональном сообществе как человека, который очень
                        много работает и колоссальные деньги вкладывает в свое развитие. На сегодняшний
                        день не знаю специалиста с таким же высоким европейским и российским образовательным
                        стандартом. Считаю, что в современном мире ценятся только уникальные навыки.
                        Уникальные навыки, как крупицы золота, систематически, как привередливый
                        золотоискатель, ищу и нахожу по всему миру.
                        <p className="mb-3 mt-3">
                            Если лечить-то так, чтобы результат был ярким и психологическая боль уходила
                            навсегда. Помощь должна быть такой, чтобы пациент вспоминал тебя добрым словом
                            и доверял лечение своих детей.
                        </p>
                        <p className="mb-3 mt-3">Этому меня научили родители. Я - потомственный врач, уже в третьем поколении.
                            Мой дед, которого ребенком мать отдала в детский дом, (в нищие годы не могла прокормить
                            4-х детей), прошел путь до профессора-хирурга, стал одним из основоположников
                            сердечно-сосудистой хирургии в СССР. Моя бабушка была акушером-гинекологом,
                            кандидатом наук. Мои родители - заслуженные врачи РФ. Мои брат и сестра - врачи.
                            С детства я много времени проводила в больнице, а дома слушала медицинский консилиум.
                            Лечить - для меня также естественно, как дышать.
                        </p>
                        <p className="mt-3">И о самом главном. Я замужем. Мы с супругом воспитываем двух дочерей –
                            Александру и Агнию. Мужа и дочерей считаю
                            главными достижениями в жизни. Свою семью я построила на основании тех знаний о семье,
                            которым обучаю других. И считаю косвенно своими не один десяток детей, родившихся в тех
                            семьях, которые с моей помощью не развелись, а укрепили отношения или построили семью.
                            Правда, не знаю почему, с моей легкой руки рождаются (со значительным перевесом) только
                            девочки.
                        </p>
                        <img src={img2} alt="" className="about__img-mobile"
                            style={{ width: '100%', paddingTop: '1rem' }} />
                    </span>
                </Col>
            </Row>
            <Row>
                <Col className="title-responsive">
                    <Title t={'Опыт работы'} mt={'5rem'} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={8} className="about__text-wrap-left">
                    <span style={{
                        lineHeight: '2.03rem',
                    }}>
                        <p>
                            Первый год моей работы прошел в закрытых отделениях психиатрической больницы.
                        </p>
                        <p className="mb-1 mt-1">
                            Затем я перевелась в Клинику пограничных состояний СГМА. Много лет лечила пациентов различного профиля: со стрессовыми, невротическими расстройствами, паническими атаками, психосоматическими заболеваниями, депрессией, различными зависимостями и суицидальными тенденциями. Эта работа в стационаре, дневные и ночные дежурства, дали мне большой клинический опыт.
                        </p>
                        <p className="mb-1 mt-1">
                            Параллельно я получила высшее психологическое образование, постоянно дополнительно обучалась у ведущих российских и западных специалистов в области психотерапии.
                        </p>
                        <p className="mb-1 mt-1">
                            Стала обучающим тренером в области системной семейной терапии с правом подготовки специалистов.
                        </p>

                        <p className="mb-1 mt-1">
                            Каждый день я не только назначала препараты, но и работала как психолог. Вела сеансы: медицинский гипноз, семейное консультирование, обучала навыкам стрессоустойчивости и т.д.
                        </p>

                        <p className="mb-1 mt-1">
                            В течение 3-х лет вела тренинговую работу по развитию стрессоустойчивости в горах западного Кавказа (в условиях, где отсутствовала сотовая связь и было совсем немного цивилизации).
                        </p>

                        <p className="mb-1 mt-1">
                            26.05.2010 г. в Ставрополе произошел теракт. Была единственным психиатром-психотерапевтом в условиях ЧС, оказывала помощь пострадавшими с самого начала – с момента их поступления в отделения нейрохирургии, травматологии.
                        </p>

                        <p className="mb-1 mt-1">
                            Занимаясь такой разноплановой деятельностью, я поняла разницу между психиатрическим и психотерапевтическим подходами к лечению.
                        </p>
                        <p className="mb-1 mt-1">
                            Вылечить пациента без применения фармакотерапии (медикаментозного лечение) возможно, часто это абсолютно достижимая цель. А вот вылечить пациента без психологической коррекции (психотерапии) – невозможно. Только психотерапия убирает триггеры (пусковые факторы) подавляющего большинства расстройств, что обеспечивает дальнейшую жизнь без рецидивов.
                        </p>
                        <p className="mt-1">В случае, если пациент получает препараты, но не обучается нужным навыкам, при отмене препаратов, чаще всего можно ожидать рецидив.
                        </p>
                    </span>
                </Col>
                <Col xs={4} className="about__img">
                    <img src={img2} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </Col>
            </Row>
            <Row>
                <Col className="title-responsive">
                    <Title t={'Мое образование'} mt={'5rem'} />
                </Col>
            </Row>
            <Row>
                <Col xs={4} className="about__img">
                    <img src={img3} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </Col>
                <Col xs={12} lg={8} className="about__text-wrap-right">
                    <span style={{
                        lineHeight: '2.03rem',
                    }}>

                        <p className="mb-1">
                            Врач-психиатр, психотерапевт. Магистр психологии. Психолог-эксперт.
                        </p>
                        <p className="mb-1 mt-1">
                            Сексолог. Дополнительно обучалась у Л.Щеглова, А.Федоровой,
                            Ш. Крайтлер (Израиль) и др.
                        </p>
                        <p className="mb-1 mt-1">
                            Имею специализацию в области психотерапии детей и подростков.
                        </p>
                        <p className="mb-1 mt-1">
                            Сертифицирована Центром когнитивной терапии (Оксфорд, Великобритания).
                            Там я несколько лет обучалась немедикаментозной коррекции депрессий, панических атак, тревожных расстройств, психосоматических заболеваний.
                        </p>
                        <p className="mb-1 mt-1">
                            Успешно защитила диплом психотерапевта и получила разрешение проводить глубинную психологическую коррекцию методом
                            кататимно-имагинативной психотерапии (Геттинген, 2008).
                        </p>
                        <p className="mb-1 mt-1">
                            Сертифицированный семейный консультант. Несколько лет супервизировала семейных психологов.
                        </p>
                        <p className="mb-1 mt-1">
                            Прошла полное обучение в когнитивно-поведенческой психотерапии, телесно-ориентированной психотерапии, транзактном анализе, сказкатерапии.
                        </p>
                        <p className="mb-1 mt-1">
                            Дополнительно обучалась работе  экзистенциальными кризисами.
                        </p>
                        <p className="mb-1 mt-1">
                            Получила отдельную специализацию в области когнитивно-поведенческой терапии пациентов с расстройством личности и психосоматическими
                            расстройствами.
                        </p>
                        <p className="mb-1 mt-1">
                            Огромный объем знаний получила в бизнес-обучении. Сертифицирована как тренер тренеров компанией Human Factors (2011).
                        </p>
                        <div className="about__teachers mb-1"><b><u>Обучалась также:</u></b>
                            <div>Маркетингу (у Гаррета Джонстона, Игоря Манна, Ильи Балахнина);</div>
                            <div>Управлению доходами компании (у Линна Айронса);</div>
                            <div>Стратегиям увеличения прибыли (у Александра Левитаса);</div>
                            <div>Делегированию (у Александра Фридмана);</div>
                            <div>Полной системе продаж (у Евгения Котова, Андрея Левченко);</div>
                            <div>Аргументации (у Никиты Непряхина);</div>
                            <div>Ораторскому искусству (у Радислава Гандапаса);</div>
                            <div>Искусству ведения сложных переговоров (у Павла Сивожелезова, Владимира Якубы, Игоря Рызова);</div>
                            <div>Построению команды (у Максима Батырева);</div>
                            <div>Искреннему Сервису (у Максима Недякина);</div>
                            <div>Принятию решений (у Максима Поташова)</div>
                            и многому другому у известных бизнес-консультантов.
                        </div>
                        <p className="mt-3">Есть такое выражение: «Специалист подобен флюсу. Его полнота односторонняя».
                            В моём случае именно разноплановые знания и опыт помогают оценить ситуацию с разных сторон,
                            и помочь пациенту достичь тех целей, которые он перед собой ставит.
                        </p>
                    </span>
                </Col>
            </Row>
            <CertificatesGallery />
            <div ref={myRef} />
        </Col>
    );
}

export default About;
