import React from 'react';
import { observer } from "mobx-react";
import { Form, Table } from "react-bootstrap";
import AddFieldBtn from "../AddFieldBtn";
import { useStore } from "../../../../store";
import './exerciseItemTable.scss';

const ExerciseItemTable = observer(({ tableItem, lessonItem, questionItem }) => {
  const { userExercisesStore } = useStore()

  function addFieldHandler(lessonId, questionId) {
    userExercisesStore.addAdditionalField(lessonId, questionId)
  }

  function setColTextareaTextHandler(lessonId, questionId, tableItemId, rowItemId, colItemId, value) {
    userExercisesStore.setColTextareaText(lessonId, questionId, tableItemId, rowItemId, colItemId, value)
  }

  return (
    <div className="exercise-item-table">
      <Table bordered>
        <tbody>
        {tableItem.rows?.map((rowItem, index) => {
          return (
            <tr key={index}>
              {rowItem.cols.map(colItem => {
                return (
                  rowItem.thead ?
                    <th className="exercise-item-table__th">
                      {colItem.text}
                    </th>
                    :
                    colItem.textarea ?
                      <td className="exercise-item-table__table">
                        <Form>
                          <Form.Group>
                            <Form.Control as="textarea"
                                          value={colItem.text}
                                          onChange={(e) =>
                                            setColTextareaTextHandler(
                                              lessonItem.id,
                                              questionItem.id,
                                              tableItem.id,
                                              rowItem.id,
                                              colItem.id,
                                              e.target.value
                                            )
                                          }/>
                          </Form.Group>
                        </Form>
                      </td>
                      :
                      <td>{colItem.text}</td>

                )
              })}
            </tr>
          )
        })}
        </tbody>
      </Table>
      {questionItem.properties[0]?.abilityToAddField
        && questionItem.properties.indexOf(tableItem) === questionItem.properties.length - 1
        && <>
                                                            <span onClick={() => addFieldHandler(
                                                              lessonItem.id,
                                                              questionItem.id
                                                            )}>
                                                                <AddFieldBtn/>
                                                         </span>
        </>
      }
    </div>
  )
})

export default ExerciseItemTable

