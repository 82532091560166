import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Accordion, Col, Row } from 'react-bootstrap';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import { TariffsQuestions, TariffsContentBlock } from './components';
import { useMediaQuery } from 'react-responsive';
import Title from '../../components/title/Title';
import { classNames } from '../../utils/classNames';
import { getAllTariffs } from '../../api/public';
import { useStore } from '../../store';
import { useHistory } from 'react-router-dom';
import RobokassaForm from '../../components/robokassaForm/RobokassaForm';
import { getPaymentData, getPaymentDataTinkoff } from '../../api/generalApi';
import OfertaPsih from '../../files/oferta_psih.pdf';
import './Tariffs.style.scss';
// import { SchoolFeedbacksVideo } from '../../components/schoolFeedbacksVideo/SchoolFeedbacksVideo';
import PaymentEpointButton from '../../components/paymentEpointButton/PaymentEpointButton';

export const TariffsPage = observer(() => {
  const { userStore } = useStore();
  const [loading, setLoading] = useState(false);
  const [tariffs, setTariffs] = useState([]);
  const [payData, setPayData] = useState({});
  const form = useRef(null);
  const history = useHistory();

  const isAuthUser = userStore.isAuth;

  useEffect(() => {
    getAllTariffs().then((data) => {
      if (data) {
        setTariffs(data.filter((t) => t.active === true));
      }
    });
  }, []);

  const isMax767 = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const buyHandler = (id) => {
    if (!isAuthUser) {
      history.push('/modal/auth', { modal: true });
    }

    if (isAuthUser && !loading) {
      setLoading(true);

      getPaymentData({ type: 4, id: id }).then((res) => {
        setLoading(false);
        if (!res.IsFree) {
          setPayData(res);
          form.current.submit();
        }
      });
    }
  };

  const installmentsHandler = async (id, months) => {
    if (isAuthUser) {
      try {
        await getPaymentDataTinkoff({ tariff_id: id, installment_length: months }).then((res) => {
          if (res && res?.link) {
            window.location.href = res.link;
          }
        });
      } catch {
        alert('Произошла ошибка, попробуйте позже...');
      }
    } else {
      history.push('/modal/auth', { modal: true });
    }
  };

  return (
    <Col className="col-page-wrapper tariffs_page">
      <Breadcrumbs />

      <Title t='КУРС "Психотипы. Учимся читать людей как книги."' />

      <div style={{ lineHeight: '2.03rem', fontSize: '1.3rem ' }}>
        <p>
          <b>Кому будет полезен этот курс?</b>
        </p>
        <p>
          СПЕЦИАЛИСТАМ, ЖЕЛАЮЩИМ ПОВЫСИТЬ СВОЙ ПРОФЕССИОНАЛЬНЫЙ УРОВЕНЬ — психиатрам,
          психотерапевтам и психологам:
        </p>
        <ul>
          <li>Уйти от общих схем и диагностических ошибок</li>
          <li>Составлять индивидуальные программы психотерапии</li>
          <li>Распознавать специфику каждого клиента, выбирая правильную тактику его ведения</li>
          <li>Повысить профессионализм и результативность терапии</li>
          <li> Получить узнаваемость и выйти на новый уровень дохода.</li>
        </ul>
        <p>
          ОБЫЧНЫМ ЛЮДЯМ, КОТОРЫЕ ХОТЯТ ИЗМЕНИТЬ СВОЮ ЖИЗНЬ К ЛУЧШЕМУ, ИСПОЛЬЗУЯ ПСИХОЛОГИЧЕСКИЕ
          ЗНАНИЯ:
        </p>
        <ul>
          <li>Ответить на вопрос «Кто я»?</li>
          <li>
            Оценить индивидуальные риски для здоровья, уязвимости в разных сферах, специфику
            потенциальных срывов
          </li>
          <li>Подобрать лучшую программу своей реализации с учётом сильных сторон</li>
          <li>Выбрать подходящего для создания здорового союза человека</li>
          <li>
            Спрогнозировать развитие ситуации в своей семье/с начальником/сотрудниками и найти
            лучшее решение текущих задач
          </li>
          <li>Воспитать разных по характеру детей, зная подход к каждому</li>
          <li>Понять, какая программа обучения/развития лучше подходит именно вашему ребенку</li>
          <li>Справиться с подростковым кризисом, верно оценив ситуацию</li>
        </ul>
        <p>
          На курсе мы разберём не только каждый психотип и сочетание типов личности, но и специфику
          мышления и поведения представителей всех психотипов в трёх разных диапазонах:
        </p>
        <ul style={{ listStyleType: 'none' }}>
          <li>✔️ Психологической нормы</li>
          <li>✔️ Аномальной личностной изменчивости</li>
          <li>✔️ Психопатии.</li>
        </ul>
        <p>
          В каждом уроке вас ждёт видеозапись с информационным блоком от Айны Громовой, слайды,
          яркие клинические примеры.
        </p>
        <p>
          Раз в неделю — прямые эфиры с разбором ваших вопросов по пройденному материалу. Запись
          эфира будет сохраняться в вашем личном кабинете. ⠀ Программа обучения построена таким
          образом, чтобы вам было комфортно изучать материал в режиме обычной жизни.
        </p>
        <br />
        <p><b>Длительность обучения:</b></p>
        <p>5 недель, с 18 сентября по 22 октября 2023 г.</p>
        <p>
          Доступ к материалам после завершения обучения — 3 месяца с возможностью платного
          продления.
        </p>
        <p>
          Жители РФ могут воспользоваться рассрочкой без переплат на 3 или 6 месяцев. Оплата участия
          возможна из любой страны мира.
        </p>
        <p>
          После окончания обучения участники тарифов «Продвинутый» и «Ученики Айны Громовой» получат
          Сертификаты участника на русском или английском языке (по вашему выбору).
        </p>
        <p>
          *Полное название программы, указанное в Сертификате, — «Психотипологические особенности
          личности в диапазоне нормы и патологии».
        </p>
        <p>
          <b>По любым вопросам обращайтесь в техническую поддержу:</b> Электронная почта
          <a href="mailto:support@doctor-ayna.ru">support@doctor-ayna.ru</a>
        </p>
        <p>Или пишите Евгении Владимировне +7 (903) 442-88-02 (Telegram, WhatsApp)</p>
      </div>

      <Row className="row-component-wrapper prices-sl-wrap-outer">
        <div className="prices-sl__title sl-title">Стоимость</div>

        <RobokassaForm refKey={form} payData={payData} />

        <Col className={classNames('prices-sl-wrap-inner', { 'prices-sl-mobile': isMax767 })}>
          {isMax767 ? (
            <Accordion defaultActiveKey="1">
              {tariffs.map((tariff) => {
                return (
                  <Accordion.Item key={tariff.id} eventKey={tariff.id}>
                    <Accordion.Header>
                      <div className="prices-sl__item__title">{tariff.title}</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="prices-sl__item__list">
                        {tariff.properties?.desc
                          ? tariff.properties.desc.map((item, index) => {
                              return <span key={index}>{item}</span>;
                            })
                          : null}

                        {tariff.properties?.documents && tariff.properties?.documents.length ? (
                          <div className="documents">
                            <div className="documents__title">Необходимые документы:</div>
                            <div className="documents__list">
                              {tariff.properties.documents.map((item, index) => {
                                return <span key={index}>{item}</span>;
                              })}
                            </div>
                            <div className="documents__bottom">
                              отправлять на электронную почту{' '}
                              <a href="mailto:info@doctor-ayna.ru">info@doctor-ayna.ru</a>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="prices-sl__item__price-wrap">
                        <div className="prices-sl__item__price-top">
                          <span>Цена</span>
                        </div>
                        <div className="prices-sl__item__price-bottom">
                          <span>{tariff.price} ₽</span>
                        </div>
                      </div>
                      {tariff.can_subscribe && (
                        <div className="prices-sl__item__buttons-wrap">
                          {tariff.can_subscribe && (
                            <React.Fragment>
                              <div
                                onClick={() => buyHandler(tariff.id)}
                                className="prices-sl__item__button-1"
                              >
                                <span>Купить</span>
                              </div>

                              <div
                                onClick={() => installmentsHandler(tariff.id, 3)}
                                className="prices-sl__item__button-2"
                              >
                                <span>В рассрочку на 3 месяца</span>
                              </div>

                              <div
                                onClick={() => installmentsHandler(tariff.id, 6)}
                                className="prices-sl__item__button-2"
                              >
                                <span>В рассрочку на 6 месяцев</span>
                              </div>

                              <PaymentEpointButton
                                id={tariff.id}
                                type={4}
                                price={tariff.price_azn}
                                webinar_state='STATE_CAN_BUY'
                              />
                            </React.Fragment>
                          )}

                          <div className="terms">
                            <p>
                              Нажимая кнопку "Купить" Вы соглашаетесь с{' '}
                              <b>
                                <a href={OfertaPsih} target="_blank" rel="noreferrer">
                                  договором оферты
                                </a>
                              </b>
                            </p>
                            {tariff.price_azn !== undefined && tariff.price_azn > 0 && (
                              <p>
                                <span className="buy-btn-asterisk">*</span>В случае оплаты не из РФ,
                                сумма списывается в валюте вашей карты по курсу к манату (денежная
                                единица Азербайджана)
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          ) : (
            tariffs.map((tariff) => {
              return (
                <div key={tariff.id} className="prices-sl__items-wrap">
                  <div className="prices-sl__item">
                    <div className="prices-sl__item__title sl-title">{tariff.title}</div>
                    <div className="prices-sl__item-text-wrap">
                      <div className="prices-sl__item__list">
                        {tariff.properties?.desc
                          ? tariff.properties.desc.map((item, index) => {
                              return <span key={index}>{item}</span>;
                            })
                          : null}

                        {tariff.properties?.documents && tariff.properties?.documents.length ? (
                          <div className="documents">
                            <div className="documents__title">Необходимые документы:</div>
                            <div className="documents__list">
                              {tariff.properties.documents.map((item, index) => {
                                return <span key={index}>{item}</span>;
                              })}
                            </div>
                            <div className="documents__bottom">
                              отправлять на электронную почту{' '}
                              <a href="mailto:info@doctor-ayna.ru">info@doctor-ayna.ru</a>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="prices-sl__item__price-wrap">
                      <div className="prices-sl__item__price-top">
                        <span>Цена</span>
                      </div>
                      <div className="prices-sl__item__price-bottom">
                        <span>{tariff.price} ₽</span>
                      </div>
                    </div>
                    {tariff.can_subscribe && (
                      <div className="prices-sl__item__buttons-wrap">
                        <React.Fragment>
                          <div
                            onClick={() => buyHandler(tariff.id)}
                            className="prices-sl__item__button-1"
                          >
                            <span>Купить</span>
                          </div>

                          <PaymentEpointButton id={tariff.id} type={4} price={tariff.price_azn} webinar_state='STATE_CAN_BUY' />

                          <div
                            onClick={() => installmentsHandler(tariff.id, 3)}
                            className="prices-sl__item__button-2"
                          >
                            <span>В рассрочку на 3 месяца</span>
                          </div>

                          <div
                            onClick={() => installmentsHandler(tariff.id, 6)}
                            className="prices-sl__item__button-2"
                          >
                            <span>В рассрочку на 6 месяцев</span>
                          </div>
                        </React.Fragment>
                        <div className="terms">
                          <p>
                            Нажимая кнопку "Купить" Вы соглашаетесь с{' '}
                            <b>
                              <a href={OfertaPsih} target="_blank" rel="noreferrer">
                                договором оферты
                              </a>
                            </b>
                          </p>
                          {tariff.price_azn !== undefined && tariff.price_azn > 0 && (
                            <p className="azn">
                              <span className="asterisk">*</span>В случае оплаты не из РФ, сумма
                              списывается в валюте вашей карты по курсу к манату (денежная единица
                              Азербайджана)
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </Col>
      </Row>

      <TariffsQuestions />
      <TariffsContentBlock />
      {/* <SchoolFeedbacksVideo /> */}
    </Col>
  );
});
