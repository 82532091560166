import {makeObservable, observable} from "mobx"
import {getFeedbacks} from "../api/public";
import {editFeedback, postFeedback} from "../api/admin";

export default class Feedbacks {
    feedbacks = []

    constructor() {
        makeObservable(this, {
            feedbacks: observable,
        });
    }

    setFeedbacks = async () => {
        this.feedbacks = await getFeedbacks();
    }

    addFeedback = async (params) => {
        const data = await postFeedback(params)
        this.feedbacks.push(data)
        return data
    }

    editFeedback = async (id, params) => {
        const data = await editFeedback(id, params)
        this.feedbacks = this.feedbacks.map(item => {
            if (item.id === data.id) {
                return data
            } else {
                return item
            }
        })
        return data
    }
}