import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Title from '../../title/Title';
import LearnMoreLink from "../../learnMoreLink/LearnMoreLink";
import {ABOUT_ROUTE} from "../../../utils/consts";
import './aboutMeMobile.scss';

function AboutMeMobile() {
    return (
        <Row className="about-me-mobile">
            <Col className="about-me-mobile__inner-wrap">
                <Col className="title-responsive">
                    <Title t={'Обо мне'}/>
                </Col>
                <Row>
                    <Col md={{span: 12}} className="about-me-mobile__text">
                        <span>
                            Mеня хорошо знают в профессиональном сообществе как человека, который очень
                            много работает и колоссальные деньги вкладывает в свое развитие. На сегодняшний
                            день не знаю специалиста с таким же высоким европейским и российским
                            образовательным стандартом. Считаю, что в современном мире ценятся только уникальные
                            навыки. Уникальные навыки, как крупицы золота, систематически, как привередливый
                            золотоискатель, ищу и нахожу по всему миру.
                        </span>
                    </Col>
                    <Col className="about-me-mobile__button-wrap">
                            <LearnMoreLink text={'Подробнее'} width={'11.1rem'} route={ABOUT_ROUTE}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default AboutMeMobile;