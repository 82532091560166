import {makeAutoObservable, toJS} from "mobx"

export default class Exercises {
    exercise = {
        description: '',
        url: '',
        title: '',
        properties: {
            doneBtn: true,
            downloadBtn: {
                toggle: false,
                text: '',
                link: ''
            }
        },
        lessons: []
    }

    substitution = (lessonId, questionId) => {
        return this.exercise.lessons.find(i => i.id === lessonId).questions.find(i => i.id === questionId)
    }

    constructor() {
        makeAutoObservable(this);
    }

    clearExercise = () => {
        this.exercise = {
            description: 'description',
            url: '',
            title: 'title',
            lessons: [],
            properties: {
                doneBtn: true,
                downloadBtn: {
                    toggle: false,
                    text: '',
                    link: ''
                }
            },
        }
    }

    setExercise = (data) => {
        this.exercise = data;
    }

    setExerciseTitle = (text) => {
        this.exercise.title = text;
    }

    setExerciseDescription = (text) => {
        this.exercise.description = text;
    }

    addLesson = () => {
        const newLesson = {
            id: 0,
            description: '',
            title: '',
            questions: []
        }

        this.exercise.lessons.push(newLesson)

        this.exercise.lessons.map((item, index) => {
            item.id = index
            return item
        })
    }


    setLessonDescription = (value, lessonId) => {
        this.exercise.lessons.find(i => i.id === lessonId).description = value;
    }

    deleteLesson = (id) => {
        this.exercise.lessons = this.exercise.lessons.filter(item => item.id !== id)
    }

    deleteLessonDescription = (lessonId) => {
        this.exercise.lessons.find(i => i.id === lessonId).description = ''
    }

    setQuestionType = (lessonId, type) => {
        const question = {
            id: 0,
            title: '',
            type: type,
            properties: {
                checkboxes: [],
                table: [],
                textarea: [],
            }
        }
        this.exercise.lessons.find(i => i.id === lessonId).questions.push(question)
        this.exercise.lessons.find(i => i.id === lessonId).questions.map((item, index) => {
            item.id = index
            return item
        })
    }

    setQuestionTitle = (value, lessonId, questionId) => {
        this.substitution(lessonId, questionId).title = value
    }

    setDownloadBtn = () => this.exercise.properties.downloadBtn.toggle = !this.exercise.properties.downloadBtn.toggle
    setDownloadBtnText = (value) => this.exercise.properties.downloadBtn.text = value
    setDownloadBtnLink = (value) => this.exercise.properties.downloadBtn.link = value

    setDoneBtn = () => this.exercise.properties.doneBtn = !this.exercise.properties.doneBtn

    createCheckbox = (lessonId, questionId) => {
        this.substitution(lessonId, questionId).properties.checkboxes.push({text: ''})
        this.substitution(lessonId, questionId).properties.checkboxes.map((item, index) => {
            item.id = index
            return item
        })
    }

    setCheckboxText = (lessonId, questionId, value, id) => {
        this.substitution(lessonId, questionId).properties.checkboxes.map(item => {
            if (item.id === id) {
                item.text = value
                return item
            }
            return item
        })
    }

    deleteCheckbox = (lessonId, questionId, checkboxId) => {
        this.substitution(lessonId, questionId).properties.checkboxes = this.substitution(lessonId, questionId).properties.checkboxes.filter(item => item.id !== checkboxId)
    }

    createTextarea = (lessonId, questionId) => {
        this.substitution(lessonId, questionId).properties.textarea.push({title: '', placeholder: ''})
        this.substitution(lessonId, questionId).properties.textarea.map((item, index) => {
            item.id = index
            return item
        })
    }

    setTextareaTitle = (lessonId, questionId, value, id) => {
        this.substitution(lessonId, questionId).properties.textarea.map(item => {
            if (item.id === id) {
                item.title = value
                return item
            }
            return item
        })
    }

    setTextareaPlaceholder = (lessonId, questionId, value, id) => {
        this.substitution(lessonId, questionId).properties.textarea.map(item => {
            if (item.id === id) {
                item.placeholder = value
                return item
            }
            return item
        })
    }

    deleteTextarea = (lessonId, questionId, textareaId) => {
        this.substitution(lessonId, questionId).properties.textarea = this.substitution(lessonId, questionId).properties.textarea.filter(item => item.id !== textareaId)
    }

    deleteQuestion = (lessonId, questionId) => {
        this.exercise.lessons.find(i => i.id === lessonId).questions = this.exercise.lessons.find(i => i.id === lessonId).questions.filter(item => item.id !== questionId)

    }

    addTable = (lessonId, questionId) => {
        this.substitution(lessonId, questionId).properties.table.push({rows: [], abilityToAddField: false})
        this.substitution(lessonId, questionId).properties.table.map((item, index) => {
            item.id = index
            return item
        })
    }

    addRow = (lessonId, questionId, tableId) => {
        this.substitution(lessonId, questionId).properties.table[tableId].rows.push({
            id: 0,
            cols: [{
                id: 0,
                text: ''
            }]
        })

        this.substitution(lessonId, questionId).properties.table[tableId].rows.map((item, index) => {
            item.id = index
            return item
        })
    }

    addColWithText = (lessonId, questionId, tableId, rowId) => {
        this.substitution(lessonId, questionId).properties.table[tableId].rows[rowId].cols.push({
            id: 0,
            text: ''
        })

        this.substitution(lessonId, questionId).properties.table[tableId].rows[rowId].cols.map((item, index) => {
            item.id = index
            return item
        })
    }

    addColWithTextarea = (lessonId, questionId, tableId, rowId) => {
        this.substitution(lessonId, questionId).properties.table[tableId].rows[rowId].cols.push({
            id: 0,
            textarea: true,
        })

        this.substitution(lessonId, questionId).properties.table[tableId].rows[rowId].cols.map((item, index) => {
            item.id = index
            return item
        })
    }

    setColText = (lessonId, questionId, value, tableId, rowId, colId) => {
        this.substitution(lessonId, questionId).properties.table[tableId].rows[rowId].cols[colId].text = value
    }

    setAbilityToAddField = (lessonId, questionId) => {
        this.substitution(lessonId, questionId).properties.table[0].abilityToAddField = !this.substitution(lessonId, questionId).properties.table[0].abilityToAddField
    }

    setThCol = (lessonId, questionId, tableId, rowId) => {
        this.substitution(lessonId, questionId).properties.table[tableId].rows[rowId].thead = true
    }

    addQuestionWithButtons = (lessonId, questionId) => {
        this.exercise.lessons.find(i => i.id === lessonId).questions[questionId] = {
            id: 0,
            title: '',
            type: 'questionWithButtons',
            properties: {
                text: '',
                answer: 'boolean',
            }
        }
    }

    setBtnText = (lessonId, questionId, value) => this.substitution(lessonId, questionId).properties.btnText = value
    deleteBtn = (lessonId, questionId) => this.substitution(lessonId, questionId).properties.btn = false

    deleteRow = (lessonId, questionId, tableId, rowId) => {
        this.substitution(lessonId, questionId).properties.table[tableId].rows = this.substitution(lessonId, questionId).properties.table[tableId].rows.filter(item => item.id !== rowId)
    }

    deleteTable = (lessonId, questionId, tableId) => {
        console.log(lessonId, questionId, tableId)
        console.log(toJS(this.substitution(lessonId, questionId).properties))
        this.substitution(lessonId, questionId).properties.table = this.substitution(lessonId, questionId).properties.table.filter(item => item.id !== tableId)
    }

    createMediaFile = (lessonId, questionId) => {
        this.exercise.lessons.find(i => i.id === lessonId).questions[questionId] = {
            id: 0,
            title: '',
            type: 'mediaFile',
            properties: {
                text: '',
                link: '',
            }
        }
    }

    setSetMediaFileText = (lessonId, questionId, value) => this.substitution(lessonId, questionId).properties.text = value
    setSetMediaFileLink = (lessonId, questionId, value) => this.substitution(lessonId, questionId).properties.link = value

    setQuestionWithButtonsText = (lessonId, questionId, value) => this.substitution(lessonId, questionId).properties.text = value
}
