import React, {useEffect, useState} from 'react';
import {Col, Form, InputGroup, Row} from 'react-bootstrap';
import './contact.scss'
import Title from '../title/Title'
import {Formik} from 'formik';
import * as yup from 'yup';
import RegularBtn from '../regularBtn/RegularBtn';
import {$host, phoneRegExp} from "../.../../../utils/consts"
import {useMediaQuery} from 'react-responsive';
import AlertModal from "../alertModal/AlertModal";

const schema = yup.object().shape({
    name: yup.string().required('Обязательное поле'),
    type: yup.string(),
    phone: yup.string().matches(phoneRegExp, 'Некорректный номер').required('Обязательное поле'),
    email: yup.string().required('Обязательное поле').email('введите корректный email'),
    message: yup.string().required('Обязательное поле'),
    terms: yup.bool().required().oneOf([true], 'Приймите условия'),
});

const Сontact = ({bg = 'rgb(229, 229, 229)'}) => {
    const max767 = useMediaQuery({
        query: '(max-width: 420px)'
    })

    let btnWidth = '8rem'
    if (max767) {
        btnWidth = '100%'
    }

    const [types, setTypes] = useState([]);
    const [alertModalVisible, setAlertModalVisible] = useState(false);

    const typesRequest = () => {
        $host.get(`api/v1/user/messages/types`)
            .then(res => {
                setTypes(res.data);
            })
    }

    useEffect(() => {
        typesRequest()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendMessageRequest = (values) => {
        $host.post(`api/v1/user/messages`, {
            name: values.name,
            phone: values.phone,
            email: values.email,
            type_id: values.type,
            message: values.message,
        }).then(
            setAlertModalVisible(true),
            values.message = '',
            setTimeout(() => {
                setAlertModalVisible(false)
            }, 1600))
    }

    return (
        <Row style={{justifyContent: 'center', background: bg}}>
            <Col className="contact col-component-wrapper">
                <Title t="Отправить сообщение" d={'none'}/>
                <Formik
                    validationSchema={schema}
                    onSubmit={sendMessageRequest}
                    initialValues={{
                        name: '',
                        type: 1,
                        phone: '',
                        email: '',
                        terms: false,
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          dirty
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="contact__inputs">
                                <Col>
                                    <Form.Group controlId="1">
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="text"
                                                placeholder="Имя"
                                                aria-describedby="inputGroupPrepend"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.name}
                                            />
                                            <Form.Control.Feedback tooltip type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <select
                                        name="type"
                                        value={values.type}
                                        onChange={handleChange}
                                    >
                                        {types.map(item => <option
                                            key={item.id}
                                            value={item.id}
                                            label={item.title}
                                        />)}
                                    </select>
                                    <Form.Group controlId="3">
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="text"
                                                placeholder="Номер телефона"
                                                aria-describedby="inputGroupPrepend"
                                                name="phone"
                                                value={values.phone}
                                                onChange={handleChange}
                                                isInvalid={!!errors.phone}
                                            />
                                            <Form.Control.Feedback tooltip type="invalid">
                                                {errors.phone}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group controlId="4">
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="text"
                                                placeholder="email"
                                                aria-describedby="inputGroupPrepend"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                            />
                                            <Form.Control.Feedback tooltip type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="5" style={{
                                        height: '100%',
                                    }}>
                                        <InputGroup hasValidation style={{
                                            height: '100%',
                                        }}>
                                            <Form.Control
                                                as={'textarea'}
                                                type="text"
                                                placeholder="Текст сообщения"
                                                name="message"
                                                value={values.message}
                                                onChange={handleChange}
                                                isInvalid={!!errors.message}/>
                                            <Form.Control.Feedback tooltip type="invalid">
                                                {errors.message}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Check
                                            className="contact__cheeck"
                                            required
                                            name="terms"
                                            label="Согласен(а) с политикой конфиденциальности"
                                            onChange={handleChange}
                                            isInvalid={!!errors.terms}
                                            feedback={errors.terms}
                                            id="6"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <RegularBtn type={"submit"} t={'Отправить'} onoff={!isValid || !dirty} mt={'0'}
                                                w={btnWidth}/>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
                <AlertModal show={alertModalVisible} text={'Письмо отправлено!'}/>
            </Col>
        </Row>
    );
}

export default Сontact;
