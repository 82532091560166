import React from 'react';
import {useStore} from "../../../../../../store";

function ExerciseAdminMediaFile({lessonItem, questionItem}) {

    const {exercisesStore} = useStore()

    function seMediaFileTextHandler(lessonId, questionId, value) {
        exercisesStore.setSetMediaFileText(lessonId, questionId, value)
    }

    function setMediaFileLinkHandler(lessonId, questionId, value) {
        exercisesStore.setSetMediaFileLink(lessonId, questionId, value)
    }

    return (
        <>
            <input type="text"
                   value={exercisesStore.exercise.properties.text}
                   placeholder={'текст ссылки'}
                   onChange={(e) =>
                       seMediaFileTextHandler(
                           lessonItem.id,
                           questionItem.id,
                           e.target.value
                       )
                   }/>

            <input type="text"
                   value={exercisesStore.exercise.properties.link}
                   placeholder={'ссылка на файл'}
                   onChange={(e) =>
                       setMediaFileLinkHandler(
                           lessonItem.id,
                           questionItem.id,
                           e.target.value
                       )
                   }/>
        </>
    );
}

export default ExerciseAdminMediaFile;