import React from 'react';
import './learnMoreLink.scss'
import {NavLink} from "react-router-dom";

function LearnMoreLink({text, width, route}) {
    return (
        <div className="d-flex justify-content-center">
            <NavLink to={route} style={{display: 'contents'}}>
                <div className="learn-more-link-wrap" style={{width: width}}>
                    <div className="learn-more-link">
                    <span className="learn-more-link__text">
                            {text}
                    </span>
                        <span className="learn-more-link__white-circle">
                      <svg xmlns="http://www.w3.org/2000/svg" id="icon-arrow-right" viewBox="0 0 21 12">
                        <path
                            d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"></path>
                      </svg>
                    </span>
                    </div>
                </div>
            </NavLink>
        </div>
    );
}

export default LearnMoreLink;