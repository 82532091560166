import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Title from '../title/Title';
import './expretise.scss'

function Expertise() {
    return (
        <Row style={{
            flexDirection: 'column',
            backgroundColor: '#F2F2F2',
            alignItems: 'center',
        }}>
            <Col style={{
                maxWidth: 1500,
                marginBottom: '1rem',
            }}>
                <Row style={{ flexDirection: 'column' }}>
                    <Col>
                        <Col className="title-responsive">
                            <Title t={'Я являюсь экспертом федеральных СМИ'} />
                        </Col>
                        <span style={{
                            fontFamily: 'Montserrat',
                            fontSize: '1rem',
                        }}>
                            <p>Даю профессиональные комментарии для программ «Человек и закон», «Прямой эфир с Андреем Малаховым», «Пусть говорят», «Мужское и женское», «ДНК
                                на НТВ», «Новости» на России-1, РЕН ТВ, телеканал «Звезда» и т.д.</p>
                        </span>
                    </Col>
                </Row>
            </Col>
        </Row >
    );
}

export default Expertise;