import React from 'react';
import './signUp.scss'
import RegularBtn from '../regularBtn/RegularBtn';
import * as yup from 'yup';
import {Col, Form} from "react-bootstrap";
import {Formik} from 'formik';
import {observer} from "mobx-react-lite";
import {useStore} from "../../store";

const schema = yup.object().shape({
    login: yup.string().trim().email('Введите корректный email').required('Введите email'),
    password: yup.string().trim().typeError('Должно быть строкой').required('Введите пароль'),
    first_name: yup.string().typeError('Должно быть строкой').required('Введите имя'),
    last_name: yup.string().typeError('Должно быть строкой').required('Введите фамилию'),
});

const SignUp = observer(({onHide}) => {
    const {userStore} = useStore();

    const signUpHandler = (values) => {
        userStore.userSignUp(values)
        onHide()
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={signUpHandler}
            initialValues={{
                login: '',
                password: '',
                first_name: '',
                last_name: '',
            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  isValid,
                  errors,
                  dirty
              }) => (
                <>
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="sign-up">
                            <Form.Group as={Col} md="12" controlId="validationFormik01">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Email"
                                    name="login"
                                    value={values.login}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.login}
                                />
                                <Form.Control.Feedback type="invalid" className="mb-3">
                                    {errors.login}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationFormik02">
                                <Form.Label>Пароль</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Пароль"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.password}
                                />
                                <Form.Control.Feedback type="invalid" className="mb-3">
                                    {errors.password}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>

                                <Form.Group as={Col} xs="5" controlId="validationFormik03">
                                    <Form.Label>Имя</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Имя"
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={!!errors.first_name}
                                    />
                                    <Form.Control.Feedback type="invalid" className="mb-3">
                                        {errors.first_name}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} xs="6" controlId="validationFormik04">
                                    <Form.Label>Фамилия</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Фамилия"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={!!errors.last_name}
                                    />
                                    <Form.Control.Feedback type="invalid" className="mb-3">
                                        {errors.last_name}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </div>
                        </div>
                        <RegularBtn onoff={!isValid || !dirty} type={"submit"} t={'Зарегистрироваться'} mt={'0'}/>
                    </Form>
                </>
            )}
        </Formik>
    );
});

export default SignUp;