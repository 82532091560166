import React from 'react';
import { Col } from "react-bootstrap";
import {useMediaQuery} from 'react-responsive';
import AboutMe from '../../components/aboutMe/desctop/AboutMe';
import AboutMeMobile from '../../components/aboutMe/mobile/AboutMeMobile';
// import Articles from '../../components/articles/Articles';
import Certificates from '../../components/certificates/Certificates';
import Competences from '../../components/competences/Competences';
import CompetencesMobile from '../../components/competences/CompetencesMobile';
import Contacts from '../../components/contacts/Contacts';
import Contact from '../../components/contact/Сontact';
import Expertise from '../../components/expertise/Expertise';
import ExpertiseMobile from '../../components/expertise/ExpertiseMobile';
import HomeBackgroundWrapper from '../../components/homeBackgroundWrapper/HomeBackgroundWrapper';
import HomePageQa from '../../components/qa/HomePageQa';
import SpecialOffers from '../../components/specialOffers/SpecialOffers';
import VideoCourcesSlider from '../../components/videocourseSlider/VideoСourcesSlider';

import VideoReviews from '../../components/videoReviews/VideoReviews';
import {observer} from "mobx-react-lite";
import Consultation from "../../components/consultation/Consultation";

const HomePage = observer(() => {
    const max767 = useMediaQuery({
        query: '(max-width: 767px)'
    })

    return (
        <Col>
            <HomeBackgroundWrapper/>
            {max767 ? <AboutMeMobile/> : <AboutMe/>}
            {/*<Articles/>*/}
            <Certificates/>
            {max767 ? <ExpertiseMobile/> : <Expertise/>}
            {max767 ? <CompetencesMobile/> : <Competences/>}
            <Consultation/>
            <VideoCourcesSlider/>
            <SpecialOffers/>
            <VideoReviews/>
            <HomePageQa/>
            <Contacts/>
            <Contact/>
        </Col>
    );
})

export default HomePage;