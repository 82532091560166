import React, {Suspense, useEffect} from 'react';
import './App.scss';
import {Container, Row} from "react-bootstrap";
import CustomSpinner from "./components/customSpinner/CustomSpinner";
import {useStore} from "./store";
import ErrorModal from "./components/errorsCatchers/networkErrorsCatcher/ErrorModal";
import {observer} from "mobx-react";

const SchoolLandingPage = React.lazy(() => import('./pagesSchoolLanding/SchoolLandingPage'));

const ObservableAppSchoolLanding = observer(() => {

    const {errorsStore} = useStore()

    const isError = errorsStore.isError
    const errorMessage = errorsStore.errorMessage

    const {userStore} = useStore()

    useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            userStore.setUser()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Container fluid>
            <Row className="school-landing-container-wrap">
                <ErrorModal message={errorMessage} show={isError}/>
                <Suspense fallback={<CustomSpinner/>}>
                    <SchoolLandingPage/>
                </Suspense>
            </Row>
        </Container>
    );
});

export default ObservableAppSchoolLanding;
