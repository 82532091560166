import React from 'react';
import './aqItem.scss'
import {NavLink} from "react-router-dom";
import {PlayCircle} from "react-bootstrap-icons";

function AqItem({id, title, image, tags}) {
    return (
        <NavLink to={`/aq/` + id}>
            <div className="qa-item">
                <img className="qa-item__image" src={image} alt=""/>
                <PlayCircle className="qa-item__circle"/>
            </div>
            <div className="qa-item__tags">
                {tags.map(item => <span key={item.id}>
                    #{item.title}&nbsp;
                </span>
                )}
                <h5 className="qa-item__title">{title}</h5>
            </div>
        </NavLink>
    );
}

export default AqItem;