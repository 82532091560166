import React from 'react';

function EpointForm({ refKey, payData }) {
  return (
    <form action="https://epoint.az/api/1/checkout" method="POST" ref={refKey}>
      <input type="hidden" name="signature" value={payData.signature} />
      <input type="hidden" name="data" value={payData.data} />
    </form>
  );
}

export default EpointForm;
