import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { adminRoutes, authRoutes, publicRoutes } from "./routes";
import CustomSpinner from "./components/customSpinner/CustomSpinner";
import AppLayout from "./layouts/AppLayout";
import { HOME_ROUTE } from "./utils/consts";
import SignUpSignInModal from "./components/signupSignin/SignUpSignInModal";
import PasswordRecoveryModal from "./components/signupSignin/PasswordRecoveryModal";
import AlertModal from "./components/alertModal/AlertModal";
import { observer } from "mobx-react-lite";
import { useStore } from './store/index';
import 'react-image-lightbox/style.css';

const ObservableApp = observer(() => {
  const location = useLocation();
  const { userStore } = useStore();

  const [previousLocation, setPreviousLocation] = useState(location);
  const [isLoading, setIsLoading] = useState(true);

  const isAuth = userStore.isAuth
  const isAdmin = userStore.isAdmin

  const tryAuthUser = async () => {
    await userStore.setUser();
    setIsLoading(false);
  }

  useEffect(() => {
    tryAuthUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (!(location.state && location.state.modal)) {
      setPreviousLocation(location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (isLoading) {
    return <CustomSpinner/>;
  }

  const isModal = (location.state && location.state.modal && previousLocation !== location);

  return (
    <>
      <Switch location={isModal ? previousLocation : location}>
        {isAdmin && adminRoutes.map(({ path, Component }) =>
          <Route
            key={path}
            path={path}
            render={(props) => (
              <Suspense fallback={<CustomSpinner/>} key={path}>
                <AppLayout>
                  <Component {...props} />
                </AppLayout>
              </Suspense>
            )}
          />
        )}

        {isAuth && authRoutes.map(({ path, Component }) =>
          <Route
            key={path}
            path={path}
            render={(props) => (
              <Suspense fallback={<CustomSpinner/>} key={path}>
                <AppLayout>
                  <Component {...props} />
                </AppLayout>
              </Suspense>
            )}
          />
        )}

        {publicRoutes.map(({ path, Component }) =>
          <Route
            exact
            key={path}
            path={path}
            render={(props) => (
              <AppLayout>
                <Component {...props} />
              </AppLayout>
            )}
          />
        )}

        <Redirect to={HOME_ROUTE}/>
      </Switch>
      {isModal ? (
        <>
          <Route exact path="/modal/auth">
            <SignUpSignInModal show={isModal} location={location}/>
          </Route>
          <Route exact path="/modal/reset">
            <PasswordRecoveryModal show={isModal}/>
          </Route>
          <Route exact path="/modal/success">
            <AlertModal show={isModal}/>
          </Route>
        </>
      ) : null
      }
    </>
  );
})

export default ObservableApp;