import React, {useState} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Col, Form, Modal, Spinner,} from 'react-bootstrap';
import RegularBtn from '../regularBtn/RegularBtn';
import AlertModal from '../alertModal/AlertModal';
import {useHistory} from "react-router-dom";
import './passwordRecoveryModal.scss'
import {passRecovery} from "../../api/generalApi";
import {HOME_ROUTE} from "../../utils/consts";

const schema = yup.object().shape({
    email: yup.string().trim().required('Введите email'),
});

const PasswordRecoveryModal = ({show}) => {
    const history = useHistory();

    const [showModal, setShowModal] = useState(show)
    const [alertModalShow, setAlertModalShow] = useState(false)
    const [spinnerVisible, setSpinnerVisible] = useState(false)

    function passRecoveryHandler(email) {
        passRecovery(email).then(() => {
            setSpinnerVisible(false)
            setShowModal(false)
            setAlertModalShow(true)
            setTimeout(
                () => setAlertModalShow(false),
                3000
            );
            setTimeout(
                () => history.push(HOME_ROUTE, {modal: true}),
                3000
            );
        })
    }

    return (
        <>
            <Modal show={showModal} onHide={() => history.goBack()} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Восстановить пароль</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        validationSchema={schema}
                        initialValues={{
                            email: '',
                        }}>
                        {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                              dirty
                          }) => (
                            <>
                                <Form noValidate onSubmit={handleSubmit}>
                                    <div>
                                        <Form.Group as={Col} xs="12" controlId="validationFormik01">
                                            <Form.Label>Введите email указанный при регистрации</Form.Label>
                                            <Form.Control rows={3}
                                                          type="text"
                                                          placeholder="Email"
                                                          name="email"
                                                          value={values.email}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={!!errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid" className="mb-3">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </Form>
                                <div className="password-recovery__send-btn">
                                    <RegularBtn onoff={!isValid || !dirty} mt={'0'} t={'Отправить письмо'} w={'15rem'}
                                                onClick={() => passRecoveryHandler(
                                                    values.email,
                                                )}/>
                                    {spinnerVisible &&
                                    <Spinner className="password-recovery__spinner" animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>}
                                </div>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            <AlertModal text="Письмо с иинструкциями отправленно на указанный email" show={alertModalShow}/>
        </>
    );
};

export default PasswordRecoveryModal;