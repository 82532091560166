import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import Title from '../../../components/title/Title';
import DescriptionItem from './DescriptionItem';
import { Clock, Exclamation } from 'react-bootstrap-icons';
import './videoCource.scss';
import { webinarType } from '../../../utils/consts';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import AlertModal from '../../../components/alertModal/AlertModal';
import BtnWithArrow from '../../../components/btnWithArrow/BtnWithArrow';
import RobokassaForm from '../../../components/robokassaForm/RobokassaForm';
import Countdown from 'react-countdown';
import CountdownRenderer from '../../../components/countdownRender/CountdownRender';
import { useStore } from '../../../store';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { getPaymentData } from '../../../api/generalApi';
import moment from 'moment';
import GiftButton from '../../../components/giftButton/giftButton';
import PaymentEpointButton from '../../../components/paymentEpointButton/PaymentEpointButton';

moment.locale('ru', {
  months: 'января_февраля_марта_апреля_мая_июня_июля_августа_сентября_октября_ноября_декабря'.split(
    '_',
  ),
  monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
  weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
  weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
  weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
});

const VideoCource = observer(() => {
  const { id } = useParams();
  const type = webinarType;
  const form = useRef(null);
  const { privateStore, userStore } = useStore();

  const webinar = toJS(privateStore.webinar);
  const [payData, setPayData] = useState({});
  const [alertModalVisible, setAlertModalVisible] = useState(false);
  const [clickOnButBtn, setClickOnButBtn] = useState(false);

  // const [monthRu, setMonthRu] = useState('');
  // const [day, setDay] = useState();
  const day = moment(webinar.custom_price?.end).date();

  const descriptionOneCol =
    !!webinar && webinar.description?.slice(0, webinar.description.length / 2 + 1);
  const descriptionTwoCol =
    !!webinar && webinar.description?.slice(webinar.description.length / 2 + 1);
  const isAuthUser = userStore.isAuth;

  useEffect(() => {
    privateStore.setWebinarById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthUser, clickOnButBtn]);

  // let startDate
  let endDate;

  if (webinar.custom_price) {
    // startDate = webinar.custom_price.start
    endDate = moment(webinar.custom_price.end);
  }

  const buyReq = () => {
    const params = { type, id };
    getPaymentData(params).then((res) => {
      setClickOnButBtn(true);
      if (!res.IsFree) {
        setPayData(res);
        form.current.submit();
      }
    });
  };

  const buyHandler = () => {
    if (isAuthUser) {
      buyReq();
    }
  };

  // const currentDay = new Date(webinar.custom_price?.end).getDay()
  // const currentMonth = new Date(webinar.custom_price?.end).getMonth()

  // const getMonthName = () => {
  //     const months = [
  //         'января', 'февраля', 'марта', 'апреля',
  //         'мая', 'июня', 'июля', 'августа',
  //         'сентября', 'октября', 'ноября', 'декабря'
  //     ]
  //
  //     const monthName = months[currentMonth]
  //     return monthName
  // }

  // function getMonthRu() {
  //     return new Promise((resolve, reject) => {
  //         if (Number.isInteger(+currentMonth.toString())) {
  //             resolve(getMonthName())
  //         } else {
  //             reject()
  //         }
  //     })
  // }
  //
  // getMonthRu().then((res) => setMonthRu(res));

  // let month = moment().locale('ru').format('MMMM')

  const monthRu = moment(endDate).locale('ru').format('MMMM');

  return (
    <>
      <Col className="col-page-wrapper videocource-wrap">
        <Breadcrumbs to={2} />
        <Title t={webinar.title} d={'none'} mt={'2.7rem'} />
        <Row>
          <Col xs={12} md={6} className="videocource__img-wrap">
            <img src={webinar.image} alt="" />
            <div className="videocource__rating">{webinar.age}+</div>
          </Col>
          <Col xs={12} md={6} className="videocource__info">
            <div>
              <Row>
                <Col>
                  <div className="videocource__info__title">{webinar.title}</div>
                  <div>
                    <span className="videocource__info__icon">
                      <Clock />
                    </span>
                    Длительность:{' '}
                    <span className="videocource__info__timelength">{webinar.length}</span>
                  </div>
                </Col>
              </Row>
              <GiftButton id={id} type={type} />
            </div>
            {webinar.custom_price && (
              <Row>
                <Col>
                  <div className="countdown">
                    <div className="countdown__price">
                      Цена сейчас: {webinar.custom_price.price}
                    </div>
                    <div className="countdown__alert">До повышения цены осталось</div>
                    <Countdown date={endDate} renderer={CountdownRenderer} />
                    <div className="countdown__future-price">
                      <span>
                        Цена с {day} {monthRu}
                      </span>

                      <span>{webinar.price}</span>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                {webinar.price === 0 ? (
                  <div className="videocource__price-free">Бесплатный видеокурс</div>
                ) : (
                  <div>
                    {!webinar.custom_price && (
                      <>
                        Стоимость:{' '}
                        <span className="videocource__info__price">{webinar.price} ₽</span>
                      </>
                    )}
                  </div>
                )}
                <div className="videocource__btn-wrap">
                  <RobokassaForm refKey={form} payData={payData} />
                  {webinar.is_owner && (
                    <div>
                      <NavLink to={'/account/myvideos/' + id}>
                        <BtnWithArrow
                          t={'К просмотру'}
                          w={'100%'}
                          bg={'#82ac0b'}
                          shad={'#82ac0b 1px 1px 3px'}
                        />
                      </NavLink>
                    </div>
                  )}
                  {!webinar.is_owner && (
                    <>
                      {!isAuthUser && (
                        <NavLink
                          className="videocource__buy-btn"
                          to={{ pathname: '/modal/auth', state: { modal: true } }}
                        >
                          Приобрести видеокурс
                        </NavLink>
                      )}

                      {webinar.price === 0 && isAuthUser && (
                        <button
                          
                          className="videocource__buy-btn"
                          onClick={() => buyHandler()}
                        >
                          Добавить видеокурс в мои видеокурсы
                        </button>
                      )}

                      {isAuthUser && webinar.state === 'STATE_CAN_PROLONG' && !!webinar.prolongation_price && (
                        <button
                          
                          className="videocource__buy-btn"
                          onClick={() => buyHandler()}
                        >
                          Продлить за {webinar.prolongation_price} ₽
                        </button>
                      )}

                      {isAuthUser && webinar.state === 'STATE_CAN_BUY' && !!webinar.price && (
                        <button
                          
                          className="videocource__buy-btn"
                          onClick={() => buyHandler()}
                        >
                          Приобрести видеокурс из РФ
                        </button>
                      )}

                      <PaymentEpointButton id={id} type={type} price={webinar.price_azn} prolongation_price={webinar.prolongation_price_azn} webinar_state={webinar.state} />

                      {webinar.IsFree && (
                        <NavLink to={'/account/myvideos/' + id}>
                          <BtnWithArrow
                            t={'К просмотру'}
                            w={'100%'}
                            bg={'#82ac0b'}
                            shad={'#82ac0b 1px 1px 3px'}
                          />
                        </NavLink>
                      )}
                    </>
                  )}
                </div>
                {webinar.price_azn !== undefined && webinar.price_azn > 0 && (
                  <Col className="videocource__after-btns">
                    <span className="videocource__buy-btn-asterisk">*</span>В случае оплаты не из
                    РФ, сумма списывается в валюте вашей карты по курсу к манату (денежная единица
                    Азербайджана)
                  </Col>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="videocource__description-wrap">
          <Col xs={12} md={6}>
            {descriptionOneCol &&
              descriptionOneCol.map((i) => <DescriptionItem key={i} text={i} />)}
          </Col>
          <Col xs={12} md={6}>
            {descriptionTwoCol &&
              descriptionTwoCol.map((i) => <DescriptionItem key={i} text={i} />)}
          </Col>
        </Row>
      </Col>
      <AlertModal
        text={'Вебинар уже приобретен'}
        show={alertModalVisible}
        onHide={() => setAlertModalVisible(false)}
        icon={<Exclamation color="red" />}
      />
    </>
  );
});

export default VideoCource;
