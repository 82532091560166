import React from 'react';
import {NavLink, Route, Switch} from "react-router-dom";
import {SCHOOL_ADMIN_ROUTE, SCHOOL_TARIFFS_NEW_ROUTE, SCHOOL_TARIFFS_ROUTE} from "../../../../utils/consts";
import TariffsNew from "./TariffsNew";

function Tariffs() {
    return (
        <div>
            <NavLink to={SCHOOL_ADMIN_ROUTE + SCHOOL_TARIFFS_ROUTE + SCHOOL_TARIFFS_NEW_ROUTE}>Добавить тариф</NavLink>
            <Switch>
                <Route path={SCHOOL_ADMIN_ROUTE + SCHOOL_TARIFFS_ROUTE + SCHOOL_TARIFFS_NEW_ROUTE}>
                    <TariffsNew/>
                </Route>
            </Switch>
        </div>
    );
}

export default Tariffs;