import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Title from '../../../components/title/Title';
import CertificatesSlider from '../../../components/certificates/certificatesSlider/CertificatesSlider';
import './competencePage.scss'
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const CompetencePage = () => {
    return (
        <Col className="col-page-wrapper">
            <Breadcrumbs to={'2'} />
            <Title t={'Лечение депрессий и панических атак.'}/>
            <Row>
                <Col>
                    <div className="mb-1">Как лечить панически атаки? Депрессии? Психосоматические заболевания? ОКР?
                    </div>
                    <div className="mb-1 mt-1">Препараты или походы к психологу?</div>
                    <div className="mb-4 mt-1">В каждом варианте много нюансов, постараюсь объяснить.</div>
                    <div className="competence-page__list">

                        <div className="mb-3 competence-page__list__number">1</div>
                        <div className="mb-2">
                            Грамотно назначить лечение может не каждый психиатр. И далеко не каждый психолог осуществит
                            правильно психологическую коррекцию.
                        </div>
                        <div>
                            Обусловлено это пробелом в системе их подготовки. Получив диплом с отличием, неважно
                            медицинский или психологический
                            (у меня оба), специалист совершенно не готов к практическому консультированию.
                        </div>

                        <div className="mt-4 mb-3 competence-page__list__number">2</div>
                        <div>
                            <div className="mb-2">
                                Как следствие, возникает много ошибок. Например, пациент страдающий паническими атаками,
                                начинает принимать транквилизатор, назначенный психиатром для того, чтобы снять приступ.
                            </div>
                            <div className="mb-2">
                                Но и в межприступном периоде пациент испытывает страх возникновения нового эпизода
                                панической атаки, по принципу «а вдруг мне снова станет плохо».
                            </div>
                            <div className="mb-2">
                                Ведь страх возникает от мыслей, а таблетки не влияют на мышление! Но психиатр не знает о
                                необходимости корректировать мышление, о том, что такое саморегуляция, так как его этому
                                не учили.
                            </div>
                            <div className="mb-2">
                                Пациент вынужден принимать транквилизатор и вне приступа, постепенно формируется
                                зависимость.
                            </div>
                        </div>

                        <div className="mt-4 mb-3 competence-page__list__number">3</div>
                        <div>
                            <div className="mb-2">
                                Такие разные диагнозы как «панические атаки», «генерализованное тревожное расстройство»,
                                «расстройство адаптации», «психосоматическое расстройство», «тревожно-депрессивное
                                расстройство» всегда обусловлены одним началом: истощением психобиологического резерва
                                организма.
                            </div>
                            <div className="mb-2">
                                Поэтому, несмотря на разные формулировки, для их коррекции применяются одни и те же
                                группы лекарственных средств: антидепрессанты, нейролептики, анксиолитики.
                            </div>
                            <div className="mb-2">
                                Грамотно подобранные препараты восстановят функции организма.
                                Вы снова начнёте спать, есть, у вас стабилизируется настроение и либидо. Беда в другом.
                                Даже на фоне отлично подобранного лечения, пока вы не измените своё мышление, все равно
                                будете провоцировать новые срывы.
                            </div>
                            <div className="mb-2">
                                Поэтому после отмены лекарств вновь становится плохо, возникает рецидив. Таблетка же не
                                научила вас думать по-другому.
                            </div>
                        </div>

                        <div className="mt-4 mb-3 competence-page__list__number">4</div>
                        <div>
                            <div className="mb-2">
                                Выход: сочетать медицинский (если есть конкретные клинические критерии в вашем
                                состоянии) и психологический подход, добавить свой труд.
                            </div>
                        </div>

                        <div className="mt-4 mb-3 competence-page__list__number">5</div>
                        <div>
                            <div className="mb-2">
                                Имеет смысл заранее позаботиться о том, чтобы выбрать правильную методику
                                психологической коррекции. Единственный метод психологической коррекции, под которым
                                есть доказательная база – это когнитивно-поведенческая терапия (КПТ).
                            </div>
                            <div className="mb-2">
                                Именно поэтому данная методика является мировым «золотым» стандартом оказания помощи при
                                тревожных расстройствах, панических атаках, психосоматической патологии и депрессии.
                            </div>
                            <div className="mb-2">
                                При наличии нужной информации вы можете восстановиться и больше себя не доводить до
                                такого состояния!
                            </div>
                            <div className="mb-2">
                                Если вы чувствуете себя плохо, у вас есть клинические признаки истощения (о них я все
                                рассказала в самом 1-м видеокурсе из цикла о психологическом здоровье «Основы
                                психологического здоровья») – оптимальный вариант пропить препараты, но за время их
                                приема пройти психотерапию.
                            </div>
                            <div className="mb-2">
                                Вы можете пройти психологическую коррекцию вместе со мной. Та психотерапия, которую
                                проводила бы вам я на личном приёме, записана на видео в цикле о психологическом
                                здоровье (6 частей).
                            </div>
                            <div className="mb-2">
                                Ищите их в разделах «видеокурсы» и «спецпредложения».
                            </div>
                            <div className="mb-2">
                                Содержание цикла основано на сочетании медицинского и психотерапевтического подхода.
                                Инструменты психологической коррекции – самые эффективные техники КПТ. Коррекция
                                мышления – залог здоровья и жизни без рецидивов.
                            </div>
                            <div className="mb-2">
                                Цикл о психологическом здоровье впервые был записан в 2017 году, и за это время десятки
                                тысяч людей обучились инструментам самостоятельного восстановления своих эмоциональных
                                сил с позиции доказательной медицины.
                            </div>
                            <div className="mb-2">
                            </div>
                            <div className="mb-2">
                                Поскольку все инструменты коррекции основаны на физиологии организма, результат
                                возникает быстро и сохраняется стабильным.
                            </div>
                        </div>
                    </div>
                    <div className="competence-page__slider-wrap">
                        <CertificatesSlider/>
                    </div>
                </Col>
            </Row>
        </Col>
    );
}

export default CompetencePage;
