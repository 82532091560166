import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Title from '../../../components/title/Title';
import './competencePage.scss'
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import CertificatesSlider from "../../../components/certificates/certificatesSlider/CertificatesSlider";

const CompetencePage2 = () => {
    return (
        <Col className="col-page-wrapper">
            <Breadcrumbs to={'2'}/>
            <Title t={'Стрессоустойчивость руководителей.'}/>
            <Row>
                <Col>
                    <p className="mb-1">
                        Не секрет, что у крупных руководителей, публичных фигур, собственников бизнеса уровень
                        стрессовой нагрузки более высокий. Ведь они несут юридическую, материальную и социальную
                        ответственность. Также ведут сложные переговоры и вынуждены принимать непростые управленческие
                        решения, которые далеко не всегда воспринимаются окружающими «на ура» и с аплодисментами.
                    </p>
                    <p className="mb-2 mt-1">
                        С 2006 года за моей помощью обращаются государственные служащие, собственники бизнеса, топовые
                        бизнес-спикеры. Я работала с чемпионами мира и готовила к выступлениям членов паралимпийской
                        сборной. Поскольку информация никогда не выносится за пределы кабинета, я не могу похвастаться
                        фотографиями и отзывами VIP-пациентов. Это очень успешные люди, личности которых широко известны
                        на федеральном уровне. Благодаря нашей совместной работе они укрепляют свои позиции и держат
                        стрессовый удар без ущерба для здоровья, сохраняя высокое качество жизни. И чувствуют в себе
                        силы достигать ещё больших вершин.
                    </p>
                    <h4 className="mt-4 mb-3">
                        Целью работы является:
                    </h4>
                    <div className="list-item">
                        <span className="list-item__stick"/>
                        <div className="list-item__text">
                            обучение навыкам преодоления стресса;
                        </div>
                    </div>
                    <div className="list-item">
                        <span className="list-item__stick"/>
                        <div className="list-item__text">
                            умение управлять своими эмоциями и противостоять манипуляциям извне;
                        </div>
                    </div>
                    <div className="list-item">
                        <span className="list-item__stick"/>
                        <div className="list-item__text">
                            разбор управленческих стратегий;
                        </div>
                    </div>
                    <div className="list-item">
                        <span className="list-item__stick"/>
                        <div className="list-item__text">
                            усиление собственного влияния в социуме;
                        </div>
                    </div>
                    <div className="list-item">
                        <span className="list-item__stick"/>
                        <div className="list-item__text">
                            психологические тонкости ведения сложных переговоров;
                        </div>
                    </div>
                    <div className="list-item">
                        <span className="list-item__stick"/>
                        <div className="list-item__text">
                            публичные выступления и навыки отвечать на самые провокационные вопросы аудитории;
                        </div>
                    </div>
                    <div className="list-item">
                        <span className="list-item__stick"/>
                        <div className="list-item__text">
                            аргументация и контраргументация: модели и скрипты;
                        </div>
                    </div>
                    <div className="list-item">
                        <span className="list-item__stick"/>
                        <div className="list-item__text">
                            подбор, обучение, мотивация персонала;
                        </div>
                    </div>
                    <h4 className="mt-4 mb-3">
                        И главное:
                    </h4>
                    <div className="list-item">
                        <span className="list-item__stick"/>
                        <div className="list-item__text">
                            обучение инструментам сохранения и укрепления психологического здоровья;
                        </div>
                    </div>
                    <div className="list-item">
                        <span className="list-item__stick"/>
                        <div className="list-item__text">
                            лечение психосоматических заболеваний с возможностью самостоятельно контролировать своё состояние (без таблеток и врачей).
                        </div>
                    </div>
                    <div className="competence-page__slider-wrap">
                        <CertificatesSlider/>
                    </div>
                </Col>
            </Row>
        </Col>
    );
}

export default CompetencePage2;
