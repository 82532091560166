import React, {useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import BreadCrumb from '../../components/breadcrumbs/Breadcrumbs';
import Title from '../../components/title/Title';
import RegularBtn from '../../components/regularBtn/RegularBtn';
import './consultationPage.scss'
import {Link} from 'react-router-dom';
import {$host, consultationType} from "../../utils/consts";
import {useStore} from "../../store";
import RobokassaForm from "../../components/robokassaForm/RobokassaForm";
import { useHistory } from 'react-router-dom';
import PaymentEpointButton from '../../components/paymentEpointButton/PaymentEpointButton';

const ConsultationPage = () => {
    const {userStore} = useStore()
    const type = consultationType;
    const id = 1;

    const [payData, setPayData] = useState({});
    const form = useRef(null);
    const history = useHistory();

    const isAuthUser = userStore.isAuth;

    const buyReq = () => {
        $host.get(`api/v1/payment/robokassa/get-payment-data`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            params: {
                type,
                id
            }
        }).then(res => {
            setPayData(res.data);
            form.current.submit();
        })
    }

    const buyHandler = () => {
      if (!isAuthUser) {
        history.push('/modal/auth', { modal: true });
      }
      buyReq();
    }

    return (
      <Col className="consultation-page-wrapper col-page-wrapper">
        <BreadCrumb active={'Запись на консультацию'} />
        <Title t={'Запись на консультацию'} />
        <Row>
          <Col className="consultation-page">
            <h3>Срочная Skype-консультация</h3>
            <div className="consultation-page__price">60 000 ₽ / три часа</div>
            <div className="consultation-page__subtitle">
              Если Вы считаете, что без online-консультации не обойтись, вы можете обратиться за
              срочной помощью.
            </div>
            <div className="consultation-page__actions">
              <div>Чтобы забронировать консультацию, нажмите кнопку «Записаться».</div>
              <div>Заполните появившуюся форму.</div>
              <div>Произведите оплату любым доступным способом в течение получаса.</div>
              <div>Дождитесь ответного письма на электронную почту с дальнейшей инструкцией.</div>
            </div>
            <div>
              <div className="pb-4">
                Если Вы
                <Link
                  className="header__signin"
                  to={{ pathname: '/modal/auth', state: { modal: true } }}
                >
                  {' '}
                  зарегистрированы
                </Link>{' '}
                на сайте рекомендуем
                <Link
                  className="header__signin"
                  to={{ pathname: '/modal/auth', state: { modal: true } }}
                >
                  {' '}
                  авторизоваться
                </Link>
                , перед записью на консультацию.
              </div>
            </div>
            <RobokassaForm refKey={form} payData={payData} />
            <div className="d-flex justify-content-start align-items-center gap-1 pb-2">
              <RegularBtn onClick={() => buyHandler()} t={'Купить'} w={'10rem'} mt="none" />
              <PaymentEpointButton id={id} type={type} price={1150} webinar_state='STATE_CAN_BUY' />
            </div>
            <p style={{fontSize: 12}}>
              <span className="buy-btn-asterisk">*</span>В случае оплаты не из РФ, сумма списывается
              в валюте вашей карты по курсу к манату (денежная единица Азербайджана)
            </p>
          </Col>
        </Row>
      </Col>
    );
}

export default ConsultationPage;

