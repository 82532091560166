import React from 'react';
import {useMediaQuery} from 'react-responsive';

function Title({w = '7.4375rem', c = 'black', d = 'inline-block', t, mt = '3.45rem', mb = '2.55rem', fs = '2rem'}) {
    const max767 = useMediaQuery({
        query: '(max-width: 767px)'
    })

    if (max767) {
        d = 'none'
        mt = '1.9rem'
        mb = '1.5rem'
        fs = '1.5rem'
    }

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: mt,
            marginBottom: mb,
        }}>
            <div style={{
                width: w,
                height: 2,
                backgroundColor: c,
                display: d,
                marginRight: 10,
            }}/>
            <h2 style={{
                fontFamily: 'Lora',
                fontSize: fs,
                margin: 0,
            }}>
                {t}
            </h2>
        </div>
    );
}

export default Title;