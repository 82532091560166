import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import Logo from '../../images/aina-logo-white.png';
import {
    ABOUT_ROUTE,
    ACCOUNT_ROUTE,
    CONSULTATION_ROUTE,
    CONTACTS_ROUTE,
    HOME_ROUTE, REVIEWS_ROUTE,
    VIDEOCOURCES_ROUTE
} from '../../utils/consts';
import './footer.scss'

function Footer() {
    return (
        <Row className="footer mt-auto">
            <Col className="col-page-wrapper footer__wrap">
                <NavLink to={HOME_ROUTE}>
                    <img src={Logo} alt="Logo"/>
                </NavLink>
                <NavLink to={HOME_ROUTE}>
                    Главная
                </NavLink>
                <NavLink to={ABOUT_ROUTE}>
                    Обо мне
                </NavLink>
                <NavLink to={VIDEOCOURCES_ROUTE}>
                    Видеокурсы
                </NavLink>
                <NavLink to={CONSULTATION_ROUTE}>
                    Консультация
                </NavLink>
                <NavLink to={REVIEWS_ROUTE}>
                    Отзывы
                </NavLink>
                <NavLink to={CONTACTS_ROUTE}>
                    Контакты
                </NavLink>
                <NavLink to={ACCOUNT_ROUTE}>
                    Аккаунт
                </NavLink>
            </Col>
            {/*<Col className="footer__made-in">*/}
            {/*    сделано в&nbsp;*/}
            {/*    <a href={'https://stinum.ru'}>*/}
            {/*        stinum*/}
            {/*    </a>*/}
            {/*</Col>*/}
        </Row>
    );
}

export default Footer;
