import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {$host} from "../../utils/consts";
import FeedbacksVideoItem from "../../components/feedbacksVideoItem/FeedbacksVideoItem";
import './feedbacks.scss'
import Title from "../../components/title/Title";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

const Feedbacks = () => {

    const [feedbacks, setFeedbacks] = useState([]);

    const videoFeedbacks = feedbacks.filter(item => item.url)

    const getFeedbacksReq = () => {
        $host.get(`api/v1/user/feedbacks`).then(res => {
            setFeedbacks(res.data)
        })
    }

    useEffect(() => {
        getFeedbacksReq()
    }, []);

    return (
        <Col className="col-page-wrapper">
            <Row>
                <Breadcrumbs/>
                <Title t={'Видео отзывы'}/>
                <Col className="feedbacks-video-wrap-inner">
                    {videoFeedbacks.map(item => <FeedbacksVideoItem
                        key={item.id}
                        name={item.name}
                        url={item.url}
                    />)}
                </Col>
            </Row>
        </Col>
    );
}

export default Feedbacks;
