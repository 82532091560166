import React, { useState } from 'react';
import { Col } from "react-bootstrap";
import Title from "../title/Title";
import Lightbox from 'react-image-lightbox';
import './certificatesGallery.scss'

function CertificatesGallery() {

    const [lightBoxVisible, setLightBoxVisible] = useState(false);
    const [largeImage, setLargeImage] = useState('');

    function hand(img) {
        setLargeImage(img)
        setLightBoxVisible(true)
    }

    const publicUrl = process.env.PUBLIC_URL;

    return (
        <div>
            <Col className="title-responsive">
                <Title t={'Мои сертификаты'} />
            </Col>
            <div className="gallery-items-wrap">

                {[...Array(18)].map((x, i) => {
                    const certificateNumber = `c${++i}`;
                    const imageUrl = publicUrl + `/images/certificates/${certificateNumber}.jpg`;

                    return <div onClick={() => hand(imageUrl)}>
                        <img src={imageUrl} alt={certificateNumber} />
                    </div>
                }
                )}

            </div>
            {lightBoxVisible && <Lightbox
                mainSrc={largeImage}
                onCloseRequest={() => setLightBoxVisible(false)}
                enableZoom={false}
            />}
        </div>
    );
}

export default CertificatesGallery;