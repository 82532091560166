import React from 'react';
import {Col, Row} from "react-bootstrap";
import './kitWebinarItem.scss'
import {NavLink} from "react-router-dom";

const KitWebinarItem = ({title, length, price, id, image}) => {
    return (
        <Row className="kit-webinar-item-inner-wrap">
            <NavLink to={`/videocources/` + id}>
                <Col className="kit-webinar-item-wrap">
                    <div>
                        <img src={image} alt=""/>
                    </div>
                    <div className="kit-webinar-item__text-wrap">
                        <div className="kit-webinar-item__title">
                            {title}
                        </div>
                        <div className="kit-webinar-item__info">
                            <div>{length}</div>
                            <div className="kit-webinar-item__price">{price} ₽</div>
                        </div>
                    </div>
                </Col>
            </NavLink>
        </Row>
    );
};

export default KitWebinarItem;
