import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const TariffsContentBlock = () => {
  return (
    <Row className="row-component-wrapper questions-sl-wrap-outer">
      <Col className="questions-sl-wrap-inner">
        <div className="sl-title">Содержание курса:</div>
        <div className="questions-sl-blocks-wrap">
          <div className="questions-sl-first-block">
            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>1</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                  <span>
                    Фрейд, Юнг, Хорни и другие столпы психологии о личности человека.
                    Что не так с теориями, которые вы изучали в институте? 6 критериев эффективности, необходимые современному специалисту.
                  </span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>2</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                  <span>Психическое здоровье личности: диапазон нормы и патологии;</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>3</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                 <span>Особенности проведения психологического консультирования в диапазоне нормы и патологии;</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>4</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                 <span>Пространство личности: от нормы до патологии;</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>5</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                 <span>Эпилептоиды в диапазоне психологической нормы;</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>6</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                 <span>Эпилептоиды в диапазоне аномальной личностной изменчивости;</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>7</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                 <span>Эпилептоиды в диапазоне психопатии;</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>8</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                 <span>Шизоиды в диапазоне психологической нормы;</span>
                </span>
              </div>
            </div>
          </div>

          <div className="questions-sl-second-block">
            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>9</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                  <span>Шизоиды в диапазоне аномальной личностной изменчивости;</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>10</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                  <span>Шизоиды в диапазоне психопатии.</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>11</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                  <span>
                    Циклоиды в диапазоне психологической нормы.
                  </span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>12</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                  <span>
                    Циклоиды в диапазоне аномальной личностной изменчивости.
                  </span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>13</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                 <span>Циклоиды в диапазоне психопатии.</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>14</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                 <span>Истероиды в диапазоне психологической нормы.</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>15</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                 <span>Истероиды в диапазоне аномальной личностной изменчивости.</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>16</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                 <span>Истероиды в диапазоне психопатии.</span>
                </span>
              </div>
            </div>

            <div className="questions-sl__item">
              <div className="questions-sl__item__numb">
                <span>17</span>
              </div>
              <div className="questions-sl__item__text">
                <span className="questions-sl__item__text__bottom">
                 <span>Заключение.</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

