import React from 'react';
import { Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { ArrowRightShort } from 'react-bootstrap-icons';
import competence2 from '../../images/competence-2.jpg'
import './competencesItem.scss'

const CompetencesItem = () => {
    return (
        <Row className="competences-item-wrap" style={{background: `url(${competence2})`,}}>
            <div style={{
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                paddingBottom: '1.5rem'
            }}>
                <div style={{
                    fontWeight: '600',
                    paddingBottom: '.5rem',
                    fontSize: '1.222rem'
                }}>
                    Семейное консультирование
                </div>
                <div>
                    <NavLink to="/competences/competence2" style={{ color: 'white', padding: 0 }}>
                        Узнать больше <ArrowRightShort />
                    </NavLink>
                </div>
            </div>
        </Row >
    );
}

export default CompetencesItem;
