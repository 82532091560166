import React from 'react';
import {useStore} from "../../../../../../store";

function ExerciseAdminQuestionWithButtons({lessonItem, questionItem}) {

    const {exercisesStore} = useStore()

    function setQuestionWithButtonsTextHandler(lessonId, questionId, value) {
        exercisesStore.setQuestionWithButtonsText(lessonId, questionId, value)
    }

    return (
        <>
            <input type="text"
                   value={exercisesStore.exercise.properties.text}
                   placeholder={'текст вопроса'}
                   onChange={(e) =>
                       setQuestionWithButtonsTextHandler(
                           lessonItem.id,
                           questionItem.id,
                           e.target.value
                       )
                   }/>
        </>
    );
}

export default ExerciseAdminQuestionWithButtons;