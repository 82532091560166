import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import Title from '../title/Title';
import Slider from "react-slick";
import {useMediaQuery} from 'react-responsive';
import {$host, REVIEWS_ROUTE} from "../../utils/consts";
import VideoReviewsSliderItem from "./VideoReviewsSliderItem";
import LearnMoreLink from "../learnMoreLink/LearnMoreLink";

function VideoReviews() {
    const max767 = useMediaQuery({
        query: '(max-width: 767px)'
    })

    const max575 = useMediaQuery({
        query: '(max-width: 575px)'
    })

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: max767 ? false : true,
    };

    if (max767) {
        settings.slidesToShow = 2
        settings.slidesToScroll = 2
    }
    if (max575) {
        settings.slidesToShow = 1
        settings.slidesToScroll = 1
    }


    const [feedbacks, setFeedbacks] = useState([]);

    const getFeedbacksReq = () => {
        $host.get(`api/v1/user/feedbacks`).then(res => {
            setFeedbacks(res.data)
        })
    }

    function YouTubeGetID(url) {
        url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
    }

    const feedbacksWithYoutubeId = feedbacks.map(item => {
        if (item.url !== null) {
            const youtubeId = YouTubeGetID(`${item.url}`)
            const previewImageLink = `https://i.ytimg.com/vi/${youtubeId}/maxresdefault.jpg`
            item.previewImageLink = previewImageLink
        }
        return item
    })

    useEffect(() => {
        getFeedbacksReq()
    }, []);

    return (
        <Row style={{
            justifyContent: 'center',
        }}>
            <Col className="slider-wrap" style={{
                maxWidth: 1500,
            }}>
                <Row>
                    <Col className="title-responsive">
                        <Title t={'Видеоотзывы'}/>
                    </Col>
                </Row>
                <Row className="video-reviews" style={{
                    marginBottom: '3.875rem'
                }}>
                    <Col>
                        <Slider {...settings}>
                            {feedbacksWithYoutubeId.slice(3).map(item => <VideoReviewsSliderItem //--//Убрать!
                                key={item.id}
                                name={item.name}
                                url={item.url}
                                previewImageLink={item.previewImageLink}
                            />)}
                        </Slider>
                    </Col>
                </Row>
                <div style={{paddingTop: '2.4rem', paddingBottom: '2.4rem'}}>
                    <LearnMoreLink text={'Все видеоотзывы'} width={'14.6rem'} route={REVIEWS_ROUTE}/>
                </div>
            </Col>
        </Row>
    );
}

export default VideoReviews;